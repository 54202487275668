import React, { useState, useEffect, useMemo, useRef } from 'react';
import { BookOpen, Check, X } from 'lucide-react';

// Funciones de ErrorBank
import {
  updateErrorBank,
  toggleLearned,
  updateMnemonic,
  getErrorBankData,
  addBulkSimulationErrors
} from './ErrorBank';

// 1. IMPORTAR COMPONENTES INTERACTIVOS PARA "Carcinoma de Tiroides"
import CarcinomadeTiroidesTable from '../InteractiveTables/CarcinomadeTiroidesTable';
import CarcinomadeTiroidesEsquema from '../InteractiveEsquemas/CarcinomadeTiroidesEsquema';
import NoduloTiroideoEsquema from '../InteractiveEsquemas/NoduloTiroideoEsquema';
import NoduloTiroideoTable from '../InteractiveTables/NoduloTiroideoTable';

/**
 * PROPS:
 *   - title, questions, onBack, DecisionTableComponents, simulationMode, subjectName,
 *     topicName, simulationTime, fromGenerator
 */
function Quiz({
  title,
  questions,
  onBack,
  DecisionTableComponents,
  simulationMode,
  subjectName,
  topicName,
  simulationTime,
  fromGenerator
}) {
  // -------------------------------------------------------------------------
  // 1) Timer para modo simulacro
  // -------------------------------------------------------------------------
  const totalTimeInSeconds = simulationMode ? simulationTime * 60 : null;
  const [timeLeft, setTimeLeft] = useState(totalTimeInSeconds);

  // -------------------------------------------------------------------------
  // 2) Mostrar resultados al final
  // -------------------------------------------------------------------------
  const [showResults, setShowResults] = useState(false);

  // -------------------------------------------------------------------------
  // 3) Preguntas incorrectas acumuladas
  // -------------------------------------------------------------------------
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);

  // -------------------------------------------------------------------------
  // 4) Manejo de pregunta actual
  // -------------------------------------------------------------------------
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);
  const [showDecisionTable, setShowDecisionTable] = useState(false);

  // -------------------------------------------------------------------------
  // 5) Historial local de estudio (localStorage)
  // -------------------------------------------------------------------------
  const [studyHistory, setStudyHistory] = useState(() => {
    try {
      const saved = localStorage.getItem(`studyHistory_${title}`);
      return saved
        ? JSON.parse(saved)
        : {
            attempts: 0,
            correct: 0,
            questionHistory: {}
          };
    } catch {
      return {
        attempts: 0,
        correct: 0,
        questionHistory: {}
      };
    }
  });

  // -------------------------------------------------------------------------
  // 6) Filtros en modo estudio
  // -------------------------------------------------------------------------
  const [selectedYear, setSelectedYear] = useState('Todos');
  const [selectedSubtopic, setSelectedSubtopic] = useState('Todos');

  const years = [...new Set(questions.map((q) => q.year))];
  const subtopics = [...new Set(questions.map((q) => q.subtopic || 'Sin subtema'))];

  // -------------------------------------------------------------------------
  // 7) Barajar preguntas si es simulacro
  // -------------------------------------------------------------------------
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  useEffect(() => {
    if (simulationMode) {
      const shuffled = [...questions].sort(() => Math.random() - 0.5);
      setShuffledQuestions(shuffled);
    } else {
      setShuffledQuestions(questions);
    }
  }, [questions, simulationMode]);

  // -------------------------------------------------------------------------
  // 8) Aplicar filtros
  // -------------------------------------------------------------------------
  const filteredByYear =
    selectedYear === 'Todos'
      ? shuffledQuestions
      : shuffledQuestions.filter((q) => q.year === selectedYear);

  const filteredQuestions = filteredByYear.filter((q) =>
    selectedSubtopic === 'Todos' ? true : q.subtopic === selectedSubtopic
  );

  // Ajustar currentQuestion si excede el nuevo tamaño tras filtrar
  useEffect(() => {
    if (currentQuestion >= filteredQuestions.length) {
      setCurrentQuestion(0);
    }
  }, [filteredQuestions, currentQuestion]);

  // -------------------------------------------------------------------------
  // 9) Guardar studyHistory en localStorage
  // -------------------------------------------------------------------------
  useEffect(() => {
    try {
      localStorage.setItem(`studyHistory_${title}`, JSON.stringify(studyHistory));
    } catch (error) {
      console.error('Error saving to localStorage:', error);
    }
  }, [studyHistory, title]);

  // -------------------------------------------------------------------------
  // 10) Temporizador en modo simulacro
  // -------------------------------------------------------------------------
  useEffect(() => {
    let timer;
    if (simulationMode && !showResults && timeLeft !== null) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            finalizarSimulacro(incorrectQuestions);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [simulationMode, showResults, timeLeft, incorrectQuestions]);

  const formatTime = (secs) => {
    const m = Math.floor(secs / 60);
    const s = secs % 60;
    return `${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
  };

  // -------------------------------------------------------------------------
  // 11) Pregunta actual
  // -------------------------------------------------------------------------
  const currentQuestionData = filteredQuestions[currentQuestion] || null;

  // Respuestas del usuario (para revisión final en modo simulacro)
  const [userAnswers, setUserAnswers] = useState([]);

  // -------------------------------------------------------------------------
  // --- MAPEOS PARA TABLA Y ESQUEMA DE "Carcinoma de Tiroides" --------------
  // -------------------------------------------------------------------------
  //  A) Mapeo para la tabla:
  const subtopicTableMap = {
    'Carcinoma de Tiroides': <CarcinomadeTiroidesTable />,
    'Nódulo tiroideo': <NoduloTiroideoTable />
    // Agrega otras entradas si lo deseas
  };

  //  B) Mapeo para el esquema:
  const subtopicEsquemaMap = {
    'Carcinoma de Tiroides': <CarcinomadeTiroidesEsquema />,
    'Nódulo tiroideo': <NoduloTiroideoEsquema />
    // Agrega otros subtemas y sus esquemas
  };

  // Determinar si esta pregunta tiene tabla/esquema disponible
  const TableComponent =
    currentQuestionData && subtopicTableMap[currentQuestionData.subtopic]
      ? subtopicTableMap[currentQuestionData.subtopic]
      : null;

  const EsquemaComponent =
    currentQuestionData && subtopicEsquemaMap[currentQuestionData.subtopic]
      ? subtopicEsquemaMap[currentQuestionData.subtopic]
      : null;

  // Estados para mostrar/ocultar
  const [showSubtopicTable, setShowSubtopicTable] = useState(false);
  const [showSubtopicEsquema, setShowSubtopicEsquema] = useState(false);

  // -------------------------------------------------------------------------
  // 12) Resetea selectedAnswer y explicación al cambiar de pregunta
  // -------------------------------------------------------------------------
  useEffect(() => {
    setSelectedAnswer(null);
    setShowExplanation(false);
    setShowDecisionTable(false);

    // Asegurarnos de ocultar la tabla y el esquema al cambiar de pregunta
    setShowSubtopicTable(false);
    setShowSubtopicEsquema(false);
  }, [currentQuestionData]);

  // -------------------------------------------------------------------------
  // 13) Manejo de la respuesta
  // -------------------------------------------------------------------------
  const handleAnswer = (optionId) => {
    if (!currentQuestionData) return;
    const option = currentQuestionData.options.find((opt) => opt.id === optionId);
    const isCorrect = option?.correct || false;

    // Guardar la respuesta en userAnswers
    setUserAnswers((prev) => {
      const updated = [...prev];
      updated[currentQuestion] = optionId;
      return updated;
    });

    // MODO ESTUDIO → actualiza ErrorBank al instante
    if (!simulationMode) {
      updateErrorBank(currentQuestionData, isCorrect ? true : false, false);
      setSelectedAnswer(optionId);
      setShowExplanation(true);

      // Actualizar studyHistory
      const qId = String(currentQuestionData.id);
      const oldQ = studyHistory.questionHistory[qId] || { attempts: 0, correct: 0 };

      setStudyHistory((prev) => ({
        ...prev,
        attempts: prev.attempts + 1,
        correct: prev.correct + (isCorrect ? 1 : 0),
        questionHistory: {
          ...prev.questionHistory,
          [qId]: {
            attempts: oldQ.attempts + 1,
            correct: oldQ.correct + (isCorrect ? 1 : 0),
            lastAttempt: new Date().toISOString()
          }
        }
      }));

      if (!isCorrect) {
        setIncorrectQuestions((prev) => [...prev, currentQuestionData]);
      }
    }
    // MODO SIMULACRO → NO se actualiza ErrorBank aquí, sino al final
    else {
      const qId = String(currentQuestionData.id);
      const oldQ = studyHistory.questionHistory[qId] || { attempts: 0, correct: 0 };

      // Actualiza solo el historial general
      setStudyHistory((prev) => ({
        ...prev,
        attempts: prev.attempts + 1,
        correct: prev.correct + (isCorrect ? 1 : 0),
        questionHistory: {
          ...prev.questionHistory,
          [qId]: {
            attempts: oldQ.attempts + 1,
            correct: oldQ.correct + (isCorrect ? 1 : 0),
            lastAttempt: new Date().toISOString()
          }
        }
      }));

      // Si es incorrecta, la agregamos a la lista
      let newIncorrect = incorrectQuestions;
      if (!isCorrect) {
        newIncorrect = [...incorrectQuestions, currentQuestionData];
        setIncorrectQuestions(newIncorrect);
      }

      // Si estamos en la última pregunta, finalizar
      if (currentQuestion === filteredQuestions.length - 1) {
        finalizarSimulacro(newIncorrect);
      } else {
        nextQuestion();
      }
    }
  };

  // -------------------------------------------------------------------------
  // 14) Siguiente pregunta
  // -------------------------------------------------------------------------
  const nextQuestion = () => {
    setCurrentQuestion((prev) => (prev + 1) % filteredQuestions.length);
  };

  // -------------------------------------------------------------------------
  // 15) Reiniciar
  // -------------------------------------------------------------------------
  const resetStudy = () => {
    setStudyHistory({
      attempts: 0,
      correct: 0,
      questionHistory: {}
    });
    setIncorrectQuestions([]);
    setCurrentQuestion(0);
    setReviewMode(false);
    setShowResults(false);
    setTimeLeft(simulationMode ? simulationTime * 60 : null);
    setUserAnswers([]);
  };

  // -------------------------------------------------------------------------
  // 16) Finalizar simulacro (recibe el arreglo actualizado de errores)
  // -------------------------------------------------------------------------
  const finalizarSimulacro = (finalErrors) => {
    // Registrar errores en ErrorBank
    if (finalErrors && finalErrors.length > 0) {
      addBulkSimulationErrors(finalErrors);
    }
    setShowResults(true);
  };

  // Evitar registrar más de una vez
  const [savedHistory, setSavedHistory] = useState(false);

  // -------------------------------------------------------------------------
  // 17) useEffect para guardar examen en el historial localStorage
  // -------------------------------------------------------------------------
  useEffect(() => {
    if (showResults && simulationMode && !savedHistory) {
      const now = new Date();
      const totalRespondidas = filteredQuestions.length;
      const correctCount = totalRespondidas - incorrectQuestions.length;
      const scorePercentage = ((correctCount / totalRespondidas) * 100).toFixed(1);

      // Calcular breakdown
      const errorsByOrganSystem = {};
      const errorsByTopic = {};
      const errorsBySubtopic = {};
      const errorsBySubtopicSpecific = {};

      incorrectQuestions.forEach((q) => {
        const os = q.organsystem || 'Desconocido';
        errorsByOrganSystem[os] = (errorsByOrganSystem[os] || 0) + 1;

        const t = q.topic || 'Tema desconocido';
        errorsByTopic[t] = (errorsByTopic[t] || 0) + 1;

        const st = q.subtopic || 'Subtema desconocido';
        errorsBySubtopic[st] = (errorsBySubtopic[st] || 0) + 1;

        const sps = q.subtopic_specific || 'Sin especificar';
        errorsBySubtopicSpecific[sps] = (errorsBySubtopicSpecific[sps] || 0) + 1;
      });

      // Contar totales
      const totalByTopic = {};
      const totalBySubtopic = {};
      const totalBySubtopicSpecific = {};

      questions.forEach((q) => {
        const t = q.topic || 'Tema desconocido';
        totalByTopic[t] = (totalByTopic[t] || 0) + 1;

        const st = q.subtopic || 'Subtema desconocido';
        totalBySubtopic[st] = (totalBySubtopic[st] || 0) + 1;

        const sps = q.subtopic_specific || 'Sin especificar';
        totalBySubtopicSpecific[sps] = (totalBySubtopicSpecific[sps] || 0) + 1;
      });

      const resultsByTopic = Object.keys(totalByTopic).map((t) => {
        const total = totalByTopic[t];
        const errors = errorsByTopic[t] || 0;
        return {
          topic: t,
          total,
          correct: total - errors,
          errors
        };
      });

      const resultsBySubtopic = Object.keys(totalBySubtopic).map((st) => {
        const total = totalBySubtopic[st];
        const errors = errorsBySubtopic[st] || 0;
        return {
          subtopic: st,
          total,
          correct: total - errors,
          errors
        };
      });

      const resultsBySubtopicSpecific = Object.keys(totalBySubtopicSpecific).map(
        (sps) => {
          const total = totalBySubtopicSpecific[sps];
          const errors = errorsBySubtopicSpecific[sps] || 0;
          return {
            subtopic_specific: sps,
            total,
            correct: total - errors,
            errors
          };
        }
      );

      // Detalles con la respuesta del usuario
      const questionDetails = filteredQuestions.map((q, index) => ({
        ...q,
        userAnswer: userAnswers[index] || null
      }));

      // Guardar todo en "examHistory"
      saveExamToHistory({
        examTitle: title || 'Examen sin título',
        date: now.toLocaleDateString(),
        time: now.toLocaleTimeString(),
        totalQuestions: totalRespondidas,
        correct: correctCount,
        scorePercentage,
        breakdown: {
          errorsByOrganSystem,
          errorsByTopic,
          errorsBySubtopic,
          errorsBySubtopicSpecific,
          resultsByTopic,
          resultsBySubtopic,
          resultsBySubtopicSpecific
        },
        questionDetails,
        removed: false
      });

      setSavedHistory(true);
    }
  }, [
    showResults,
    simulationMode,
    savedHistory,
    filteredQuestions,
    incorrectQuestions,
    title,
    questions,
    userAnswers
  ]);

  // -------------------------------------------------------------------------
  // 18) Pantalla final de resultados (modo simulacro)
  // -------------------------------------------------------------------------
  const [showDetailedReview, setShowDetailedReview] = useState(false);

  if (showResults && simulationMode) {
    const totalRespondidas = filteredQuestions.length;
    const correctCount = totalRespondidas - incorrectQuestions.length;

    // Recalcular breakdown para mostrar aquí
    const errorsByOrganSystem = {};
    const errorsByTopic = {};
    const errorsBySubtopic = {};
    const errorsBySubtopicSpecific = {};

    incorrectQuestions.forEach((q) => {
      const os = q.organsystem || 'Desconocido';
      errorsByOrganSystem[os] = (errorsByOrganSystem[os] || 0) + 1;

      const t = q.topic || 'Tema desconocido';
      errorsByTopic[t] = (errorsByTopic[t] || 0) + 1;

      const st = q.subtopic || 'Subtema desconocido';
      errorsBySubtopic[st] = (errorsBySubtopic[st] || 0) + 1;

      const sps = q.subtopic_specific || 'Sin especificar';
      errorsBySubtopicSpecific[sps] = (errorsBySubtopicSpecific[sps] || 0) + 1;
    });

    const totalByTopic = {};
    const totalBySubtopic = {};
    const totalBySubtopicSpecific = {};

    questions.forEach((q) => {
      const t = q.topic || 'Tema desconocido';
      totalByTopic[t] = (totalByTopic[t] || 0) + 1;

      const st = q.subtopic || 'Subtema desconocido';
      totalBySubtopic[st] = (totalBySubtopic[st] || 0) + 1;

      const sps = q.subtopic_specific || 'Sin especificar';
      totalBySubtopicSpecific[sps] = (totalBySubtopicSpecific[sps] || 0) + 1;
    });

    const resultsByTopic = Object.keys(totalByTopic).map((t) => {
      const total = totalByTopic[t];
      const errors = errorsByTopic[t] || 0;
      return {
        topic: t,
        total,
        correct: total - errors,
        errors
      };
    });

    const resultsBySubtopic = Object.keys(totalBySubtopic).map((st) => {
      const total = totalBySubtopic[st];
      const errors = errorsBySubtopic[st] || 0;
      return {
        subtopic: st,
        total,
        correct: total - errors,
        errors
      };
    });

    const resultsBySubtopicSpecific = Object.keys(totalBySubtopicSpecific).map(
      (sps) => {
        const total = totalBySubtopicSpecific[sps];
        const errors = errorsBySubtopicSpecific[sps] || 0;
        return {
          subtopic_specific: sps,
          total,
          correct: total - errors,
          errors
        };
      }
    );

    return (
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
          Resultados del Simulacro
        </h2>
        <p className="text-center mb-4">
          Preguntas respondidas: {totalRespondidas}
        </p>
        <p className="text-center mb-4 text-green-700 font-bold">
          Correctas: {correctCount}
        </p>
        <p className="text-center mb-4 text-red-700 font-bold">
          Incorrectas: {incorrectQuestions.length}
        </p>

        {/* Errores por Órgano/Sistema */}
        <h3 className="text-xl font-bold mb-2">Errores por Órgano/Sistema:</h3>
        <table className="w-full border-collapse mb-4">
          <thead>
            <tr>
              <th className="border-b p-2">Órgano/Sistema</th>
              <th className="border-b p-2">Errores</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(errorsByOrganSystem).map((key) => (
              <tr key={key}>
                <td className="border-b p-2">{key}</td>
                <td className="border-b p-2">{errorsByOrganSystem[key]}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Resultados por Tema */}
        <h3 className="text-xl font-bold mb-2">Resultados por Tema:</h3>
        <table className="w-full border-collapse mb-4">
          <thead>
            <tr>
              <th className="border-b p-2">Tema</th>
              <th className="border-b p-2">Total</th>
              <th className="border-b p-2">Correctas</th>
              <th className="border-b p-2">Incorrectas</th>
            </tr>
          </thead>
          <tbody>
            {resultsByTopic.map(({ topic, total, correct, errors }) => (
              <tr key={topic}>
                <td className="border-b p-2">{topic}</td>
                <td className="border-b p-2">{total}</td>
                <td className="border-b p-2 text-green-700">{correct}</td>
                <td className="border-b p-2 text-red-700">{errors}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Resultados por Subtema */}
        <h3 className="text-xl font-bold mb-2">Resultados por Subtema:</h3>
        <table className="w-full border-collapse mb-4">
          <thead>
            <tr>
              <th className="border-b p-2">Subtema</th>
              <th className="border-b p-2">Total</th>
              <th className="border-b p-2">Correctas</th>
              <th className="border-b p-2">Incorrectas</th>
            </tr>
          </thead>
          <tbody>
            {resultsBySubtopic.map(({ subtopic, total, correct, errors }) => (
              <tr key={subtopic}>
                <td className="border-b p-2">{subtopic}</td>
                <td className="border-b p-2">{total}</td>
                <td className="border-b p-2 text-green-700">{correct}</td>
                <td className="border-b p-2 text-red-700">{errors}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Resultados por Subtema Específico */}
        <h3 className="text-xl font-bold mb-2">Resultados por Subtema Específico:</h3>
        <table className="w-full border-collapse mb-4">
          <thead>
            <tr>
              <th className="border-b p-2">Subtema Específico</th>
              <th className="border-b p-2">Total</th>
              <th className="border-b p-2">Correctas</th>
              <th className="border-b p-2">Incorrectas</th>
            </tr>
          </thead>
          <tbody>
            {resultsBySubtopicSpecific.map(
              ({ subtopic_specific, total, correct, errors }) => (
                <tr key={subtopic_specific}>
                  <td className="border-b p-2">{subtopic_specific}</td>
                  <td className="border-b p-2">{total}</td>
                  <td className="border-b p-2 text-green-700">{correct}</td>
                  <td className="border-b p-2 text-red-700">{errors}</td>
                </tr>
              )
            )}
          </tbody>
        </table>

        <div className="mb-4">
          <p className="mb-2 font-semibold">Sugerencias de Estudio:</p>
          <ul className="list-disc list-inside text-gray-700">
            <li>Repasa las tablas y esquemas de {topicName || subjectName}.</li>
            <li>Practica más los subtemas y subtemas específicos con más errores.</li>
          </ul>
        </div>

        <button
          onClick={() => setShowDetailedReview(!showDetailedReview)}
          className="w-full px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 font-semibold mb-4"
        >
          {showDetailedReview ? 'Ocultar Revisión Detallada' : 'Revisar Respuestas'}
        </button>

        {showDetailedReview && (
          <div className="max-h-96 overflow-y-auto mb-4">
            {filteredQuestions.map((q, index) => {
              const userAnswer = userAnswers[index];
              return (
                <div key={q.id} className="border p-4 my-2 rounded">
                  <h4 className="font-bold">
                    Pregunta {index + 1} (RM {q.year})
                  </h4>
                  {q.case_description && (
                    <p className="mt-2 italic">{q.case_description}</p>
                  )}
                  <p className="mt-2">{q.question_text}</p>

                  <div className="mt-2">
                    {q.options.map((opt) => (
                      <div
                        key={opt.id}
                        className={`p-2 ${
                          opt.id === userAnswer
                            ? opt.correct
                              ? 'bg-green-100'
                              : 'bg-red-100'
                            : ''
                        }`}
                      >
                        <span>
                          {opt.option_label}) {opt.option_text}
                        </span>
                        {opt.correct && (
                          <span className="ml-2 text-green-600">(Correcta)</span>
                        )}
                        {opt.id === userAnswer && !opt.correct && (
                          <span className="ml-2 text-red-600">(Tu respuesta)</span>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="mt-2">
                    <h5 className="font-semibold">Explicación:</h5>
                    <p>{q.explanation}</p>
                    {q.key_points && (
                      <pre className="bg-gray-100 p-2 rounded mt-1 text-sm">
                        {q.key_points}
                      </pre>
                    )}
                    {q.ascii_diagram && (
                      <pre className="bg-gray-100 p-2 rounded mt-1 text-sm">
                        {q.ascii_diagram}
                      </pre>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <button
          onClick={resetStudy}
          className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 font-semibold"
        >
          Reiniciar
        </button>
        <button
          onClick={onBack}
          className="w-full mt-2 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 font-semibold"
        >
          {fromGenerator ? 'Regresar' : 'Volver'}
        </button>
      </div>
    );
  }

  // -------------------------------------------------------------------------
  // 19) Si no hay preguntas tras filtrar
  // -------------------------------------------------------------------------
  if (!filteredQuestions.length || !currentQuestionData) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <p className="text-red-600">
            No hay preguntas disponibles con los filtros aplicados.
          </p>
          <button
            onClick={() => {
              setSelectedYear('Todos');
              setSelectedSubtopic('Todos');
            }}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Ver Todas
          </button>
        </div>
      </div>
    );
  }

  // -------------------------------------------------------------------------
  // 20) Render principal (modo estudio o práctica normal)
  // -------------------------------------------------------------------------
  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-lg p-6 relative">
        {/* Botón Volver */}
        <button
          onClick={onBack}
          className="mb-4 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
        >
          {fromGenerator ? 'Regresar' : 'Volver'}
        </button>

        {/* Título e info general */}
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-center text-gray-800 mb-2">
            {title}
          </h1>

          <div className="flex justify-between items-center flex-wrap gap-2">
            <div className="text-sm">
              <span className="font-medium">Intentos totales: </span>
              {studyHistory.attempts}
            </div>
            <div className="text-sm text-green-600">
              <span className="font-medium">Correctas (histórico): </span>
              {studyHistory.correct} (
              {studyHistory.attempts > 0
                ? Math.round((studyHistory.correct / studyHistory.attempts) * 100)
                : 0}
              %)
            </div>
            <button
              onClick={resetStudy}
              className="px-4 py-2 text-sm bg-red-500 text-white rounded-lg hover:bg-red-600"
            >
              Reiniciar
            </button>
            <button
              onClick={() => setReviewMode(!reviewMode)}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              {reviewMode ? 'Modo Práctica' : 'Ver Estadísticas'}
            </button>
          </div>

          {/* Temporizador (si simulacro) */}
          {simulationMode && (
            <div className="mt-4 text-center">
              <p className="font-bold text-red-600">
                Tiempo restante: {formatTime(timeLeft)}
              </p>
              <div className="w-full bg-gray-200 h-4 rounded">
                <div
                  className="bg-red-500 h-4 rounded"
                  style={{ width: `${(timeLeft / (simulationTime * 60)) * 100}%` }}
                />
              </div>
            </div>
          )}
        </div>

        {/* Filtros en modo estudio */}
        {!simulationMode && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Filtrar por Año:
              </label>
              <select
                value={selectedYear}
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                  setCurrentQuestion(0);
                }}
                className="w-full p-2 border border-gray-300 rounded-lg"
              >
                <option value="Todos">Todos</option>
                {years.sort().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Filtrar por Subtema:
              </label>
              <select
                value={selectedSubtopic}
                onChange={(e) => {
                  setSelectedSubtopic(e.target.value);
                  setCurrentQuestion(0);
                }}
                className="w-full p-2 border border-gray-300 rounded-lg"
              >
                <option value="Todos">Todos</option>
                {subtopics.map((st) => (
                  <option key={st} value={st}>
                    {st}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        {/* Modo estadísticas vs práctica */}
        {!reviewMode ? (
          <>
            {/* Navegación de preguntas en modo estudio */}
            {!simulationMode && (
              <QuestionNavigation
                questions={filteredQuestions}
                currentQuestion={currentQuestion}
                setCurrentQuestion={setCurrentQuestion}
              />
            )}

            {/* Contenido de la pregunta actual */}
            <div className="mb-6">
              <div className="flex items-start gap-2 mb-4">
                <BookOpen className="w-6 h-6 text-blue-500 flex-shrink-0 mt-1" />
                <div>
                  {currentQuestionData.year && (
                    <div className="text-sm text-gray-500 mb-2">
                      RM {currentQuestionData.year}
                    </div>
                  )}
                  {currentQuestionData.case_description && (
                    <div className="text-gray-800">
                      <InteractiveCaseDescription
                        questionId={currentQuestionData.id}
                        text={currentQuestionData.case_description}
                      />
                    </div>
                  )}

                  {/* Enunciado con resaltado interactivo */}
                  <div className="font-medium mt-2">
                    <InteractiveQuestionText
                      questionId={currentQuestionData.id}
                      text={currentQuestionData.question_text}
                    />
                  </div>
                </div>
              </div>

              {/* Opciones */}
              <div className="space-y-3">
                {currentQuestionData.options.map((option) => (
                  <button
                    key={option.id}
                    onClick={() => !showExplanation && handleAnswer(option.id)}
                    disabled={showExplanation}
                    className={`w-full p-4 text-left rounded-lg transition-all ${
                      !selectedAnswer
                        ? 'hover:bg-gray-50 border border-gray-200'
                        : option.correct
                        ? 'bg-green-50 border-green-500'
                        : option.id === selectedAnswer
                        ? 'bg-red-50 border-red-500'
                        : 'border border-gray-200'
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      {showExplanation && option.correct && (
                        <Check className="w-5 h-5 text-green-500" />
                      )}
                      {showExplanation &&
                        option.id === selectedAnswer &&
                        !option.correct && (
                          <X className="w-5 h-5 text-red-500" />
                        )}
                      <span className="font-medium">{option.option_label})</span>
                      <span>{option.option_text}</span>
                    </div>
                  </button>
                ))}
              </div>
            </div>

            {/* Explicación (solo en modo estudio) */}
            {showExplanation && !simulationMode && (
              <div className="mt-6 p-4 bg-blue-50 rounded-lg">
                <h3 className="font-bold text-blue-800 mb-2">Explicación:</h3>
                <p className="text-blue-900 mb-4">{currentQuestionData.explanation}</p>

                {currentQuestionData.key_points && (
                  <>
                    <h4 className="font-bold text-blue-800 mb-2">Puntos Clave:</h4>
                    <pre className="bg-gray-100 p-2 rounded mb-4">
                      {currentQuestionData.key_points}
                    </pre>
                  </>
                )}

                {currentQuestionData.ascii_diagram && (
                  <>
                    <h4 className="font-bold text-blue-800 mb-2">Esquema (ASCII):</h4>
                    <pre className="bg-gray-100 p-2 rounded mb-4">
                      {currentQuestionData.ascii_diagram}
                    </pre>
                  </>
                )}

                {/* BOTÓN para la Tabla Interactiva */}
                {TableComponent && (
                  <div className="mt-4">
                    <button
                      onClick={() => setShowSubtopicTable(!showSubtopicTable)}
                      className="w-full p-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
                    >
                      {showSubtopicTable
                        ? 'Ocultar Tabla Interactiva'
                        : 'Ver Tabla Interactiva'}
                    </button>
                    {showSubtopicTable && (
                      <div className="mt-4">{TableComponent}</div>
                    )}
                  </div>
                )}

                {/* BOTÓN para el Esquema Interactivo */}
                {EsquemaComponent && (
                  <div className="mt-4">
                    <button
                      onClick={() => setShowSubtopicEsquema(!showSubtopicEsquema)}
                      className="w-full p-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
                    >
                      {showSubtopicEsquema
                        ? 'Ocultar Esquema Interactivo'
                        : 'Ver Esquema Interactivo'}
                    </button>
                    {showSubtopicEsquema && (
                      <div className="mt-4">{EsquemaComponent}</div>
                    )}
                  </div>
                )}

                {/* Mnemotecnia y 'Aprendido' */}
                <LearnMnemonicSection question={currentQuestionData} />

                {/* Tablas de decisión (las que pasas por props) */}
                {DecisionTableComponents && DecisionTableComponents.length > 0 && (
                  <>
                    <button
                      onClick={() => setShowDecisionTable(!showDecisionTable)}
                      className="w-full mt-4 p-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
                    >
                      {showDecisionTable
                        ? 'Ocultar Tabla(s) de Decisión'
                        : 'Ver Tabla(s) de Decisión'}
                    </button>
                    {showDecisionTable &&
                      DecisionTableComponents.map((Comp, idx) => (
                        <div key={idx} className="mt-4">
                          <Comp />
                        </div>
                      ))}
                  </>
                )}

                <button
                  onClick={nextQuestion}
                  className="w-full mt-4 p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  Siguiente
                </button>
              </div>
            )}
          </>
        ) : (
          // Modo revisión de estadísticas
          <div className="space-y-4">
            <h2 className="text-xl font-bold text-gray-800 mb-4">
              Estadísticas por Pregunta
            </h2>
            {questions.map((question) => {
              const stats =
                studyHistory.questionHistory[String(question.id)] || {
                  attempts: 0,
                  correct: 0,
                  lastAttempt: null
                };
              const effectiveness =
                stats.attempts > 0
                  ? Math.round((stats.correct / stats.attempts) * 100)
                  : 0;

              return (
                <div key={question.id} className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex justify-between items-start mb-2">
                    <div className="font-medium">
                      Pregunta {question.id} (RM {question.year})
                    </div>
                    <div
                      className={`text-sm ${
                        effectiveness >= 70
                          ? 'text-green-600'
                          : effectiveness >= 40
                          ? 'text-yellow-600'
                          : 'text-red-600'
                      }`}
                    >
                      {effectiveness}% efectiva
                    </div>
                  </div>
                  <p className="text-sm text-gray-600 mb-2">
                    Intentos: {stats.attempts} | Correctas: {stats.correct}
                  </p>
                  {stats.lastAttempt && (
                    <p className="text-xs text-gray-500">
                      Último intento:{' '}
                      {new Date(stats.lastAttempt).toLocaleDateString()}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {/* Panel de Notas (modificado) */}
        {currentQuestionData && <NotesSidePanel questionId={currentQuestionData.id} />}
      </div>
    </div>
  );
}

/* ==========================================================================
   COMPONENTE: Texto Interactivo (Pregunta)
   Se elimina el uso de botones "S" y "C". Ahora se usa:
     - Click simple para subrayar (amarillo).
     - Doble click para encircular (rojo).
   Además se agrega un botón para limpiar resaltados.
   ==========================================================================
*/
function InteractiveQuestionText({ text, questionId }) {
  // Dividimos en palabras
  // Memorizar el array de palabras para que no cambie en cada render
  const words = useMemo(() => text.split(' '), [text]);

  // Estado de estilos: se inicializa o reinicializa cada vez que cambia questionId o text.
  const [wordStyles, setWordStyles] = useState(() => {
    const saved = localStorage.getItem(`question_text_highlights_${questionId}`);
    if (saved) {
      return JSON.parse(saved);
    }
    return words.map(() => ({ underline: false, circle: false }));
  });

  // Reinicializar el estado cuando cambian questionId o text.
  useEffect(() => {
    const saved = localStorage.getItem(`question_text_highlights_${questionId}`);
    if (saved) {
      setWordStyles(JSON.parse(saved));
    } else {
      const initialStyles = words.map(() => ({ underline: false, circle: false }));
      setWordStyles(initialStyles);
    }
  }, [questionId, text, words]);

  const saveStyles = (newStyles) => {
    localStorage.setItem(`question_text_highlights_${questionId}`, JSON.stringify(newStyles));
  };

  const clickTimerRef = useRef(null);

  const toggleUnderline = (index) => {
    setWordStyles((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        underline: !updated[index].underline
      };
      saveStyles(updated);
      return updated;
    });
  };

  const toggleCircle = (index) => {
    setWordStyles((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        circle: !updated[index].circle
      };
      saveStyles(updated);
      return updated;
    });
  };

  const handleClick = (index) => {
    if (clickTimerRef.current) return;
    clickTimerRef.current = setTimeout(() => {
      toggleUnderline(index);
      clickTimerRef.current = null;
    }, 200);
  };

  const handleDoubleClick = (index) => {
    if (clickTimerRef.current) {
      clearTimeout(clickTimerRef.current);
      clickTimerRef.current = null;
    }
    toggleCircle(index);
  };

  // Botón para limpiar estilos
  const clearStyles = () => {
    const cleared = words.map(() => ({ underline: false, circle: false }));
    setWordStyles(cleared);
    saveStyles(cleared);
  };

  const getClassName = (styles) => {
    let className = 'mx-1 cursor-pointer relative';
    if (styles.underline) {
      className += ' underline decoration-2 decoration-yellow-400';
    }
    if (styles.circle) {
      className += ' inline-block border-2 border-red-400 rounded-full px-1';
    }
    return className;
  };

  return (
    <div className="leading-relaxed">
      {words.map((word, i) => {
        const styles = wordStyles[i] || { underline: false, circle: false };
        return (
          <span
            key={i}
            className={getClassName(styles)}
            onClick={() => handleClick(i)}
            onDoubleClick={() => handleDoubleClick(i)}
          >
            {word}{' '}
          </span>
        );
      })}
      <button
        onClick={clearStyles}
        className="ml-2 px-1 py-0.5 bg-transparent border border-gray-300 text-xs text-gray-500 rounded hover:bg-gray-200 transition-colors"
      >
        Limpiar resaltados
      </button>
    </div>
  );
}

/* ==========================================================================
   COMPONENTE: Texto Interactivo (Case Description)
   Dinámica similar a InteractiveQuestionText pero centrado y en cursiva.
   ==========================================================================
*/
function InteractiveCaseDescription({ text, questionId }) {
  // Dividimos en palabras
  // Memorizar el array de palabras para que no cambie en cada render
  const words = useMemo(() => text.split(' '), [text]);

  // Estado de estilos: se inicializa o reinicializa cada vez que cambia questionId o text.
  const [wordStyles, setWordStyles] = useState(() => {
    const saved = localStorage.getItem(`case_desc_highlights_${questionId}`);
    if (saved) {
      return JSON.parse(saved);
    }
    return words.map(() => ({ underline: false, circle: false }));
  });

  // Reinicializar el estado cuando cambian questionId o text.
  useEffect(() => {
    const saved = localStorage.getItem(`case_desc_highlights_${questionId}`);
    if (saved) {
      setWordStyles(JSON.parse(saved));
    } else {
      const initialStyles = words.map(() => ({ underline: false, circle: false }));
      setWordStyles(initialStyles);
    }
  }, [questionId, text, words]);

  const saveStyles = (newStyles) => {
    localStorage.setItem(`case_desc_highlights_${questionId}`, JSON.stringify(newStyles));
  };

  const clickTimerRef = useRef(null);

  const toggleUnderline = (index) => {
    setWordStyles((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        underline: !updated[index].underline
      };
      saveStyles(updated);
      return updated;
    });
  };

  const toggleCircle = (index) => {
    setWordStyles((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        circle: !updated[index].circle
      };
      saveStyles(updated);
      return updated;
    });
  };

  const handleClick = (index) => {
    if (clickTimerRef.current) return;
    clickTimerRef.current = setTimeout(() => {
      toggleUnderline(index);
      clickTimerRef.current = null;
    }, 200);
  };

  const handleDoubleClick = (index) => {
    if (clickTimerRef.current) {
      clearTimeout(clickTimerRef.current);
      clickTimerRef.current = null;
    }
    toggleCircle(index);
  };

  const clearStyles = () => {
    const cleared = words.map(() => ({ underline: false, circle: false }));
    setWordStyles(cleared);
    saveStyles(cleared);
  };

  const getClassName = (styles) => {
    let className = 'mx-1 cursor-pointer relative';
    if (styles.underline) {
      className += ' underline decoration-2 decoration-yellow-400';
    }
    if (styles.circle) {
      className += ' inline-block border-2 border-red-400 rounded-full px-1';
    }
    return className;
  };

  return (
    <div className="text-center italic leading-relaxed">
      {words.map((word, i) => {
        const styles = wordStyles[i] || { underline: false, circle: false };
        return (
          <span
            key={i}
            className={getClassName(styles)}
            onClick={() => handleClick(i)}
            onDoubleClick={() => handleDoubleClick(i)}
          >
            {word}{' '}
          </span>
        );
      })}
      <button
        onClick={clearStyles}
        className="ml-2 px-1 py-0.5 bg-transparent border border-gray-300 text-xs text-gray-500 rounded hover:bg-gray-200 transition-colors"
      >
        Limpiar resaltados
      </button>
    </div>
  );
}

/* ==========================================================================
   COMPONENTE: Panel lateral de Notas
   Ahora se asegura de usar `notes_${questionId}` para cada pregunta.
   ==========================================================================
*/
function NotesSidePanel({ questionId }) {
  // Clave en localStorage
  const storageKey = `notes_${questionId}`;

  // Estado: mostrar/ocultar panel
  const [showPanel, setShowPanel] = useState(false);

  // Estado: contenido de las notas
  const [notes, setNotes] = useState('');

  // Cargar notas guardadas al iniciar
  useEffect(() => {
    const saved = localStorage.getItem(storageKey);
    if (saved) {
      setNotes(saved);
    } else {
      setNotes('');
    }
  }, [storageKey]);

  // Guardar en localStorage
  const handleSaveNotes = () => {
    localStorage.setItem(storageKey, notes);
  };

  return (
    <>
      {/* Botón flotante para abrir/cerrar el panel */}
      <button
        onClick={() => setShowPanel(!showPanel)}
        className="fixed bottom-6 right-6 z-50 px-4 py-3 bg-indigo-600 text-white rounded-full shadow-lg hover:bg-indigo-700 focus:outline-none"
      >
        {showPanel ? 'Cerrar Notas' : 'Abrir Notas'}
      </button>

      {/* Panel lateral con textarea */}
      <div
        className={`fixed top-0 right-0 h-full w-full sm:w-80 bg-white shadow-xl transform transition-transform duration-300 z-40 ${
          showPanel ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between bg-indigo-600 text-white p-4">
            <h2 className="text-lg font-bold">Notas - Pregunta {questionId}</h2>
            <button onClick={() => setShowPanel(false)} className="text-white">
              ✕
            </button>
          </div>
          <div className="p-4 flex-1 overflow-y-auto">
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Escribe tus notas aquí..."
              className="w-full h-64 p-2 border border-gray-300 rounded"
            />
            <button
              onClick={handleSaveNotes}
              className="mt-4 w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

/* ==========================================================================
   COMPONENTE: Sección de Mnemotecnia / Aprendido
   ==========================================================================
*/
function LearnMnemonicSection({ question }) {
  const ebData = getErrorBankData(String(question.id)) || {
    learned: false,
    mnemonic: ''
  };
  const [isLearned, setIsLearned] = useState(ebData.learned);
  const [mnemonicValue, setMnemonicValue] = useState(ebData.mnemonic || '');
  const [editingMnemonic, setEditingMnemonic] = useState(false);

  useEffect(() => {
    setIsLearned(ebData.learned);
    setMnemonicValue(ebData.mnemonic || '');
    setEditingMnemonic(false);
  }, [question.id, ebData.learned, ebData.mnemonic]);

  const handleToggleLearned = () => {
    toggleLearned(String(question.id));
    setIsLearned(!isLearned);
  };

  const handleSaveMnemonic = () => {
    updateMnemonic(String(question.id), mnemonicValue);
    setEditingMnemonic(false);
  };

  return (
    <div className="mt-4 p-3 bg-white rounded shadow-sm text-gray-800">
      <div className="flex items-center justify-between mb-2">
        <span className="font-bold">¿Aprendido?</span>
        <button
          onClick={handleToggleLearned}
          className={`px-3 py-1 rounded text-white text-sm ${
            isLearned ? 'bg-red-500 hover:bg-red-600' : 'bg-green-600 hover:bg-green-700'
          }`}
        >
          {isLearned ? 'Marcar como NO aprendido' : 'Marcar como Aprendido'}
        </button>
      </div>

      <div className="mt-2">
        <span className="font-bold">Mnemotecnia:</span>
        {!editingMnemonic ? (
          <div className="mt-1">
            {mnemonicValue ? (
              <p className="text-sm text-gray-600 whitespace-pre-wrap">
                {mnemonicValue}
              </p>
            ) : (
              <p className="text-sm italic text-gray-400">No hay mnemotecnia guardada</p>
            )}
            <button
              onClick={() => setEditingMnemonic(true)}
              className="mt-2 px-3 py-1 bg-blue-500 text-white text-sm rounded hover:bg-blue-600"
            >
              {mnemonicValue ? 'Editar' : 'Agregar'}
            </button>
          </div>
        ) : (
          <div className="mt-1">
            <textarea
              rows={3}
              className="w-full border border-gray-300 rounded p-1 text-sm"
              value={mnemonicValue}
              onChange={(e) => setMnemonicValue(e.target.value)}
            />
            <div className="flex gap-2 mt-2">
              <button
                onClick={handleSaveMnemonic}
                className="px-3 py-1 bg-green-600 text-white text-sm rounded hover:bg-green-700"
              >
                Guardar
              </button>
              <button
                onClick={() => {
                  setMnemonicValue(ebData.mnemonic || '');
                  setEditingMnemonic(false);
                }}
                className="px-3 py-1 bg-gray-400 text-white text-sm rounded hover:bg-gray-500"
              >
                Cancelar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

/* ==========================================================================
   COMPONENTE: Navegación de Preguntas
   ==========================================================================
*/
function QuestionNavigation({ questions, currentQuestion, setCurrentQuestion }) {
  return (
    <div className="mb-6">
      <h2 className="text-lg font-bold text-gray-800 mb-2">
        Navegación de Preguntas
      </h2>
      <div className="flex flex-wrap gap-2">
        {questions.map((q, index) => (
          <button
            key={q.id}
            onClick={() => setCurrentQuestion(index)}
            className={`px-3 py-2 rounded-lg border ${
              index === currentQuestion
                ? 'bg-blue-500 text-white border-blue-500'
                : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-100'
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

/* ==========================================================================
   FUNC: Guardar Examen en el Historial
   ==========================================================================
*/
function saveExamToHistory(examData) {
  const stored = localStorage.getItem('examHistory');
  const history = stored ? JSON.parse(stored) : [];
  history.unshift({
    ...examData,
    id: Date.now(),
    removed: examData.removed ?? false
  });
  localStorage.setItem('examHistory', JSON.stringify(history));
}

export default Quiz;