import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';


/**
 * Componente ExamHistory
 * ----------------------
 * - Muestra la lista de simulacros guardados en localStorage.
 * - Permite filtrar entre “activos” y “borrados” con un toggle.
 * - Incluye checkboxes para seleccionar varios simulacros y quitarlos o restaurarlos.
 * - Soporta expansión de “Detalles” (resultado por sistemas, temas, subtemas...)
 *   y “Revisión Detallada” (preguntas + explicaciones).
 * - Se ha modificado para mostrar también breakdown de “Subtema Específico”.
 */

function ExamHistory({ onBack }) {
  const [history, setHistory] = useState([]);

  // Modo borrados (false = ver simulacros activos, true = ver simulacros borrados)
  const [showDeleted, setShowDeleted] = useState(false);

  // Para checkboxes: IDs de exámenes seleccionados
  const [selectedExams, setSelectedExams] = useState([]);

  // Para expandir/colapsar el breakdown de un examen
  const [expandedExamId, setExpandedExamId] = useState(null);

  // Para ver/ocultar la revisión detallada de preguntas de cada examen
  // Usamos un diccionario: { [examId]: boolean }
  const [detailedReviewOpen, setDetailedReviewOpen] = useState({});

  // Carga el historial desde localStorage
  useEffect(() => {
    const stored = localStorage.getItem('examHistory');
    if (stored) {
      setHistory(JSON.parse(stored));
    }
  }, []);

  // Filtra exámenes activos vs. borrados
  const activeExams = history.filter((exam) => !exam.removed);
  const deletedExams = history.filter((exam) => exam.removed);

  // Toggle “Ver Borrados” vs “Ver Activos”
  const toggleShowDeleted = () => {
    setShowDeleted(!showDeleted);
    setSelectedExams([]); // limpiar selección
  };

  // Marcar/destildar un examen en la tabla
  const handleCheckboxChange = (examId) => {
    if (selectedExams.includes(examId)) {
      // si ya está seleccionado, quitarlo
      setSelectedExams(selectedExams.filter((id) => id !== examId));
    } else {
      // si no estaba, agregarlo
      setSelectedExams([...selectedExams, examId]);
    }
  };

  // “Seleccionar todo” o “Deseleccionar todo” de la tabla actual
  const handleSelectAll = (exams) => {
    const allIds = exams.map((exam) => exam.id);
    // Ver si ya todos estaban seleccionados
    const allSelected = allIds.every((id) => selectedExams.includes(id));
    if (allSelected) {
      // deselect all
      setSelectedExams([]);
    } else {
      // select all
      setSelectedExams(allIds);
    }
  };

  // Quitar (marcar removed = true) para los exámenes seleccionados
  const removeSelectedExams = () => {
    const updated = history.map((exam) => {
      if (selectedExams.includes(exam.id)) {
        return { ...exam, removed: true };
      }
      return exam;
    });
    setHistory(updated);
    localStorage.setItem('examHistory', JSON.stringify(updated));
    setSelectedExams([]);
  };

  // Restaurar (removed = false) para los exámenes seleccionados
  const restoreSelectedExams = () => {
    const updated = history.map((exam) => {
      if (selectedExams.includes(exam.id)) {
        return { ...exam, removed: false };
      }
      return exam;
    });
    setHistory(updated);
    localStorage.setItem('examHistory', JSON.stringify(updated));
    setSelectedExams([]);
  };

  // Quitar un examen individual
  const removeSingleExam = (examId) => {
    const updated = history.map((exam) => {
      if (exam.id === examId) {
        return { ...exam, removed: true };
      }
      return exam;
    });
    setHistory(updated);
    localStorage.setItem('examHistory', JSON.stringify(updated));
    setSelectedExams(selectedExams.filter((id) => id !== examId));
  };

  // Restaurar un examen individual
  const restoreSingleExam = (examId) => {
    const updated = history.map((exam) => {
      if (exam.id === examId) {
        return { ...exam, removed: false };
      }
      return exam;
    });
    setHistory(updated);
    localStorage.setItem('examHistory', JSON.stringify(updated));
    setSelectedExams(selectedExams.filter((id) => id !== examId));
  };

  // Borrar DEFINITIVO un examen (lo sacamos del array) => se pierde
  const permanentlyDeleteSelected = () => {
    const updated = history.filter((exam) => !selectedExams.includes(exam.id));
    setHistory(updated);
    localStorage.setItem('examHistory', JSON.stringify(updated));
    setSelectedExams([]);
  };

  // Borrar todo el historial
  const handleClearHistory = () => {
    localStorage.removeItem('examHistory');
    setHistory([]);
    setSelectedExams([]);
  };

  // Toggle colapsar/expandir breakdown
  const toggleExpand = (examId) => {
    setExpandedExamId((prev) => (prev === examId ? null : examId));
  };

  // Toggle la revisión detallada
  const toggleDetailedReview = (examId) => {
    setDetailedReviewOpen((prev) => ({
      ...prev,
      [examId]: !prev[examId],
    }));
  };

  // Render principal
  // ----------------
  // Seleccionamos la lista a mostrar según showDeleted
  const examsToShow = showDeleted ? deletedExams : activeExams;

  // Para el checkbox de “seleccionar todos”
  const allSelectedThisView =
    examsToShow.length > 0 &&
    examsToShow.every((ex) => selectedExams.includes(ex.id));

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <motion.div
        className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-lg"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">
            Historial de Simulacros
          </h2>
          <button
            onClick={onBack}
            className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500"
          >
            Volver
          </button>
        </div>

        {/* Botones superiores */}
        <div className="flex flex-wrap gap-2 justify-between items-center mb-4">
          <div className="flex gap-2">
            <button
              onClick={toggleShowDeleted}
              className={`px-4 py-2 rounded-lg font-semibold transition ${
                showDeleted
                  ? 'bg-green-600 text-white hover:bg-green-700'
                  : 'bg-blue-600 text-white hover:bg-blue-700'
              }`}
            >
              {showDeleted ? 'Ver Activos' : 'Ver Borrados'}
            </button>

            {!showDeleted ? (
              // Modo Activos
              <motion.button
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
                disabled={selectedExams.length === 0}
                onClick={removeSelectedExams}
                className={`px-4 py-2 rounded-lg font-semibold transition ${
                  selectedExams.length === 0
                    ? 'bg-gray-300 text-gray-700 cursor-not-allowed'
                    : 'bg-red-500 text-white hover:bg-red-600'
                }`}
              >
                Quitar Seleccionados
              </motion.button>
            ) : (
              // Modo Borrados
              <div className="flex gap-2">
                <motion.button
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.97 }}
                  disabled={selectedExams.length === 0}
                  onClick={restoreSelectedExams}
                  className={`px-4 py-2 rounded-lg font-semibold transition ${
                    selectedExams.length === 0
                      ? 'bg-gray-300 text-gray-700 cursor-not-allowed'
                      : 'bg-green-500 text-white hover:bg-green-600'
                  }`}
                >
                  Restaurar Seleccionados
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.97 }}
                  disabled={selectedExams.length === 0}
                  onClick={permanentlyDeleteSelected}
                  className={`px-4 py-2 rounded-lg font-semibold transition ${
                    selectedExams.length === 0
                      ? 'bg-gray-300 text-gray-700 cursor-not-allowed'
                      : 'bg-red-500 text-white hover:bg-red-600'
                  }`}
                >
                  Borrar Definitivo
                </motion.button>
              </div>
            )}
          </div>

          {/* Botón “Borrar Todo” */}
          <motion.button
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.97 }}
            onClick={handleClearHistory}
            className="px-4 py-2 bg-red-700 text-white rounded-lg hover:bg-red-800 font-semibold"
          >
            Borrar Todo el Historial
          </motion.button>
        </div>

        {/* Tabla */}
        {examsToShow.length === 0 ? (
          <p className="text-center text-gray-600">
            {showDeleted
              ? 'No hay simulacros borrados.'
              : 'No hay simulacros en el historial.'}
          </p>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100 text-gray-800">
                  <th className="border p-2 text-left">
                    {/* Checkbox Select All */}
                    <input
                      type="checkbox"
                      checked={allSelectedThisView}
                      onChange={() => handleSelectAll(examsToShow)}
                    />
                  </th>
                  <th className="border p-2">Título</th>
                  <th className="border p-2">Fecha</th>
                  <th className="border p-2">Hora</th>
                  <th className="border p-2">#Preg.</th>
                  <th className="border p-2">Correct.</th>
                  <th className="border p-2">%</th>
                  <th className="border p-2 text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {examsToShow.map((exam) => {
                  const isSelected = selectedExams.includes(exam.id);
                  return (
                    <React.Fragment key={exam.id}>
                      <tr
                        className="hover:bg-gray-50 transition"
                        style={{
                          opacity: isSelected ? 0.85 : 1,
                        }}
                      >
                        <td className="border p-2 text-center">
                          <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={() => handleCheckboxChange(exam.id)}
                          />
                        </td>
                        <td className="border p-2">{exam.examTitle}</td>
                        <td className="border p-2">{exam.date}</td>
                        <td className="border p-2">{exam.time}</td>
                        <td className="border p-2 text-center">{exam.totalQuestions}</td>
                        <td className="border p-2 text-center">{exam.correct}</td>
                        <td className="border p-2 text-center">{exam.scorePercentage}%</td>
                        <td className="border p-2 text-center space-x-2">
                          {/* Botón Ver/Ocultar Breakdown */}
                          {exam.breakdown ? (
                            <motion.button
                              whileHover={{ scale: 1.04 }}
                              whileTap={{ scale: 0.96 }}
                              onClick={() => toggleExpand(exam.id)}
                              className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm"
                            >
                              {expandedExamId === exam.id ? 'Ocultar' : 'Detalles'}
                            </motion.button>
                          ) : (
                            <span className="text-gray-400 text-sm">Sin Detalles</span>
                          )}

                          {/* Botón Revisión Detallada */}
                          {exam.questionDetails && exam.questionDetails.length > 0 && (
                            <motion.button
                              whileHover={{ scale: 1.04 }}
                              whileTap={{ scale: 0.96 }}
                              onClick={() => toggleDetailedReview(exam.id)}
                              className="px-3 py-1 bg-indigo-500 text-white rounded hover:bg-indigo-600 text-sm"
                            >
                              {detailedReviewOpen[exam.id]
                                ? 'Ocultar Rev.'
                                : 'Ver Rev.'}
                            </motion.button>
                          )}

                          {/* Quitar o Restaurar individual */}
                          {!showDeleted ? (
                            <motion.button
                              whileHover={{ scale: 1.04 }}
                              whileTap={{ scale: 0.96 }}
                              className="px-2 py-1 bg-red-500 text-white rounded text-sm hover:bg-red-600"
                              onClick={() => removeSingleExam(exam.id)}
                            >
                              Quitar
                            </motion.button>
                          ) : (
                            <div className="inline-flex gap-1">
                              <motion.button
                                whileHover={{ scale: 1.04 }}
                                whileTap={{ scale: 0.96 }}
                                className="px-2 py-1 bg-green-500 text-white rounded text-sm hover:bg-green-600"
                                onClick={() => restoreSingleExam(exam.id)}
                              >
                                Restaurar
                              </motion.button>
                              <motion.button
                                whileHover={{ scale: 1.04 }}
                                whileTap={{ scale: 0.96 }}
                                className="px-2 py-1 bg-red-600 text-white rounded text-sm hover:bg-red-700"
                                onClick={() => {
                                  // Borrado definitivo
                                  setSelectedExams([exam.id]);
                                  permanentlyDeleteSelected();
                                }}
                              >
                                Del
                              </motion.button>
                            </div>
                          )}
                        </td>
                      </tr>

                      {/* BreakDown expandido */}
                      {expandedExamId === exam.id && exam.breakdown && (
                        <tr>
                          <td colSpan={8} className="border p-4 bg-gray-50">
                            <BreakdownTable breakdown={exam.breakdown} />
                          </td>
                        </tr>
                      )}

                      {/* Revisión Detallada */}
                      {detailedReviewOpen[exam.id] && exam.questionDetails && (
                        <tr>
                          <td colSpan={8} className="border p-4 bg-white">
                            <DetailedReview questionDetails={exam.questionDetails} />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </motion.div>
    </div>
  );
}

/* Subcomponente que renderiza la sección de breakdown (errores por órgano, tema, subtema, etc.)
   Se ha modificado para incluir la parte de subtopic_specific */
function BreakdownTable({ breakdown }) {
  const {
    errorsByOrganSystem = {},
    resultsByTopic = [],
    resultsBySubtopic = [],
    // NUEVO:
    errorsBySubtopicSpecific = {},
    resultsBySubtopicSpecific = []
  } = breakdown;

  return (
    <div className="space-y-6">
      {/* Errores por Órgano/Sistema */}
      <div>
        <h4 className="font-bold text-gray-700 mb-2">Errores por Órgano/Sistema</h4>
        {Object.keys(errorsByOrganSystem).length === 0 ? (
          <p className="text-sm text-gray-600">No hubo errores en este aspecto.</p>
        ) : (
          <table className="w-full border-collapse mb-4 text-sm">
            <thead>
              <tr className="bg-gray-200">
                <th className="border p-2">Órgano/Sistema</th>
                <th className="border p-2">Errores</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(errorsByOrganSystem).map(([os, count]) => (
                <tr key={os}>
                  <td className="border p-2">{os}</td>
                  <td className="border p-2">{count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Resultados por Tema */}
      <div>
        <h4 className="font-bold text-gray-700 mb-2">Resultados por Tema</h4>
        <table className="w-full border-collapse text-sm mb-4">
          <thead>
            <tr className="bg-gray-200">
              <th className="border p-2">Tema</th>
              <th className="border p-2">Total</th>
              <th className="border p-2">Correctas</th>
              <th className="border p-2">Errores</th>
            </tr>
          </thead>
          <tbody>
            {resultsByTopic.map((row) => (
              <tr key={row.topic}>
                <td className="border p-2">{row.topic}</td>
                <td className="border p-2">{row.total}</td>
                <td className="border p-2 text-green-700">{row.correct}</td>
                <td className="border p-2 text-red-700">{row.errors}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Resultados por Subtema */}
      <div>
        <h4 className="font-bold text-gray-700 mb-2">Resultados por Subtema</h4>
        <table className="w-full border-collapse text-sm mb-4">
          <thead>
            <tr className="bg-gray-200">
              <th className="border p-2">Subtema</th>
              <th className="border p-2">Total</th>
              <th className="border p-2">Correctas</th>
              <th className="border p-2">Errores</th>
            </tr>
          </thead>
          <tbody>
            {resultsBySubtopic.map((row) => (
              <tr key={row.subtopic}>
                <td className="border p-2">{row.subtopic}</td>
                <td className="border p-2">{row.total}</td>
                <td className="border p-2 text-green-700">{row.correct}</td>
                <td className="border p-2 text-red-700">{row.errors}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* NUEVO: Errores por Subtema Específico */}
      <div>
        <h4 className="font-bold text-gray-700 mb-2">Errores por Subtema Específico</h4>
        {Object.keys(errorsBySubtopicSpecific).length === 0 ? (
          <p className="text-sm text-gray-600">No hubo errores en los subtemas específicos.</p>
        ) : (
          <table className="w-full border-collapse mb-4 text-sm">
            <thead>
              <tr className="bg-gray-200">
                <th className="border p-2">Subtema Específico</th>
                <th className="border p-2">Errores</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(errorsBySubtopicSpecific).map(([sps, count]) => (
                <tr key={sps}>
                  <td className="border p-2">{sps}</td>
                  <td className="border p-2">{count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* NUEVO: Resultados por Subtema Específico */}
      <div>
        <h4 className="font-bold text-gray-700 mb-2">Resultados por Subtema Específico</h4>
        {resultsBySubtopicSpecific.length === 0 ? (
          <p className="text-sm text-gray-600">No hay registros de subtemas específicos.</p>
        ) : (
          <table className="w-full border-collapse text-sm">
            <thead>
              <tr className="bg-gray-200">
                <th className="border p-2">Subtema Específico</th>
                <th className="border p-2">Total</th>
                <th className="border p-2">Correctas</th>
                <th className="border p-2">Errores</th>
              </tr>
            </thead>
            <tbody>
              {resultsBySubtopicSpecific.map((row) => (
                <tr key={row.subtopic_specific}>
                  <td className="border p-2">{row.subtopic_specific}</td>
                  <td className="border p-2">{row.total}</td>
                  <td className="border p-2 text-green-700">{row.correct}</td>
                  <td className="border p-2 text-red-700">{row.errors}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

/* Subcomponente para la “Revisión Detallada” (preguntas+explicaciones) */
function DetailedReview({ questionDetails }) {
  return (
    <div className="max-h-96 overflow-y-auto space-y-4">
      {questionDetails.map((qd, index) => {
        const userAnswer = qd.userAnswer;
        return (
          <div key={qd.id} className="border p-3 rounded hover:shadow-inner transition">
            <h4 className="font-bold text-gray-700">
              Pregunta {index + 1} (RM {qd.year})
            </h4>

            {/* case_description */}
            {qd.case_description && (
              <p className="mt-2 italic text-gray-700">{qd.case_description}</p>
            )}

            {/* question_text */}
            <p className="mt-2 font-semibold text-gray-800">{qd.question_text}</p>

            {/* Opciones */}
            {qd.options && (
              <div className="mt-2">
                {qd.options.map((opt) => {
                  const isUserAnswer = opt.id === userAnswer;
                  const isCorrect = !!opt.correct;
                  let bgColor = '';
                  if (isUserAnswer) {
                    bgColor = isCorrect ? 'bg-green-100' : 'bg-red-100';
                  }
                  return (
                    <div
                      key={opt.id}
                      className={`p-2 rounded ${bgColor} flex items-center gap-2`}
                    >
                      <span className="font-semibold">{opt.option_label})</span>
                      <span>{opt.option_text}</span>
                      {isCorrect && (
                        <span className="ml-2 text-green-700 text-sm">(Correcta)</span>
                      )}
                      {isUserAnswer && !isCorrect && (
                        <span className="ml-2 text-red-700 text-sm">(Tu Respuesta)</span>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            {/* Explicación */}
            {qd.explanation && (
              <div className="mt-2">
                <h5 className="font-semibold text-blue-700">Explicación:</h5>
                <p className="text-gray-700">{qd.explanation}</p>
              </div>
            )}

            {/* key_points */}
            {qd.key_points && (
              <pre className="bg-gray-100 p-2 rounded mt-1 text-xs text-gray-800">
                {qd.key_points}
              </pre>
            )}

            {/* ascii_diagram */}
            {qd.ascii_diagram && (
              <pre className="bg-gray-100 p-2 rounded mt-1 text-xs text-gray-800">
                {qd.ascii_diagram}
              </pre>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ExamHistory;