import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactFlow, { Background, Controls, MiniMap } from 'react-flow-renderer';
import Modal from 'react-modal';
import { FaExpand, FaCompress, FaMoon, FaSun } from 'react-icons/fa';

Modal.setAppElement('#root');

const nodes = [
  // 1. Nódulo Tiroideo Detectado
  {
    id: '1',
    data: {
      title: "Nódulo Tiroideo Detectado",
      label: (
        <div>
          <strong>Nódulo Detectado</strong>
          <br />(Hallazgo inicial)
        </div>
      ),
      description: "Identificación inicial del nódulo, detectado de forma incidental o durante el examen físico."
    },
    position: { x: 300, y: 0 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f0f0f0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 2. Evaluación Clínica
  {
    id: '2',
    data: {
      title: "Evaluación Clínica",
      label: (
        <div>
          <strong>Evaluación Clínica</strong>
          <br />(Historia y examen físico)
        </div>
      ),
      description: "Recopilación de datos clínicos: antecedentes, palpación y síntomas, para orientar la evaluación."
    },
    position: { x: 300, y: 100 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 3. Dosaje de TSH
  {
    id: '3',
    data: {
      title: "Dosaje de TSH",
      label: (
        <div>
          <strong>Dosaje de TSH</strong>
          <br />(Función tiroidea)
        </div>
      ),
      description: "Medición de TSH para evaluar la función tiroidea. Si la TSH es baja se sugiere nódulo hiperfuncional."
    },
    position: { x: 300, y: 180 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#e8e8e8',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 13. Gammagrafía Tiroidea (rama TSH baja)
  {
    id: '13',
    data: {
      title: "Gammagrafía Tiroidea",
      label: (
        <div>
          <strong>Gammagrafía</strong>
          <br />(TSH baja)
        </div>
      ),
      description: "Se utiliza para identificar nódulos 'calientes' en caso de TSH baja, que suelen ser benignos."
    },
    position: { x: 100, y: 250 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f0f0f0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 4. Ecografía Tiroidea (rama TSH normal/alta)
  {
    id: '4',
    data: {
      title: "Ecografía Tiroidea",
      label: (
        <div>
          <strong>Ecografía</strong>
          <br />(TSH normal/alta)
        </div>
      ),
      description: "Ultrasonido para medir el nódulo (ej.: 10 mm) y evaluar características morfológicas (ecogenicidad, bordes, microcalcificaciones, forma 'taller que ancho')."
    },
    position: { x: 500, y: 250 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f0f0f0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 5. Evaluación Ecográfica
  {
    id: '5',
    data: {
      title: "Evaluación Ecográfica",
      label: (
        <div>
          <strong>Evaluación Ecográfica</strong>
          <br />(Caracterización)
        </div>
      ),
      description: "Determinación de si el nódulo es sospechoso: tamaño ≥1 cm o presencia de rasgos de malignidad."
    },
    position: { x: 500, y: 350 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 6. Seguimiento (Bajo Riesgo)
  {
    id: '6',
    data: {
      title: "Seguimiento (Bajo Riesgo)",
      label: (
        <div>
          <strong>Seguimiento</strong>
          <br />(No sospechoso)
        </div>
      ),
      description: "En nódulos < 1 cm y sin características sospechosas, se recomienda seguimiento clínico y ecográfico."
    },
    position: { x: 300, y: 450 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f0f0f0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 7. FNA (PAAF)
  {
    id: '7',
    data: {
      title: "FNA (PAAF)",
      label: (
        <div>
          <strong>FNA (PAAF)</strong>
          <br />(Nódulo sospechoso)
        </div>
      ),
      description: "Indicada en nódulos ≥ 1 cm o con rasgos sospechosos, para obtener muestra citológica."
    },
    position: { x: 700, y: 450 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 8. Citología
  {
    id: '8',
    data: {
      title: "Citología",
      label: (
        <div>
          <strong>Citología</strong>
          <br />(Resultado FNA)
        </div>
      ),
      description: "Análisis citológico para determinar la naturaleza benigna o maligna del nódulo."
    },
    position: { x: 700, y: 550 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f0f0f0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 9. Diagnóstico Benigno
  {
    id: '9',
    data: {
      title: "Diagnóstico Benigno",
      label: (
        <div>
          <strong>Benigno</strong>
          <br />(Citología)
        </div>
      ),
      description: "La citología confirma la benignidad del nódulo, orientando a seguimiento."
    },
    position: { x: 600, y: 650 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 10. Diagnóstico Maligno
  {
    id: '10',
    data: {
      title: "Diagnóstico Maligno",
      label: (
        <div>
          <strong>Maligno</strong>
          <br />(Citología)
        </div>
      ),
      description: "La citología indica malignidad, requiriendo intervención terapéutica."
    },
    position: { x: 800, y: 650 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 11. Manejo (Quirúrgico/Terapéutico)
  {
    id: '11',
    data: {
      title: "Manejo (Quirúrgico/Terapéutico)",
      label: (
        <div>
          <strong>Manejo</strong>
          <br />(Maligno)
        </div>
      ),
      description: "Intervención mediante cirugía, radioyodo u otras terapias según la extensión y riesgo."
    },
    position: { x: 800, y: 750 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f0f0f0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 12. Seguimiento (Post-Citológico)
  {
    id: '12',
    data: {
      title: "Seguimiento (Post-Citológico)",
      label: (
        <div>
          <strong>Seguimiento</strong>
          <br />(Benigno)
        </div>
      ),
      description: "Control clínico y ecográfico periódico en nódulos con citología benigna."
    },
    position: { x: 600, y: 750 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  }
];

const edges = [
  // De Nódulo Detectado a Evaluación Clínica
  { id: 'e1-2', source: '1', target: '2', animated: true },
  // De Evaluación Clínica a Dosaje de TSH
  { id: 'e2-3', source: '2', target: '3', animated: true },
  // Rama: Dosaje de TSH -> Gammagrafía (TSH baja)
  { id: 'e3-13', source: '3', target: '13', animated: false },
  // Rama: Dosaje de TSH -> Ecografía (TSH normal/alta)
  { id: 'e3-4', source: '3', target: '4', animated: true },
  // De Ecografía a Evaluación Ecográfica
  { id: 'e4-5', source: '4', target: '5', animated: true },
  // Rama: Evaluación Ecográfica -> Seguimiento (Bajo Riesgo)
  { id: 'e5-6', source: '5', target: '6', animated: false },
  // Rama: Evaluación Ecográfica -> FNA (Nódulo sospechoso)
  { id: 'e5-7', source: '5', target: '7', animated: true },
  // De FNA a Citología
  { id: 'e7-8', source: '7', target: '8', animated: true },
  // Rama de Citología: a Diagnóstico Benigno
  { id: 'e8-9', source: '8', target: '9', animated: false },
  // Rama de Citología: a Diagnóstico Maligno
  { id: 'e8-10', source: '8', target: '10', animated: true },
  // De Diagnóstico Benigno a Seguimiento (Post-Citológico)
  { id: 'e9-12', source: '9', target: '12', animated: false },
  // De Diagnóstico Maligno a Manejo (Quirúrgico/Terapéutico)
  { id: 'e10-11', source: '10', target: '11', animated: true }
];

const ConceptMapInteractive = () => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  const onNodeClick = useCallback((event, node) => {
    setSelectedNode(node);
  }, []);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current.requestFullscreen().catch((err) => {
        console.error(`Error al activar pantalla completa: ${err.message}`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  const toggleTheme = () => {
    setIsDarkTheme((prev) => !prev);
  };

  const closeModal = () => {
    setSelectedNode(null);
  };

  const containerStyle = {
    height: '100vh',
    width: '100%',
    position: 'relative',
    background: isDarkTheme
      ? 'linear-gradient(135deg, #2c3e50, #34495e)'
      : '#f7f7f7',
    transition: 'background 0.5s ease',
    overflow: 'hidden'
  };

  const fullscreenHeaderStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: '15px 30px',
    background: isDarkTheme ? 'rgba(0, 0, 0, 0.85)' : 'rgba(255, 255, 255, 0.85)',
    color: isDarkTheme ? '#fff' : '#333',
    zIndex: 1500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0 2px 10px rgba(0,0,0,0.5)',
    backdropFilter: 'blur(5px)'
  };

  const headerTitleStyle = {
    fontSize: '1.5em',
    fontWeight: 'bold'
  };

  const buttonStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1em',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  };

  const toggleButtonStyle = {
    ...buttonStyle,
    backgroundColor: isFullscreen ? '#e74c3c' : '#3498db',
    color: '#fff'
  };

  const themeButtonStyle = {
    ...buttonStyle,
    backgroundColor: isDarkTheme ? '#f1c40f' : '#34495e',
    color: '#fff'
  };

  const fullScreenButtonStyle = {
    ...toggleButtonStyle,
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: 1500
  };

  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      backdropFilter: 'blur(5px)',
      zIndex: 1600,
      transition: 'opacity 0.3s ease'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      padding: '25px',
      borderRadius: '10px',
      width: '320px',
      backgroundColor: isDarkTheme ? '#2c3e50' : '#fff',
      color: isDarkTheme ? '#ecf0f1' : '#333',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',
      transition: 'opacity 0.3s ease'
    }
  };

  return (
    <div ref={containerRef} style={containerStyle}>
      {isFullscreen && (
        <div style={fullscreenHeaderStyle}>
          <div style={headerTitleStyle}>Mapa Conceptual - Nódulo Tiroideo</div>
          <div style={{ display: 'flex', gap: '15px' }}>
            <button onClick={toggleTheme} style={themeButtonStyle}>
              {isDarkTheme ? <FaSun /> : <FaMoon />}
              {isDarkTheme ? 'Claro' : 'Oscuro'}
            </button>
            <button onClick={toggleFullScreen} style={toggleButtonStyle}>
              <FaCompress />
              Salir
            </button>
          </div>
        </div>
      )}

      {/* Botones en modo normal (abajo a la derecha) */}
      {!isFullscreen && (
        <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1500, display: 'flex', gap: '10px' }}>
          <button onClick={toggleTheme} style={themeButtonStyle}>
            {isDarkTheme ? <FaSun /> : <FaMoon />}
            {isDarkTheme ? 'Claro' : 'Oscuro'}
          </button>
          <button onClick={toggleFullScreen} style={fullScreenButtonStyle}>
            <FaExpand />
            Pantalla Completa
          </button>
        </div>
      )}

      <ReactFlow nodes={nodes} edges={edges} onNodeClick={onNodeClick} fitView>
        <Background color={isDarkTheme ? "#555" : "#aaa"} gap={16} />
        <Controls />
        <MiniMap
          nodeStrokeColor={(n) =>
            n.style?.background ? n.style.background : '#000'
          }
          nodeColor={(n) => n.style?.background || '#fff'}
        />
      </ReactFlow>

      <Modal
        parentSelector={() =>
          isFullscreen && containerRef.current
            ? containerRef.current
            : document.body
        }
        isOpen={!!selectedNode}
        onRequestClose={closeModal}
        contentLabel="Detalles del nodo"
        style={modalStyles}
      >
        {selectedNode && (
          <>
            <h2 style={{ marginTop: 0 }}>{selectedNode.data.title}</h2>
            <div style={{ marginBottom: '10px' }}>
              {selectedNode.data.label}
            </div>
            <p>{selectedNode.data.description}</p>
            <button
              onClick={closeModal}
              style={{
                marginTop: '10px',
                padding: '10px 15px',
                backgroundColor: '#3498db',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
            >
              Cerrar
            </button>
          </>
        )}
      </Modal>
    </div>
  );
};

export default ConceptMapInteractive;