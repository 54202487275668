import React, { useState } from 'react';
import '../components/FlashcardCell.css'; // Asegúrate de que el path es correcto

function NoduloTiroideoTable() {
  const [hiddenCells, setHiddenCells] = useState({});
  const [hiddenColumns, setHiddenColumns] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  // Datos de la tabla: Evaluación y Manejo de Nódulos Tiroideos
  const tableData = [
    {
      fase: "1. Evaluación Clínica",
      evaluacion: "Historia y examen físico",
      criterios: "Palpación del nódulo, movilidad, síntomas locales (dolor, adenopatías), antecedentes personales y familiares",
      procedimiento: "Recolección de datos clínicos para orientar la evaluación inicial",
      notas: "Nódulos asintomáticos en evaluación de rutina suelen pasar a estudio ecográfico",
    },
    {
      fase: "2. Función Tiroidea (TSH)",
      evaluacion: "Dosaje de TSH",
      criterios: "- TSH baja: sugiere nódulo hiperfuncional\n- TSH normal o alta: no hiperfuncional",
      procedimiento: "- TSH baja: realizar gammagrafía tiroidea para identificar nódulos 'calientes'\n- TSH normal/alta: proceder a ecografía",
      notas: "La medición precisa de TSH orienta el siguiente paso diagnóstico",
    },
    {
      fase: "3. Ecografía Tiroidea",
      evaluacion: "Ecografía cervical",
      criterios: "- Medición precisa en milímetros (e.g., 10 mm)\n- Características ecográficas: ecogenicidad, márgenes, microcalcificaciones, forma “taller que ancho”",
      procedimiento: "- Nódulo <1 cm sin rasgos sospechosos: seguimiento\n- Nódulo ≥1 cm o con características sospechosas: FNA (punción aspiración con aguja fina)",
      notas: "La mención de ‘10 mm’ implica que ya se realizó ecografía y se conocen detalles morfológicos",
    },
    {
      fase: "4. Diagnóstico Citológico",
      evaluacion: "FNA (Punción aspiración con aguja fina)",
      criterios: "Confirmación de la naturaleza del nódulo (benigno vs. maligno)",
      procedimiento: "Realizar FNA en nódulos ≥1 cm con rasgos sospechosos o en aquellos de menor tamaño si existen factores de riesgo adicionales",
      notas: "Es el método diagnóstico definitivo para confirmar malignidad o benignidad del nódulo",
    },
    {
      fase: "5. Manejo Posterior",
      evaluacion: "Resultados de FNA y seguimiento clínico",
      criterios: "- Nódulo benigno: seguimiento con control ecográfico y clínico\n- Nódulo maligno: manejo quirúrgico o terapéutico",
      procedimiento: "Plan de manejo según los resultados citológicos: seguimiento en nódulos benignos o intervención (quirúrgica) en caso de malignidad",
      notas: "La decisión final depende de la correlación entre hallazgos ecográficos, clínicos y citológicos",
    },
  ];

  const columnDisplayNames = {
    fase: 'Fase',
    evaluacion: 'Evaluación / Medida',
    criterios: 'Criterios Clave',
    procedimiento: 'Procedimiento / Conducta',
    notas: 'Notas y Ejemplos',
  };

  const columns = Object.keys(columnDisplayNames);

  // Función para voltear una celda individual
  const toggleCell = (rowIdx, fieldKey) => {
    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prevState) => ({
      ...prevState,
      [cellKey]: !prevState[cellKey],
    }));
  };

  // Función para voltear (ocultar/mostrar) todas las celdas
  const toggleAllCells = () => {
    const areAllCellsFlipped = Object.keys(hiddenCells).length > 0;
    if (!areAllCellsFlipped) {
      const newHiddenCells = {};
      tableData.forEach((_, rowIdx) => {
        columns.forEach((colKey) => {
          const cellKey = `${rowIdx}-${colKey}`;
          newHiddenCells[cellKey] = true;
        });
      });
      setHiddenCells(newHiddenCells);
    } else {
      setHiddenCells({});
    }
  };

  // Función para ocultar/mostrar una columna completa
  const toggleColumn = (columnKey) => {
    setHiddenColumns((prevState) => {
      const newHiddenColumns = {
        ...prevState,
        [columnKey]: !prevState[columnKey],
      };

      const newHiddenCells = { ...hiddenCells };
      tableData.forEach((_, rowIdx) => {
        const cellKey = `${rowIdx}-${columnKey}`;
        if (newHiddenColumns[columnKey]) {
          newHiddenCells[cellKey] = true;
        } else {
          delete newHiddenCells[cellKey];
        }
      });

      setHiddenCells(newHiddenCells);
      return newHiddenColumns;
    });
  };

  // Función para abrir el modal con el contenido de la celda
  const handleRevealText = (text, rowIdx, fieldKey) => {
    setModalContent(text);
    setIsModalOpen(true);

    // Restablece el flip de la celda al abrir el modal
    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prev) => {
      const newState = { ...prev };
      if (newState[cellKey]) {
        delete newState[cellKey];
      }
      return newState;
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  // Función para alternar pantalla completa
  const toggleFullScreen = () => {
    const elem = document.getElementById("tablaContainer");
    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        alert(`Error al intentar habilitar pantalla completa: ${err.message}`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <div className="overflow-x-auto w-full p-4 bg-white shadow-lg rounded-md" id="tablaContainer">
      <h2 className="font-bold text-blue-800 mb-4 text-center text-3xl">
        Tabla Completa de Evaluación y Manejo de Nódulos Tiroideos
      </h2>

      {/* Botones para controlar la tabla */}
      <div className="flex flex-wrap justify-center gap-4 mb-6">
        <button
          onClick={toggleAllCells}
          className="px-4 py-2 rounded bg-green-600 text-white hover:bg-green-700 transition font-semibold"
        >
          {Object.keys(hiddenCells).length === 0
            ? 'Ocultar toda la información'
            : 'Mostrar toda la información'}
        </button>
        {columns.map((columnKey) => (
          <button
            key={columnKey}
            onClick={() => toggleColumn(columnKey)}
            className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600 transition font-semibold"
          >
            {hiddenColumns[columnKey]
              ? `Mostrar ${columnDisplayNames[columnKey]}`
              : `Ocultar ${columnDisplayNames[columnKey]}`}
          </button>
        ))}
        {/* Botón para pantalla completa */}
        <button
          onClick={toggleFullScreen}
          className="px-4 py-2 rounded bg-yellow-500 text-white hover:bg-yellow-600 transition font-semibold"
        >
          Pantalla Completa
        </button>
      </div>

      {/* Tabla con separación horizontal y vertical */}
      <table className="border-separate border-spacing-x-12 border-spacing-y-12 min-w-[3000px] table-auto">
        <thead>
          <tr>
            {columns.map(
              (columnKey) =>
                !hiddenColumns[columnKey] && (
                  <th
                    key={columnKey}
                    className="border-b-2 border-gray-300 bg-blue-100 text-center p-4 font-semibold text-gray-700 whitespace-pre-wrap break-words leading-relaxed"
                  >
                    {columnDisplayNames[columnKey]}
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {tableData.map((item, rowIdx) => (
            <tr key={rowIdx} className="transition duration-200 hover:bg-gray-50">
              {columns.map((colKey) => {
                if (hiddenColumns[colKey]) return null;
                const cellKey = `${rowIdx}-${colKey}`;
                return (
                  <td
                    key={colKey}
                    className="p-4 border-b border-gray-200 whitespace-pre-wrap break-words leading-relaxed align-top"
                  >
                    <div
                      className={`flashcard-cell ${hiddenCells[cellKey] ? 'flipped' : ''}`}
                      title="Haz click para voltear la tarjeta"
                    >
                      <div className="flashcard-cell-inner">
                        <div
                          className="flashcard-cell-front rounded-md shadow-md bg-white p-4 whitespace-pre-wrap break-words leading-relaxed"
                          onClick={() => toggleCell(rowIdx, colKey)}
                        >
                          {Array.isArray(item[colKey])
                            ? item[colKey].join('\n')
                            : item[colKey]}
                        </div>
                        <div
                          className="flashcard-cell-back rounded-md shadow-md bg-gray-50 p-4 whitespace-pre-wrap break-words leading-relaxed"
                          onClick={(e) => e.stopPropagation()}
                          title="Haz click para ver detalles en modal"
                        >
                          <button
                            className="text-blue-600 underline font-semibold hover:text-blue-800"
                            onClick={() =>
                              handleRevealText(
                                Array.isArray(item[colKey])
                                  ? `${columnDisplayNames[colKey]}: ${item[colKey].join('\n')}`
                                  : item[colKey],
                                rowIdx,
                                colKey
                              )
                            }
                          >
                            Mostrar
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3 className="font-bold text-xl mb-4">Detalle</h3>
            <p className="mb-4 whitespace-pre-wrap">{modalContent}</p>
            <button
              onClick={closeModal}
              className="px-4 py-2 rounded bg-red-600 text-white hover:bg-red-700 transition font-semibold"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default NoduloTiroideoTable;