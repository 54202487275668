// FlashcardMode.js
import React, { useState, useEffect } from 'react';
import { toggleLearned, updateMnemonic, getErrorBankData } from './ErrorBank';

function FlashcardMode({ questions, onExit }) {
  // 1. Definimos los Hooks de estado
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showBack, setShowBack] = useState(false);

  // Estados para "Aprendido" y "Mnemotecnia" en la tarjeta actual
  const [isLearned, setIsLearned] = useState(false);
  const [currentMnemonic, setCurrentMnemonic] = useState('');
  const [mnemonicInput, setMnemonicInput] = useState('');

  // 2. Definimos la pregunta actual
  const question = questions[currentIndex] || null;

  // 3. useEffect para recargar data del errorBank cada vez que cambie de pregunta
  useEffect(() => {
    if (!question) return;

    const errorData = getErrorBankData(String(question.id)) || {};
    setIsLearned(errorData.learned || false);
    setCurrentMnemonic(errorData.mnemonic || '');
    setMnemonicInput('');
    setShowBack(false); // Asegurarnos de mostrar el frente al cambiar de tarjeta
  }, [currentIndex, question]);

  // 4. Si no hay preguntas, retornamos un mensaje (pero Hooks ya fueron llamados)
  if (!questions.length) {
    return (
      <div className="p-4">
        <p>No hay preguntas para mostrar en modo Flashcard.</p>
        <button
          onClick={onExit}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Salir
        </button>
      </div>
    );
  }

  // --- Funciones del componente ---
  const handleFlip = () => {
    setShowBack((prev) => !prev);
  };

  const handleNext = () => {
    // Si estamos en la última tarjeta, podrías cerrar o reiniciar
    if (currentIndex === questions.length - 1) {
      setCurrentIndex(0); // Reiniciar a la 1ra tarjeta (o podrías llamar onExit())
    } else {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const handleToggleLearned = () => {
    if (!question) return;
    toggleLearned(String(question.id));
    setIsLearned(!isLearned);
  };

  const handleSaveMnemonic = () => {
    if (!question) return;
    updateMnemonic(String(question.id), mnemonicInput);
    setCurrentMnemonic(mnemonicInput);
    setMnemonicInput('');
  };

  // 5. Render principal (Tarjeta de Flashcard)
  return (
    <div className="w-full max-w-xl mx-auto p-4 bg-white shadow rounded">
      <h2 className="text-xl font-bold text-center mb-4">Modo Flashcard</h2>

      {/* Tarjeta: Frente / Detrás */}
      <div className="border rounded p-4 text-center min-h-[200px] flex flex-col justify-center">
        {!showBack ? (
          <>
            {/* Información adicional del frente */}
            {question.year && (
              <p className="text-sm text-gray-600 mb-1">RM {question.year}</p>
            )}
            {question.topic && (
              <p className="text-sm text-gray-600">
                <strong>Tema:</strong> {question.topic}
              </p>
            )}
            {question.subtopic && (
              <p className="text-sm text-gray-600">
                <strong>Subtema:</strong> {question.subtopic}
              </p>
            )}
            {/* Subtema específico (opcional) */}
            {question.subtopic_specific && (
              <p className="text-sm text-gray-600">
                <strong>Subtema Específico:</strong>{' '}
                {question.subtopic_specific || 'Sin especificar'}
              </p>
            )}

            {/* Descripción o enunciado al frente */}
            {question.case_description && (
              <p className="mt-2 italic text-gray-800">{question.case_description}</p>
            )}
            <p className="mt-2 text-gray-800 font-medium">
              {question.question_text}
            </p>
            <button
              onClick={handleFlip}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Ver Respuesta
            </button>
          </>
        ) : (
          <>
            {/* Parte trasera: Explicación */}
            <p className="text-gray-700 mb-2">
              <strong>Explicación:</strong> {question.explanation}
            </p>
            {question.key_points && (
              <pre className="text-left p-2 bg-gray-100 rounded mb-2 text-sm">
                {question.key_points}
              </pre>
            )}
            <button
              onClick={handleFlip}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Ocultar Respuesta
            </button>
          </>
        )}
      </div>

      {/* Sección de Mnemotecnia */}
      <div className="mt-4">
        <label className="block mb-1 font-semibold text-gray-700">
          Mnemotecnia / Frase:
        </label>
        <textarea
          rows={3}
          className="w-full border border-gray-300 rounded p-2 text-sm"
          placeholder={currentMnemonic || 'Escribe tu frase...'}
          value={mnemonicInput}
          onChange={(e) => setMnemonicInput(e.target.value)}
        />
        <button
          onClick={handleSaveMnemonic}
          className="mt-1 px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 text-sm"
        >
          Guardar Mnemotecnia
        </button>
        {currentMnemonic && (
          <p className="text-sm text-gray-600 mt-2">
            <strong>Actual:</strong> {currentMnemonic}
          </p>
        )}
      </div>

      {/* Botones de control */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={onExit}
          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Salir
        </button>
        <button
          onClick={handleToggleLearned}
          className={`px-4 py-2 rounded text-white ${
            isLearned ? 'bg-red-500 hover:bg-red-600' : 'bg-green-600 hover:bg-green-700'
          }`}
        >
          {isLearned ? 'Marcar como NO aprendido' : 'Marcar como Aprendido'}
        </button>
        <button
          onClick={handleNext}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Siguiente
        </button>
      </div>

      <p className="text-center text-sm text-gray-500 mt-2">
        Tarjeta {currentIndex + 1} de {questions.length}
      </p>
    </div>
  );
}

export default FlashcardMode;