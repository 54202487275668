import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactFlow, { Background, Controls, MiniMap } from 'react-flow-renderer';
import Modal from 'react-modal';
import { FaExpand, FaCompress, FaMoon, FaSun } from 'react-icons/fa';

Modal.setAppElement('#root');

const nodes = [
  // 1. Factores de Riesgo
  {
    id: '1',
    data: {
      title: "Radiación / Factores de Riesgo",
      label: (
        <div>
          <strong>Radiación / Factores de Riesgo</strong>
          <br />(Exposición temprana, historia familiar)
        </div>
      ),
      description: "La exposición a radiación ionizante (especialmente en la niñez) y antecedentes familiares aumentan el riesgo."
    },
    position: { x: 300, y: 0 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f0f0f0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 2. Mutaciones Genéticas
  {
    id: '2',
    data: {
      title: "Mutaciones Genéticas",
      label: (
        <div>
          <strong>Mutaciones Genéticas</strong>
          <br />(BRAF, RET/PTC, RAS)
        </div>
      ),
      description: "Alteraciones genéticas que promueven la transformación maligna de células tiroideas."
    },
    position: { x: 300, y: 120 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 3. Carcinoma Papilar de Tiroides
  {
    id: '3',
    data: {
      title: "Carcinoma Papilar de Tiroides",
      label: <div><strong>Carcinoma Papilar de Tiroides</strong></div>,
      description: "El tipo más común de cáncer de tiroides. Suele ocurrir en adultos jóvenes (30-50 años) y tiene buen pronóstico."
    },
    position: { x: 300, y: 240 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f0f0f0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 4. Características Histopatológicas
  {
    id: '4',
    data: {
      title: "Características Histopatológicas",
      label: <div><strong>Histopatología</strong></div>,
      description: "Se observan núcleos en vidrio esmerilado, surcos nucleares y cuerpos de psammoma característicos."
    },
    position: { x: 50, y: 360 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 5. Evaluación Diagnóstica
  {
    id: '5',
    data: {
      title: "Evaluación Diagnóstica (Ultrasonido)",
      label: (
        <div>
          <strong>Evaluación Diagnóstica</strong>
          <br />(Ultrasonido)
        </div>
      ),
      description: "Búsqueda de nódulos sospechosos: nódulo sólido, hipoecogénico, con microcalcificaciones y bordes irregulares."
    },
    position: { x: 300, y: 360 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f0f0f0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 6. Confirmación con Citología
  {
    id: '6',
    data: {
      title: "Punción Aspiración (FNA)",
      label: (
        <div>
          <strong>Confirmación Citológica</strong>
          <br />(FNA)
        </div>
      ),
      description: "El estudio citológico de la muestra extraída confirma la presencia de células malignas."
    },
    position: { x: 550, y: 360 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 7. Marcadores Tumorales
  {
    id: '7',
    data: {
      title: "Marcadores Tumorales",
      label: <div><strong>Tiroglobulina (Tg)</strong></div>,
      description: "La Tg es útil en el seguimiento post-quirúrgico para detectar recurrencias o persistencia de tejido tiroideo."
    },
    position: { x: 50, y: 480 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f0f0f0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 8. Diagnóstico Confirmado
  {
    id: '8',
    data: {
      title: "[ Diagnóstico Confirmado ]",
      label: <div><strong>[ Diagnóstico Confirmado ]</strong></div>,
      description: "Integración de datos clínicos, ecográficos, citológicos y marcadores para confirmar Carcinoma Papilar."
    },
    position: { x: 300, y: 480 },
    style: {
      padding: '10px',
      border: '2px dashed #1a192b',
      borderRadius: '8px',
      background: '#e0e0e0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 9. Manejo y Tratamiento
  {
    id: '9',
    data: {
      title: "Manejo y Tratamiento",
      label: <div><strong>Manejo y Tratamiento</strong></div>,
      description: "Estrategias terapéuticas incluyendo cirugía, radioyodo y control a largo plazo."
    },
    position: { x: 300, y: 600 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f0f0f0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 10. Cirugía
  {
    id: '10',
    data: {
      title: "Intervención Quirúrgica",
      label: <div><strong>Intervención Quirúrgica</strong></div>,
      description: "Tiroidectomía total o parcial, con posible disección de ganglios cervicales."
    },
    position: { x: 50, y: 720 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 11. Radioyodo (I-131)
  {
    id: '11',
    data: {
      title: "Terapia con I-131 (Radioiodoterapia)",
      label: (
        <div>
          <strong>Terapia con I-131</strong>
          <br />(Radioiodoterapia)
        </div>
      ),
      description: "Se usa para ablar tejido tiroideo remanente y tratar posibles metástasis."
    },
    position: { x: 300, y: 720 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f0f0f0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 12. Seguimiento
  {
    id: '12',
    data: {
      title: "Seguimiento y Monitoreo",
      label: <div><strong>Seguimiento Post-Tx</strong></div>,
      description: "Control mediante tiroglobulina y gammagrafía con I-131 para descartar recurrencias."
    },
    position: { x: 550, y: 720 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 13. Detalle Cirugía
  {
    id: '13',
    data: {
      title: "Total o Parcial Tiroidectomía ± Disección Cervical",
      label: (
        <div>
          <strong>Tiroidectomía ± Disección</strong>
          <br />Ganglios Cervicales
        </div>
      ),
      description: "Dependiendo de la extensión, puede requerir extirpación completa y resección de ganglios cervicales."
    },
    position: { x: 50, y: 840 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 14. Ablación de Tejido
  {
    id: '14',
    data: {
      title: "Ablación de Tejido Tiroideo Residual",
      label: <div><strong>Ablación con I-131</strong></div>,
      description: "El I-131 destruye el tejido tiroideo residual, reduciendo riesgo de recurrencia."
    },
    position: { x: 300, y: 840 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 15. Gammagrafía con I-131
  {
    id: '15',
    data: {
      title: "Gammagrafía con I-131",
      label: (
        <div>
          <strong>Gammagrafía con I-131</strong>
          <br />Detección de metástasis
        </div>
      ),
      description: "Técnica de imagen nuclear para evaluar captación de tejido tiroideo en metástasis o recurrencia."
    },
    position: { x: 550, y: 840 },
    style: {
      padding: '10px',
      border: '2px solid #1a192b',
      borderRadius: '8px',
      background: '#f7f7f7',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  },

  // 16. Complicaciones
  {
    id: '16',
    data: {
      title: "Complicaciones del Tratamiento",
      label: (
        <div>
          <strong>Complicaciones</strong>
          <br /> (ej., fibrosis pulmonar, hipoparatiroidismo)
        </div>
      ),
      description: "Posibles efectos adversos: hipoparatiroidismo post-quirúrgico y fibrosis pulmonar tras altas dosis de I-131."
    },
    position: { x: 300, y: 960 },
    style: {
      padding: '10px',
      border: '2px dashed #1a192b',
      borderRadius: '8px',
      background: '#e0e0e0',
      boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
    }
  }
];

const edges = [
  // Relación Factores de Riesgo -> Mutaciones -> Carcinoma Papilar
  { id: 'e1-2', source: '1', target: '2', animated: true },
  { id: 'e2-3', source: '2', target: '3', animated: true },

  // Carcinoma Papilar -> Histopatología y Evaluación Diagnóstica
  { id: 'e3-4', source: '3', target: '4', animated: true },
  { id: 'e3-5', source: '3', target: '5', animated: true },
  { id: 'e3-6', source: '3', target: '6', animated: true },
  { id: 'e3-7', source: '3', target: '7', animated: true },

  // Flujo hacia Diagnóstico Confirmado
  { id: 'e4-8', source: '4', target: '8', animated: true },
  { id: 'e5-8', source: '5', target: '8', animated: true },
  { id: 'e6-8', source: '6', target: '8', animated: true },
  { id: 'e7-8', source: '7', target: '8', animated: true },

  // Diagnóstico Confirmado -> Manejo y Tratamiento
  { id: 'e8-9', source: '8', target: '9', animated: true },

  // Tratamiento -> Cirugía, Radioyodo y Seguimiento
  { id: 'e9-10', source: '9', target: '10', animated: true },
  { id: 'e9-11', source: '9', target: '11', animated: true },
  { id: 'e9-12', source: '9', target: '12', animated: true },

  // Detalle quirúrgico
  { id: 'e10-13', source: '10', target: '13', animated: true },

  // Detalle Radioyodo
  { id: 'e11-14', source: '11', target: '14', animated: true },

  // Detalle seguimiento
  { id: 'e12-15', source: '12', target: '15', animated: true },

  // Complicaciones
  { id: 'e11-16', source: '11', target: '16', animated: true }
];

const ConceptMapInteractive = () => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () =>
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  const onNodeClick = useCallback((event, node) => {
    setSelectedNode(node);
  }, []);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current.requestFullscreen().catch((err) => {
        console.error(`Error al activar pantalla completa: ${err.message}`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  const toggleTheme = () => {
    setIsDarkTheme((prev) => !prev);
  };

  const closeModal = () => {
    setSelectedNode(null);
  };

  const containerStyle = {
    height: '100vh',
    width: '100%',
    position: 'relative',
    background: isDarkTheme
      ? 'linear-gradient(135deg, #2c3e50, #34495e)'
      : '#f7f7f7',
    transition: 'background 0.5s ease',
    overflow: 'hidden'
  };

  const fullscreenHeaderStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: '15px 30px',
    background: isDarkTheme ? 'rgba(0, 0, 0, 0.85)' : 'rgba(255, 255, 255, 0.85)',
    color: isDarkTheme ? '#fff' : '#333',
    zIndex: 1500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0 2px 10px rgba(0,0,0,0.5)',
    backdropFilter: 'blur(5px)'
  };

  const headerTitleStyle = {
    fontSize: '1.5em',
    fontWeight: 'bold'
  };

  const buttonStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1em',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  };

  const toggleButtonStyle = {
    ...buttonStyle,
    backgroundColor: isFullscreen ? '#e74c3c' : '#3498db',
    color: '#fff'
  };

  const themeButtonStyle = {
    ...buttonStyle,
    backgroundColor: isDarkTheme ? '#f1c40f' : '#34495e',
    color: '#fff'
  };

  const fullScreenButtonStyle = {
    ...toggleButtonStyle,
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: 1500
  };

  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      backdropFilter: 'blur(5px)',
      zIndex: 1600,
      transition: 'opacity 0.3s ease'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      padding: '25px',
      borderRadius: '10px',
      width: '320px',
      backgroundColor: isDarkTheme ? '#2c3e50' : '#fff',
      color: isDarkTheme ? '#ecf0f1' : '#333',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',
      transition: 'opacity 0.3s ease'
    }
  };

  return (
    <div ref={containerRef} style={containerStyle}>
      {isFullscreen && (
        <div style={fullscreenHeaderStyle}>
          <div style={headerTitleStyle}>Mapa Conceptual Interactivo</div>
          <div style={{ display: 'flex', gap: '15px' }}>
            <button onClick={toggleTheme} style={themeButtonStyle}>
              {isDarkTheme ? <FaSun /> : <FaMoon />}
              {isDarkTheme ? 'Claro' : 'Oscuro'}
            </button>
            <button onClick={toggleFullScreen} style={toggleButtonStyle}>
              <FaCompress />
              Salir
            </button>
          </div>
        </div>
      )}

      {/* Botones en modo normal (abajo a la derecha) */}
      {!isFullscreen && (
        <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1500, display: 'flex', gap: '10px' }}>
          <button onClick={toggleTheme} style={themeButtonStyle}>
            {isDarkTheme ? <FaSun /> : <FaMoon />}
            {isDarkTheme ? 'Claro' : 'Oscuro'}
          </button>
          <button onClick={toggleFullScreen} style={fullScreenButtonStyle}>
            <FaExpand />
            Pantalla Completa
          </button>
        </div>
      )}

      <ReactFlow nodes={nodes} edges={edges} onNodeClick={onNodeClick} fitView>
        <Background color={isDarkTheme ? "#555" : "#aaa"} gap={16} />
        <Controls />
        <MiniMap
          nodeStrokeColor={(n) =>
            n.style?.background ? n.style.background : '#000'
          }
          nodeColor={(n) => n.style?.background || '#fff'}
        />
      </ReactFlow>

      <Modal
        parentSelector={() =>
          isFullscreen && containerRef.current
            ? containerRef.current
            : document.body
        }
        isOpen={!!selectedNode}
        onRequestClose={closeModal}
        contentLabel="Detalles del nodo"
        style={modalStyles}
      >
        {selectedNode && (
          <>
            <h2 style={{ marginTop: 0 }}>{selectedNode.data.title}</h2>
            <div style={{ marginBottom: '10px' }}>
              {selectedNode.data.label}
            </div>
            <p>{selectedNode.data.description}</p>
            <button
              onClick={closeModal}
              style={{
                marginTop: '10px',
                padding: '10px 15px',
                backgroundColor: '#3498db',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
            >
              Cerrar
            </button>
          </>
        )}
      </Modal>
    </div>
  );
};

export default ConceptMapInteractive;