// App.js
import React, { useEffect, useState } from 'react';
import netlifyIdentity from 'netlify-identity-widget';

// Importa tus componentes existentes
import GenerateExam from './GenerateExam';
import Quiz from './components/Quiz';
import DecisionTable from './components/DecisionTable';
import HeartFailureTable from './components/HeartFailureTable';
import HeartFailureTable1 from './components/HeartFailureTable1';
import RuidosCardiacosTable from './components/Tablaruidoscardiacos';
import EsquemaRuidosCardiacos from './components/Esquemaruidoscardiacos';
import Tabladeruidoscardiacos2 from './components/Tablaruidoscardiacos2';
import ErrorReview from './ErrorReview';
import ExamHistory from './ExamHistory';

/**
 * Mapeos "subjectId -> area" y "topicId -> topic" según tu configuración actual.
 */
const areaMap = {
  cardiologia: 'Cardiología',
  neurologia: 'Neurología'
};

const topicMap = {
  valvulopatias: 'Valvulopatías',
  miocardiopatias: 'Miocardiopatías',
  isquemiaMiocardica: 'Isquemia Miocárdica',
  fisiologiaCardiaca: 'Fisiología Cardíaca',
  ruidosCardiacos: 'Ruidos Cardíacos',
  conduccionAV: 'Conducción AV',
  cardiopatiasCongenitas: 'Cardiopatías Congénitas',
  arritmias: 'Arritmias',
  arritmiasSupraventriculares: 'Arritmias Supraventriculares',
  insuficienciaCardiaca: 'Insuficiencia Cardíaca',
  fisiologiaVascular: 'Fisiología Vascular',
  shock: 'Shock',
  electrofisiologiaCardiaca: 'Electrofisiología Cardíaca',
  emergenciasHipertensivas: 'Emergencias Hipertensivas'
};

// Iconos para cada tema (para mejorar la visualización)
const topicIcons = {
  valvulopatias: '💓',
  miocardiopatias: '🫀',
  isquemiaMiocardica: '⚡',
  fisiologiaCardiaca: '📊',
  ruidosCardiacos: '🔊',
  conduccionAV: '⚡',
  cardiopatiasCongenitas: '👶',
  arritmias: '〰️',
  arritmiasSupraventriculares: '📈',
  insuficienciaCardiaca: '❤️‍🩹',
  fisiologiaVascular: '🩸',
  shock: '⚠️',
  electrofisiologiaCardiaca: '📝',
  emergenciasHipertensivas: '🚑'
};

// Iconos generales para la UI (reemplazando react-feather)
const icons = {
  heart: '❤️',
  brain: '🧠',
  bookOpen: '📖',
  clock: '⏱️',
  award: '🏆',
  history: '📜',
  logout: '🚪',
  back: '⬅️',
  menu: '☰',
  user: '👤',
  refresh: '🔄',
  warning: '⚠️',
  bookMarked: '📚',
  list: '📋',
  play: '▶️',
  pause: '⏸️',
  externalLink: '🔗',
  sun: '☀️',
  moon: '🌙',
  check: '✓',
  database: '🗄️',
  error: '❌',
};

// Menú de áreas y sus respectivos temas
const subjects = [
  {
    id: 'cardiologia',
    name: 'Cardiología',
    icon: icons.heart,
    description: "Especialidad médica que se ocupa del estudio, diagnóstico y tratamiento de las enfermedades del corazón y del sistema circulatorio.",
    topics: [
      { id: 'valvulopatias', name: 'Valvulopatías', icon: topicIcons.valvulopatias },
      { id: 'miocardiopatias', name: 'Miocardiopatías', icon: topicIcons.miocardiopatias },
      { id: 'isquemiaMiocardica', name: 'Isquemia Miocárdica', icon: topicIcons.isquemiaMiocardica },
      { id: 'fisiologiaCardiaca', name: 'Fisiología Cardíaca', icon: topicIcons.fisiologiaCardiaca },
      { id: 'ruidosCardiacos', name: 'Ruidos Cardíacos', icon: topicIcons.ruidosCardiacos },
      { id: 'conduccionAV', name: 'Conducción AV', icon: topicIcons.conduccionAV },
      { id: 'cardiopatiasCongenitas', name: 'Cardiopatías Congénitas', icon: topicIcons.cardiopatiasCongenitas },
      { id: 'arritmias', name: 'Arritmias', icon: topicIcons.arritmias },
      { id: 'arritmiasSupraventriculares', name: 'Arritmias Supraventriculares', icon: topicIcons.arritmiasSupraventriculares },
      { id: 'insuficienciaCardiaca', name: 'Insuficiencia Cardíaca', icon: topicIcons.insuficienciaCardiaca },
      { id: 'fisiologiaVascular', name: 'Fisiología Vascular', icon: topicIcons.fisiologiaVascular },
      { id: 'shock', name: 'Shock', icon: topicIcons.shock },
      { id: 'electrofisiologiaCardiaca', name: 'Electrofisiología Cardíaca', icon: topicIcons.electrofisiologiaCardiaca },
      {
        id: 'emergenciasHipertensivas',
        name: 'Emergencias Hipertensivas',
        icon: topicIcons.emergenciasHipertensivas,
        DecisionTableComponents: [DecisionTable]
      }
    ]
  },
  {
    id: 'neurologia',
    name: 'Neurología',
    icon: icons.brain,
    description: "Especialidad médica que estudia la estructura, función y desarrollo del sistema nervioso y muscular.",
    topics: [
      // Ejemplo: sin subtemas en la demo
    ]
  }
];

// CSS personalizado (inyectado al montar la aplicación)
const customStyles = `
  /* Animaciones y transiciones */
  .fade-in {
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .scale-in {
    animation: scaleIn 0.2s ease-out;
  }
  
  .slide-in {
    animation: slideIn 0.3s ease-out;
  }
  
  .slide-in-right {
    animation: slideInRight 0.3s ease-out;
  }
  
  .pulse {
    animation: pulse 2s infinite;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes scaleIn {
    from { transform: scale(0.95); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  
  @keyframes slideIn {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  @keyframes slideInRight {
    from { transform: translateX(20px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
  
  /* Transiciones */
  .btn {
    transition: all 0.2s ease;
  }
  
  .btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .btn:active {
    transform: translateY(0);
  }
  
  .card-hover {
    transition: all 0.3s ease;
  }
  
  .card-hover:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  }
  
  /* Dark mode */
  body.dark-mode {
    background-color: #1a1a2e;
    color: #f1f1f1;
  }
  
  /* Mobile Menu */
  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .mobile-menu.open {
    transform: translateX(0);
  }
  
  /* Transiciones para paneles colapsables */
  .collapsible {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .collapsible.show {
    max-height: 2000px; /* valor alto para asegurar que muestre todo el contenido */
  }
  
  /* Spinner de carga */
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Paginación */
  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 8px 12px;
    margin: 0 4px;
    border-radius: 4px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pagination-button:hover:not(:disabled) {
    background-color: #e0e0e0;
  }
  
  .pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .pagination-button.active {
    background-color: #3498db;
    color: white;
  }
`;

function App() {
  // 1) Manejo de usuario con Netlify Identity (opcional)
  const [user, setUser] = useState(
    process.env.NODE_ENV === 'development'
      ? { user_metadata: { full_name: 'Usuario Local' }, email: 'local@example.com' }
      : null
  );

  // 2) Cargar TODAS las preguntas desde el backend
  const [allQuestions, setAllQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [loadError, setLoadError] = useState(null);
  
  // 3) Estados para paginación y optimización de carga
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [hasMoreQuestions, setHasMoreQuestions] = useState(true);

  // 4) Estados para subject y topic
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);

  // 5) Estado para la pantalla "Generar Examen"
  const [showGenerator, setShowGenerator] = useState(false);
  const [examQuestionsFromGenerator, setExamQuestionsFromGenerator] = useState([]);
  const [examTimeFromGenerator, setExamTimeFromGenerator] = useState(60);
  const [examModeFromGenerator, setExamModeFromGenerator] = useState("study");
  const [showQuizFromGenerator, setShowQuizFromGenerator] = useState(false);
  const [examTitleFromGenerator, setExamTitleFromGenerator] = useState("");

  // 6) Modo simulación en el flujo actual (subject/topic)
  const [simulationMode, setSimulationMode] = useState(false);
  const [simulationTime, setSimulationTime] = useState(3);

  // 7) Estados para mostrar/ocultar tablas extra
  const [showHeartFailureTable, setShowHeartFailureTable] = useState(false);
  const [showHeartFailureTable1, setShowHeartFailureTable1] = useState(false);
  const [showRuidosCardiacosTable, setShowRuidosCardiacosTable] = useState(false);
  const [showEsquemaRuidosCardiacos, setShowEsquemaRuidosCardiacos] = useState(false);
  const [showRuidosCardiacosTable2, setShowRuidosCardiacosTable2] = useState(false);

  // 8) Estado para la Revisión de Errores
  const [showErrorReview, setShowErrorReview] = useState(false);

  // 9) Estado para mostrar el Historial de exámenes
  const [showExamHistory, setShowExamHistory] = useState(false);

  // 10) Nuevo: Tema oscuro/claro
  const [darkMode, setDarkMode] = useState(false);

  // 11) Nuevo: Menú móvil
  const [menuOpen, setMenuOpen] = useState(false);

  // 12) Nuevo: Efectos de animación para cards
  const [animateCards, setAnimateCards] = useState(false);

  // --- useEffects ---

  // Inyectar CSS personalizado
  useEffect(() => {
    const styleEl = document.createElement('style');
    styleEl.innerHTML = customStyles;
    document.head.appendChild(styleEl);
    
    // Limpieza
    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);

  // Netlify Identity init (si aplica)
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      netlifyIdentity.init();
      netlifyIdentity.on('login', (loggedUser) => {
        setUser(loggedUser);
        netlifyIdentity.close();
      });
      netlifyIdentity.on('logout', () => setUser(null));
    }
  }, []);

  // Cargar preguntas desde API (optimizado para compatibilidad con la futura paginación)
  useEffect(() => {
    // Determinar la URL base según el entorno (desarrollo o producción)
    const baseUrl = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3001/api/questions'
  : '/.netlify/functions/api/questions/all';
    
    console.log('Cargando preguntas desde:', baseUrl);
    setLoadingQuestions(true);
    setLoadError(null);
    
    fetch(baseUrl)
      .then((res) => {
        if (!res.ok) {
          // Si la respuesta no es exitosa (status code 200-299)
          return res.json().then(errData => {
            throw new Error(`Error ${res.status}: ${errData.error || 'Error desconocido'}`);
          });
        }
        return res.json();
      })
      .then((data) => {
        // Para versión actual (sin paginación)
        if (Array.isArray(data)) {
          console.log(`Preguntas recibidas: ${data.length}`);
          setAllQuestions(data);
          setTotalQuestions(data.length);
          setHasMoreQuestions(false);
        } 
        // Para futura versión (con paginación)
        else if (data && data.data && Array.isArray(data.data)) {
          console.log(`Preguntas recibidas: ${data.data.length} de ${data.pagination?.total || 'desconocido'}`);
          setAllQuestions(data.data);
          setTotalQuestions(data.pagination?.total || data.data.length);
          setHasMoreQuestions(data.pagination?.hasNextPage || false);
        }
        
        setLoadingQuestions(false);
        
        // Activar animación de entrada para cards
        setTimeout(() => {
          setAnimateCards(true);
        }, 100);
      })
      .catch((err) => {
        console.error('Error cargando preguntas:', err);
        setLoadError(err.message);
        setLoadingQuestions(false);
      });
  }, []);

  // Efecto para modo oscuro
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);
  // NUEVO CÓDIGO: Persistencia de estado
// Nuevo efecto para guardar el estado de navegación
useEffect(() => {
  // Omitimos las referencias circulares para evitar problemas al serializar
  const stateToSave = {
    selectedSubject: selectedSubject ? { id: selectedSubject.id, name: selectedSubject.name } : null,
    selectedTopic: selectedTopic ? { id: selectedTopic.id, name: selectedTopic.name } : null,
    showGenerator,
    showQuizFromGenerator,
    showErrorReview,
    showExamHistory,
    examTitleFromGenerator
  };
  localStorage.setItem('appState', JSON.stringify(stateToSave));
}, [
  selectedSubject,
  selectedTopic,
  showGenerator,
  showQuizFromGenerator,
  showErrorReview,
  showExamHistory,
  examTitleFromGenerator
]);

// Efecto para cargar el estado al montar
useEffect(() => {
  try {
    const savedState = localStorage.getItem('appState');
    if (savedState && loadingQuestions === false && !loadError) {
      const state = JSON.parse(savedState);
      
      // Restaurar subject y topic
      if (state.selectedSubject) {
        const fullSubject = subjects.find(s => s.id === state.selectedSubject.id);
        if (fullSubject) {
          setSelectedSubject(fullSubject);
          
          // Si había un topic seleccionado, restaurarlo también
          if (state.selectedTopic) {
            const fullTopic = fullSubject.topics.find(t => t.id === state.selectedTopic.id);
            if (fullTopic) setSelectedTopic(fullTopic);
          }
        }
      }
      
      // Restaurar otras vistas
      if (state.showGenerator) setShowGenerator(state.showGenerator);
      if (state.showQuizFromGenerator) setShowQuizFromGenerator(state.showQuizFromGenerator);
      if (state.showErrorReview) setShowErrorReview(state.showErrorReview);
      if (state.showExamHistory) setShowExamHistory(state.showExamHistory);
      if (state.examTitleFromGenerator) setExamTitleFromGenerator(state.examTitleFromGenerator);
    }
  } catch (error) {
    console.error("Error al restaurar el estado:", error);
    localStorage.removeItem('appState');
  }
}, [subjects, loadingQuestions, loadError]);

  // 11) Nuevo: Breadcrumb (navegación)
  const getBreadcrumbs = () => {
    const crumbs = [];
    crumbs.push({ name: 'Inicio', action: () => {
      setSelectedSubject(null);
      setSelectedTopic(null);
      setShowGenerator(false);
      setShowQuizFromGenerator(false);
      setShowErrorReview(false);
      setShowExamHistory(false);
    }});
    
    if (showGenerator) {
      crumbs.push({ name: 'Generar Examen', action: null });
    } else if (showQuizFromGenerator) {
      crumbs.push({ 
        name: 'Generar Examen', 
        action: () => {
          setShowQuizFromGenerator(false);
          setShowGenerator(true);
        } 
      });
      crumbs.push({ name: examTitleFromGenerator, action: null });
    } else if (showErrorReview) {
      crumbs.push({ name: 'Revisión de Errores', action: null });
    } else if (showExamHistory) {
      crumbs.push({ name: 'Historial de Exámenes', action: null });
    } else if (selectedSubject) {
      crumbs.push({ 
        name: selectedSubject.name, 
        action: () => {
          setSelectedTopic(null);
        } 
      });
      
      if (selectedTopic) {
        crumbs.push({ name: selectedTopic.name, action: null });
      }
    }
    
    return crumbs;
  };

  // --- Funciones ---

  // 1) Login manual
  const handleLogin = () => {
    if (process.env.NODE_ENV !== 'development') {
      netlifyIdentity.open();
    }
  };

  // 2) Filtrar preguntas según subject y topic (flujo tradicional)
  function getFilteredQuestions(subjectId, topicId) {
    const area = areaMap[subjectId] || '';
    const topicName = topicMap[topicId] || '';
    return allQuestions.filter((q) => q.area === area && q.topic === topicName);
  }

  // 3) Callback para <GenerateExam />
  const handleStartExamFromGenerator = (filteredQuestions, tiempo, examMode, examTitle) => {
    setExamQuestionsFromGenerator(filteredQuestions);
    setExamTimeFromGenerator(tiempo);
    setExamModeFromGenerator(examMode);
    setExamTitleFromGenerator(examTitle || "Examen Personalizado");
    setShowGenerator(false);
    setShowQuizFromGenerator(true);
  };

  // 4) Botón "Volver al Menú de Temas" (para la parte de subject/topic)
  const handleBackToTopics = () => {
    // Resetea las tablas extra
    setShowHeartFailureTable(false);
    setShowHeartFailureTable1(false);
    setShowRuidosCardiacosTable(false);
    setShowEsquemaRuidosCardiacos(false);
    setShowRuidosCardiacosTable2(false);

    // Regresamos al selector de topics
    setSelectedTopic(null);
    setSimulationMode(false);
  };

  // 5) Toggle del panel colapsable (para tablas complementarias)
  const togglePanel = (stateSetter, currentState) => {
    stateSetter(!currentState);
  };

  // --- Componentes Auxiliares (modificados para recibir darkMode) ---

  // Header Component
  const Header = ({ darkMode }) => (
    <header className={`py-4 px-6 ${darkMode ? 'bg-gray-900 text-white' : 'bg-white'} shadow-md fixed top-0 left-0 right-0 z-50`}>
      <div className="max-w-6xl mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <button 
            onClick={() => setMenuOpen(!menuOpen)}
            className="md:hidden p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
          >
            {icons.menu}
          </button>
          <span className="font-bold text-xl flex items-center">
            <span className="text-red-500 mr-2">{icons.heart}</span> 
            MediExam Pro
          </span>
          {/* Añadir indicador de base de datos */}
          <span className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded ml-2">
            Turso DB {totalQuestions > 0 ? `(${totalQuestions})` : ''}
          </span>
        </div>
        
        <div className="hidden md:flex items-center space-x-4">
          {user && (
            <>
              <div className="flex items-center">
                <span className="mr-2 text-gray-600 dark:text-gray-300">{icons.user}</span>
                <span className="text-sm">
                  {process.env.NODE_ENV === 'development'
                    ? 'Alexis'
                    : user?.user_metadata?.full_name || ''}
                </span>
              </div>
              
              {process.env.NODE_ENV !== 'development' && (
                <button
                  onClick={() => netlifyIdentity.logout()}
                  className="btn flex items-center text-sm px-3 py-1 rounded-full bg-red-100 text-red-600 hover:bg-red-200 transition-colors"
                >
                  <span className="mr-1">{icons.logout}</span> Salir
                </button>
              )}
            </>
          )}
          
          <button
            onClick={() => setDarkMode(!darkMode)}
            className={`btn p-2 rounded-full ${darkMode ? 'bg-yellow-400 text-gray-900' : 'bg-gray-800 text-white'}`}
            aria-label="Cambiar tema"
          >
            {darkMode ? icons.sun : icons.moon}
          </button>
        </div>
      </div>
    </header>
  );

  // Breadcrumb Navigation
  const BreadcrumbNav = ({ darkMode }) => {
    const crumbs = getBreadcrumbs();
    
    return (
      <nav className="text-sm mb-6 fade-in">
        <ol className="flex flex-wrap items-center space-x-2">
          {crumbs.map((crumb, index) => (
            <React.Fragment key={index}>
              {index > 0 && <span className="text-gray-400">/</span>}
              <li>
                {crumb.action ? (
                  <button 
                    onClick={crumb.action}
                    className="text-blue-500 hover:text-blue-700 hover:underline"
                  >
                    {crumb.name}
                  </button>
                ) : (
                  <span className={`${darkMode ? 'text-gray-300' : 'text-gray-600'} font-medium`}>
                    {crumb.name}
                  </span>
                )}
              </li>
            </React.Fragment>
          ))}
        </ol>
      </nav>
    );
  };

  // Mobile Menu
  const MobileMenu = ({ darkMode }) => (
    <div className={`mobile-menu ${menuOpen ? 'open' : ''} ${darkMode ? 'bg-gray-900' : 'bg-white'} p-6`}>
      <div className="flex justify-between items-center mb-8">
        <span className="font-bold text-xl">MediExam Pro</span>
        <button 
          onClick={() => setMenuOpen(false)}
          className="p-2"
        >
          ✕
        </button>
      </div>
      
      {user && (
        <div className={`flex items-center mb-6 p-4 ${darkMode ? 'bg-gray-800' : 'bg-gray-100'} rounded-lg`}>
          <div className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center text-white font-bold mr-3">
            {(process.env.NODE_ENV === 'development'
              ? 'A'
              : user?.user_metadata?.full_name?.[0] || 'U')}
          </div>
          <div>
            <p className="font-medium">
              {process.env.NODE_ENV === 'development'
                ? 'Alexis'
                : user?.user_metadata?.full_name || ''}
            </p>
            <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{user?.email}</p>
          </div>
        </div>
      )}
      
      <div className="space-y-4">
        <button 
          className={`btn w-full text-left flex items-center p-4 rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
          onClick={() => {
            setMenuOpen(false);
            setSelectedSubject(null);
            setSelectedTopic(null);
            setShowGenerator(false);
            setShowErrorReview(false);
            setShowExamHistory(false);
          }}
        >
          <span className="mr-3">{icons.bookOpen}</span> Menú Principal
        </button>
        
        <button 
          className={`btn w-full text-left flex items-center p-4 rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
          onClick={() => {
            setMenuOpen(false);
            setShowGenerator(true);
          }}
        >
          <span className="mr-3">{icons.bookMarked}</span> Generar Examen
        </button>
        
        <button 
          className={`btn w-full text-left flex items-center p-4 rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
          onClick={() => {
            setMenuOpen(false);
            setShowErrorReview(true);
          }}
        >
          <span className="mr-3">{icons.warning}</span> Revisión de Errores
        </button>
        
        <button 
          className={`btn w-full text-left flex items-center p-4 rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
          onClick={() => {
            setMenuOpen(false);
            setShowExamHistory(true);
          }}
        >
          <span className="mr-3">{icons.history}</span> Historial de Exámenes
        </button>
        
        <button 
          onClick={() => setDarkMode(!darkMode)}
          className={`btn w-full text-left flex items-center p-4 rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
        >
          {darkMode ? icons.sun : icons.moon}
          <span className="ml-3">{darkMode ? 'Modo Claro' : 'Modo Oscuro'}</span>
        </button>
        
        {process.env.NODE_ENV !== 'development' && (
          <button 
            className="btn w-full text-left flex items-center p-4 rounded-lg bg-red-100 text-red-700"
            onClick={() => netlifyIdentity.logout()}
          >
            <span className="mr-3">{icons.logout}</span> Cerrar Sesión
          </button>
        )}
      </div>
    </div>
  );

  // Footer Component
  const Footer = ({ darkMode }) => (
    <footer className={`mt-16 py-8 ${darkMode ? 'bg-gray-900 text-gray-300' : 'bg-gray-100 text-gray-600'}`}>
      <div className="max-w-6xl mx-auto px-6 text-center">
        <p className="text-sm">© {new Date().getFullYear()} MediExam Pro - Herramienta de estudio para profesionales médicos</p>
      </div>
    </footer>
  );

  // SubjectCard Component
  const SubjectCard = ({ subject, onSelect, darkMode }) => (
    <div
      onClick={() => onSelect(subject)}
      className={`card-hover p-6 rounded-xl cursor-pointer transition-all ${
        darkMode 
          ? 'bg-gray-800 hover:bg-gray-700 border border-gray-700' 
          : 'bg-white hover:bg-blue-50 shadow-lg hover:shadow-xl border border-gray-100'
      } ${animateCards ? 'fade-in' : ''}`}
    >
      <div className="flex items-start">
        <div className={`p-3 rounded-full mr-4 ${darkMode ? 'bg-blue-900' : 'bg-blue-100'}`}>
          <span className="text-2xl">{subject.icon}</span>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2">{subject.name}</h3>
          <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            {subject.description}
          </p>
          <p className="mt-3 text-sm">
            <span className={`font-medium ${darkMode ? 'text-blue-400' : 'text-blue-600'}`}>
              {subject.topics.length}
            </span> temas disponibles
          </p>
        </div>
      </div>
    </div>
  );

  // TopicCard Component
  const TopicCard = ({ topic, onSelect, darkMode }) => (
    <div
      onClick={() => onSelect(topic)}
      className={`card-hover p-5 rounded-lg cursor-pointer transition-all flex items-center ${
        darkMode 
          ? 'bg-gray-800 hover:bg-gray-700 border border-gray-700' 
          : 'bg-white hover:bg-blue-50 shadow hover:shadow-md border border-gray-100'
      } ${animateCards ? 'slide-in' : ''}`}
    >
      <span className="text-2xl mr-3">{topic.icon}</span>
      <h3 className="text-lg font-medium">{topic.name}</h3>
    </div>
  );

  // ActionButton Component
  const ActionButton = ({ icon, text, onClick, color = "blue", className = "", darkMode }) => (
    <button
      onClick={onClick}
      className={`btn flex items-center justify-center px-4 py-3 rounded-lg text-white font-medium transition-colors ${className} ${
        color === "blue" ? "bg-blue-600 hover:bg-blue-700" :
        color === "green" ? "bg-green-600 hover:bg-green-700" :
        color === "red" ? "bg-red-600 hover:bg-red-700" :
        color === "purple" ? "bg-purple-600 hover:bg-purple-700" :
        color === "orange" ? "bg-orange-600 hover:bg-orange-700" :
        color === "gray" ? "bg-gray-600 hover:bg-gray-700" : ""
      }`}
    >
      <span className="mr-2">{icon}</span>
      <span>{text}</span>
    </button>
  );

  // PageLayout component
  const PageLayout = ({ children, darkMode }) => (
    <div className={`min-h-screen flex flex-col ${darkMode ? 'bg-gray-800 text-white' : 'bg-gray-50 text-gray-900'}`}>
      <Header darkMode={darkMode} />
      <MobileMenu darkMode={darkMode} />
      
      <main className="flex-grow pt-24 pb-8 px-4">
        <div className="max-w-6xl mx-auto">
          <BreadcrumbNav darkMode={darkMode} />
          <div className="fade-in">
            {children}
          </div>
        </div>
      </main>
      
      <Footer darkMode={darkMode} />
    </div>
  );

  // --- Render condicional principal ---

  // (a) Si no hay usuario (producción) -> pedir login
  if (!user && process.env.NODE_ENV !== 'development') {
    return (
      <div className={`min-h-screen flex flex-col items-center justify-center ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100'}`}>
        <div className={`p-10 rounded-xl shadow-2xl text-center max-w-md w-full scale-in ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <div className="mb-8">
            <div className="text-5xl mx-auto text-red-500 mb-4">{icons.heart}</div>
            <h1 className="text-3xl font-bold mb-2">MediExam Pro</h1>
            <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>Plataforma de estudio para profesionales médicos</p>
          </div>
          
          <h2 className="text-xl font-semibold mb-6">Inicia sesión para continuar</h2>
          
          <button
            onClick={handleLogin}
            className="btn w-full py-3 px-6 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition flex items-center justify-center"
          >
            <span className="mr-2">{icons.user}</span> Ingresar
          </button>
          
          <p className={`mt-6 text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            Sistema de evaluación y estudio para medicina.
          </p>
        </div>
        
        {/* Tema oscuro/claro */}
        <button
          onClick={() => setDarkMode(!darkMode)}
          className={`btn mt-6 p-3 rounded-full ${darkMode ? 'bg-yellow-400 text-gray-900' : 'bg-gray-800 text-white'}`}
          aria-label="Cambiar tema"
        >
          {darkMode ? icons.sun : icons.moon}
        </button>
      </div>
    );
  }

  // (b) Cargando preguntas
  if (loadingQuestions) {
    return (
      <div className={`min-h-screen flex items-center justify-center ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100'}`}>
        <div className="text-center">
          <div className="mx-auto mb-4 pulse">
            <span className="text-5xl text-blue-500">{icons.refresh}</span>
          </div>
          <p className="text-xl font-medium">Cargando preguntas desde Turso...</p>
          <p className={`${darkMode ? 'text-gray-400' : 'text-gray-500'} mt-2`}>Preparando tu experiencia de estudio</p>
        </div>
      </div>
    );
  }

  // (b.2) Error de carga
  if (loadError) {
    return (
      <div className={`min-h-screen flex items-center justify-center ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100'}`}>
        <div className={`text-center max-w-md p-8 ${darkMode ? 'bg-red-900' : 'bg-red-50'} rounded-xl`}>
          <div className="mx-auto mb-4">
            <span className="text-5xl text-red-500">{icons.error}</span>
          </div>
          <p className={`text-xl font-medium ${darkMode ? 'text-red-300' : 'text-red-700'}`}>Error al cargar preguntas</p>
          <p className={`${darkMode ? 'text-gray-200' : 'text-gray-700'} mt-2 mb-4`}>{loadError}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  }

  // (A) Vista Historial de exámenes
  if (showExamHistory) {
    return (
      <PageLayout darkMode={darkMode}>
        <div className={`p-8 rounded-xl ${darkMode ? 'bg-gray-800' : 'bg-white shadow-lg'}`}>
          <h2 className="text-2xl font-bold mb-6 flex items-center">
            <span className="mr-3 text-blue-500">{icons.history}</span> Historial de Exámenes
          </h2>
          <ExamHistory onBack={() => setShowExamHistory(false)} darkMode={darkMode} />
        </div>
      </PageLayout>
    );
  }

  // Si se activa la vista de Revisión de Errores, mostramos <ErrorReview />
  if (showErrorReview) {
    return (
      <PageLayout darkMode={darkMode}>
        <div className={`p-8 rounded-xl ${darkMode ? 'bg-gray-800' : 'bg-white shadow-lg'}`}>
          <h2 className="text-2xl font-bold mb-6 flex items-center">
            <span className="mr-3 text-orange-500">{icons.warning}</span> Revisión de Errores
          </h2>
          <ErrorReview
            allQuestions={allQuestions}
            onBack={() => setShowErrorReview(false)}
            darkMode={darkMode}
          />
        </div>
      </PageLayout>
    );
  }

  // (c) Si showQuizFromGenerator está activo -> <Quiz> con preguntas generadas
  if (showQuizFromGenerator) {
    return (
      <PageLayout darkMode={darkMode}>
        <div className={`p-8 rounded-xl ${darkMode ? 'bg-gray-800' : 'bg-white shadow-lg'}`}>
          <Quiz
            title={examTitleFromGenerator || "Examen Personalizado"}
            questions={examQuestionsFromGenerator}
            simulationMode={examModeFromGenerator === "simulation"}
            simulationTime={examModeFromGenerator === "simulation" ? examTimeFromGenerator : null}
            onBack={() => {
              setShowQuizFromGenerator(false);
              setShowGenerator(true);
            }}
            fromGenerator={true}
            darkMode={darkMode}
          />
        </div>
      </PageLayout>
    );
  }

  // (d) Si showGenerator está activo -> mostrar <GenerateExam />
  if (showGenerator) {
    return (
      <PageLayout darkMode={darkMode}>
        <div className={`p-8 rounded-xl ${darkMode ? 'bg-gray-800' : 'bg-white shadow-lg'}`}>
          <h2 className="text-2xl font-bold mb-6 flex items-center">
            <span className="mr-3 text-green-500">{icons.bookMarked}</span> Generador de Exámenes
          </h2>
          <GenerateExam
            allQuestions={allQuestions}
            onStartExam={handleStartExamFromGenerator}
            onBack={() => setShowGenerator(false)}
            darkMode={darkMode}
          />
        </div>
      </PageLayout>
    );
  }

  // (e) Si no hay selectedSubject -> mostrar menú de áreas
  if (!selectedSubject) {
    return (
      <PageLayout darkMode={darkMode}>
        <div className="space-y-8">
          {/* Bienvenida y tarjeta de usuario */}
          <div className={`p-8 rounded-xl ${darkMode ? 'bg-gray-800' : 'bg-white shadow-lg'}`}>
            <div className="flex flex-col md:flex-row md:items-center md:justify-between">
              <div>
                <h1 className="text-3xl font-bold mb-2">
                  {process.env.NODE_ENV === 'development'
                    ? '¡Bienvenido Alexis!'
                    : `¡Bienvenido ${user?.user_metadata?.full_name || ''}!`}
                </h1>
                <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Continúa con tu preparación médica
                </p>
              </div>
              
              <div className="mt-4 md:mt-0 flex flex-wrap gap-3">
                <ActionButton 
                  icon={icons.bookMarked}
                  text="Generar Examen"
                  color="orange"
                  onClick={() => setShowGenerator(true)}
                  darkMode={darkMode}
                />
                
                <ActionButton 
                  icon={icons.warning}
                  text="Revisión de Errores"
                  color="purple"
                  onClick={() => setShowErrorReview(true)}
                  darkMode={darkMode}
                />
                
                <ActionButton 
                  icon={icons.history}
                  text="Historial"
                  color="green"
                  onClick={() => setShowExamHistory(true)}
                  darkMode={darkMode}
                />
              </div>
            </div>
          </div>
          
          {/* Áreas de estudio */}
          <div>
            <h2 className="text-2xl font-bold mb-6">Selecciona un Área Médica</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {subjects.map((subject) => (
                <SubjectCard 
                  key={subject.id} 
                  subject={subject} 
                  onSelect={setSelectedSubject}
                  darkMode={darkMode}
                />
              ))}
            </div>
          </div>
          
          {/* Estadísticas rápidas */}
          <div className={`p-8 rounded-xl ${darkMode ? 'bg-gray-800' : 'bg-white shadow-lg'}`}>
            <h3 className="text-xl font-bold mb-4 flex items-center">
              <span className="mr-2 text-yellow-500">{icons.award}</span> Estadísticas Rápidas
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-blue-50'}`}>
                <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Total de Preguntas</p>
                <p className="text-2xl font-bold">{allQuestions.length}</p>
              </div>
              <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-green-50'}`}>
                <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Áreas</p>
                <p className="text-2xl font-bold">{subjects.length}</p>
              </div>
              <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-purple-50'}`}>
                <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Temas</p>
                <p className="text-2xl font-bold">
                  {subjects.reduce((total, subject) => total + subject.topics.length, 0)}
                </p>
              </div>
              <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-yellow-50'}`}>
                <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Base de datos</p>
                <p className="text-2xl font-bold flex items-center">
                  <span className="mr-2">Turso</span>
                  <span className="text-green-500 text-sm">{icons.check}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    );
  }

  // (f) Si no hay selectedTopic -> mostrar menú de temas
  if (!selectedTopic) {
    return (
      <PageLayout darkMode={darkMode}>
        <div className={`p-8 rounded-xl ${darkMode ? 'bg-gray-800' : 'bg-white shadow-lg'}`}>
          <div className="flex items-center mb-6">
            <span className="text-3xl mr-3">{selectedSubject.icon}</span>
            <h2 className="text-2xl font-bold">{selectedSubject.name}</h2>
          </div>
          
          {/* Controles para modo simulacro */}
          <div className={`mb-8 p-5 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-blue-50'}`}>
            <h3 className="text-lg font-medium mb-4 flex items-center">
              <span className="mr-2 text-blue-500">{icons.clock}</span> Configuración de Simulacro
            </h3>
            
            <div className="flex flex-col sm:flex-row sm:items-center gap-4">
              <div className="flex items-center">
                <label className="mr-3 font-medium">Duración:</label>
                <input
                  type="number"
                  value={simulationTime}
                  onChange={(e) => setSimulationTime(Number(e.target.value))}
                  className={`w-20 p-2 rounded text-center ${
                    darkMode 
                      ? 'bg-gray-800 border-gray-600' 
                      : 'border border-gray-300'
                  }`}
                  min="1"
                /> 
                <span className="ml-2">min</span>
              </div>
              
              <ActionButton 
                icon={simulationMode ? icons.pause : icons.play}
                text={simulationMode ? 'Desactivar Simulacro' : 'Activar Simulacro'}
                color={simulationMode ? "red" : "green"}
                onClick={() => setSimulationMode(!simulationMode)}
                className="sm:ml-auto"
                darkMode={darkMode}
              />
            </div>
            
            {simulationMode && (
              <div className={`mt-3 p-3 ${darkMode ? 'bg-blue-900' : 'bg-blue-100'} rounded text-sm slide-in`}>
                <p className={`font-medium ${darkMode ? 'text-blue-200' : 'text-blue-800'}`}>
                  Modo simulacro activado: Preguntas aleatorias con temporizador de {simulationTime} minutos.
                </p>
              </div>
            )}
          </div>
          
          <h3 className="text-xl font-bold mb-5">Selecciona un Tema</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {selectedSubject.topics.map((topic) => (
              <TopicCard 
                key={topic.id} 
                topic={topic} 
                onSelect={setSelectedTopic}
                darkMode={darkMode}
              />
            ))}
          </div>
        </div>
      </PageLayout>
    );
  }

  // (g) Con subject y topic seleccionados -> filtrar preguntas y mostrar <Quiz>
  const filteredQuestions = getFilteredQuestions(selectedSubject.id, selectedTopic.id);

  // Verificar si hay material complementario disponible
  const hasComplementaryMaterial = selectedTopic.id === 'ruidosCardiacos' || selectedTopic.id === 'insuficienciaCardiaca';

  return (
    <PageLayout darkMode={darkMode}>
      <div className="space-y-6">
        <div className={`p-8 rounded-xl ${darkMode ? 'bg-gray-800' : 'bg-white shadow-lg'}`}>
          <Quiz
            title={selectedTopic.name}
            questions={filteredQuestions}
            onBack={() => setSelectedTopic(null)}
            DecisionTableComponents={selectedTopic.DecisionTableComponents}
            simulationMode={simulationMode}
            subjectName={selectedSubject.name}
            topicName={selectedTopic.name}
            simulationTime={simulationTime}
            darkMode={darkMode}
          />
        </div>
        
        {/* Material complementario en una tarjeta separada */}
        {hasComplementaryMaterial && (
          <div className={`p-8 rounded-xl ${darkMode ? 'bg-gray-800' : 'bg-white shadow-lg'}`}>
            <h3 className="text-xl font-bold mb-5 flex items-center">
              <span className="mr-2 text-blue-500">{icons.externalLink}</span>
              Material Complementario
            </h3>
            
            {selectedTopic.id === 'ruidosCardiacos' && (
              <div className="space-y-4">
                <div className="flex flex-wrap gap-3">
                  <ActionButton 
                    icon={icons.list}
                    text={showRuidosCardiacosTable ? "Ocultar Tabla de Ruidos" : "Mostrar Tabla de Ruidos"}
                    color="green"
                    onClick={() => togglePanel(setShowRuidosCardiacosTable, showRuidosCardiacosTable)}
                    darkMode={darkMode}
                  />
                  
                  <ActionButton 
                    icon={icons.list}
                    text={showEsquemaRuidosCardiacos ? "Ocultar Esquema" : "Mostrar Esquema"}
                    color="blue"
                    onClick={() => togglePanel(setShowEsquemaRuidosCardiacos, showEsquemaRuidosCardiacos)}
                    darkMode={darkMode}
                  />
                  
                  <ActionButton 
                    icon={icons.list}
                    text={showRuidosCardiacosTable2 ? "Ocultar Tabla #2" : "Mostrar Tabla #2"}
                    color="purple"
                    onClick={() => togglePanel(setShowRuidosCardiacosTable2, showRuidosCardiacosTable2)}
                    darkMode={darkMode}
                  />
                </div>
                
                <div className={`collapsible ${showRuidosCardiacosTable ? 'show' : ''}`}>
                  <div className={`p-4 rounded-lg mt-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                    <h4 className="font-medium mb-3">Tabla de Ruidos Cardíacos</h4>
                    <RuidosCardiacosTable darkMode={darkMode} />
                  </div>
                </div>
                
                <div className={`collapsible ${showEsquemaRuidosCardiacos ? 'show' : ''}`}>
                  <div className={`p-4 rounded-lg mt-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                    <h4 className="font-medium mb-3">Esquema de Ruidos Cardíacos</h4>
                    <EsquemaRuidosCardiacos darkMode={darkMode} />
                  </div>
                </div>
                
                <div className={`collapsible ${showRuidosCardiacosTable2 ? 'show' : ''}`}>
                  <div className={`p-4 rounded-lg mt-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                    <h4 className="font-medium mb-3">Tabla de Ruidos Cardíacos #2</h4>
                    <Tabladeruidoscardiacos2 darkMode={darkMode} />
                  </div>
                </div>
              </div>
            )}
            
            {selectedTopic.id === 'insuficienciaCardiaca' && (
              <div className="space-y-4">
                <div className="flex flex-wrap gap-3">
                  <ActionButton 
                    icon={icons.list}
                    text={showHeartFailureTable ? "Ocultar Tabla de Insuficiencia" : "Mostrar Tabla de Insuficiencia"}
                    color="green"
                    onClick={() => togglePanel(setShowHeartFailureTable, showHeartFailureTable)}
                    darkMode={darkMode}
                  />
                  
                  <ActionButton 
                    icon={icons.list}
                    text={showHeartFailureTable1 ? "Ocultar Tabla de Clasificación" : "Mostrar Tabla de Clasificación"}
                    color="purple"
                    onClick={() => togglePanel(setShowHeartFailureTable1, showHeartFailureTable1)}
                    darkMode={darkMode}
                  />
                </div>
                
                <div className={`collapsible ${showHeartFailureTable ? 'show' : ''}`}>
                  <div className={`p-4 rounded-lg mt-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                    <h4 className="font-medium mb-3">Tabla de Insuficiencia Cardíaca</h4>
                    <HeartFailureTable darkMode={darkMode} />
                  </div>
                </div>
                
                <div className={`collapsible ${showHeartFailureTable1 ? 'show' : ''}`}>
                  <div className={`p-4 rounded-lg mt-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                    <h4 className="font-medium mb-3">Clasificación de Insuficiencia Cardíaca</h4>
                    <HeartFailureTable1 darkMode={darkMode} />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default App;