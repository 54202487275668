import React, { useState, useMemo, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Label,
  PieChart,
  Pie,
  Cell,
  ScatterChart,
  Scatter,
  Rectangle,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ReferenceArea,
  ReferenceLine,
  // Componentes adicionales necesarios
  AreaChart,
  Area,
  ComposedChart,
  Brush
} from 'recharts';

// Importaciones de Lucide-React para iconos
import { 
  ArrowUpCircle, 
  ArrowDownCircle, 
  Minus, 
  AlertTriangle, 
  BarChart2, 
  TrendingUp, 
  Activity, 
  Calendar, 
  Info, 
  Download, 
  PieChart as PieChartIcon, 
  Share2, 
  Sliders,
  ChevronDown,
  ChevronUp,
  ChevronsUpDown,
  Filter,
  RefreshCw,
  Eye,
  EyeOff,
  Save,
  FileText,
  HelpCircle
} from 'lucide-react';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

// Componentes para el análisis avanzado
const TrendHeatmap = ({ data, years }) => {
  // Estados para controles interactivos
  const [sortBy, setSortBy] = useState('total'); // 'total', 'name', 'growth'
  const [sortDirection, setSortDirection] = useState('desc');
  const [colorScheme, setColorScheme] = useState('blue'); // 'blue', 'heat', 'rainbow'
  const [showEmptyTopics, setShowEmptyTopics] = useState(false);
  const [maxTopicsToShow, setMaxTopicsToShow] = useState(20);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  
  // Procesar y ordenar datos para el mapa de calor
  const { heatmapData, uniqueTopics, topicTotals, maxCount, yearTotals } = useMemo(() => {
    // Primero, filtrar únicamente los registros que son temas (no totales ni subtemas)
    const topicData = data ? data.filter(item => !item.isTopic && !item.isSubtopic) : [];
    
    // Para propósitos de demostración, generar algunos datos de ejemplo si no hay datos
    const demoData = [];
    if (!data || topicData.length === 0) {
      const demoTopics = [
        'Cardiopatía isquémica', 'Hipertensión arterial', 'Insuficiencia cardíaca', 
        'Arritmias', 'Valvulopatías', 'Cardiopatías congénitas', 'Fiebre reumática',
        'Bases en Cardiología', 'RCR', 'Shock (enfoque básico)'
      ];
      
      const demoYears = years && years.length > 0 ? years : 
        ['RM 2010', 'RM 2011', 'RM 2012', 'RM 2013', 'RM 2014', 
         'RM 2015', 'RM 2016', 'RM 2018', 'RM 2019', 'RM 2020', 'RM 2022', 'RM 2024'];
      
      demoTopics.forEach(topic => {
        demoYears.forEach(year => {
          // Generar algunos valores aleatorios para demostración
          // Concentrar valores en ciertos años para mostrar tendencias
          let value = 0;
          const rand = Math.random();
          
          // Dar más peso a algunos temas en ciertos años
          if (topic === 'Cardiopatía isquémica' && year.includes('2016')) value = 3;
          else if (topic === 'Hipertensión arterial' && year.includes('2019')) value = 2;
          else if (topic === 'Insuficiencia cardíaca' && year.includes('2020')) value = 3;
          else if (topic === 'Arritmias' && year.includes('2022')) value = 2;
          else if (rand > 0.85) value = 1;
          else if (rand > 0.97) value = 2;
          
          if (value > 0) {
            demoData.push({
              topic: topic,
              year: year,
              total: value,
              counts: { [year]: value }
            });
          }
        });
      });
    }
    
    // Usar datos reales o datos de demostración
    const workingData = topicData.length > 0 ? topicData : demoData;
    const workingYears = years && years.length > 0 ? years : 
      Array.from(new Set(demoData.map(item => item.year))).sort();
    
    // Extraer todos los temas únicos y calcular sus totales
    const topics = {};
    const yearSums = {};
    
    // Construir diccionario de temas con sus conteos acumulados
    workingData.forEach(item => {
      if (!topics[item.topic]) {
        topics[item.topic] = {
          name: item.topic,
          total: 0,
          yearCounts: {},
          growth: 0
        };
      }
      
      // Sumar al total general del tema
      topics[item.topic].total += item.total || 0;
      
      // Sumar conteos por año
      workingYears.forEach(year => {
        // Para el tema
        if (!topics[item.topic].yearCounts[year]) {
          topics[item.topic].yearCounts[year] = 0;
        }
        
        // Si el ítem actual es del año que estamos procesando, sumar su conteo
        if (item.counts && item.counts[year]) {
          topics[item.topic].yearCounts[year] += item.counts[year];
        } else if (item.year === year) {
          // Alternativa si los datos vienen en otro formato
          topics[item.topic].yearCounts[year] += item.total || 0;
        }
        
        // Para los totales anuales
        if (!yearSums[year]) yearSums[year] = 0;
        
        // Actualizar el total del año
        if (item.counts && item.counts[year]) {
          yearSums[year] += item.counts[year];
        } else if (item.year === year) {
          yearSums[year] += item.total || 0;
        }
      });
    });
    
    // Calcular crecimiento (del primer al último año disponible)
    Object.values(topics).forEach(topic => {
      const firstYear = workingYears[0];
      const lastYear = workingYears[workingYears.length - 1];
      const firstCount = topic.yearCounts[firstYear] || 0.1; // Evitar división por cero
      const lastCount = topic.yearCounts[lastYear] || 0;
      
      // Calcular tasa de crecimiento
      if (firstCount && lastCount) {
        topic.growth = ((lastCount - firstCount) / firstCount) * 100;
      }
    });
    
    // Convertir a array para ordenación
    let topicsArray = Object.values(topics);
    
    // Filtrar temas vacíos si está desactivada la opción
    if (!showEmptyTopics) {
      topicsArray = topicsArray.filter(topic => topic.total > 0);
    }
    
    // Ordenar según criterio seleccionado
    if (sortBy === 'name') {
      topicsArray.sort((a, b) => sortDirection === 'asc' 
        ? a.name.localeCompare(b.name) 
        : b.name.localeCompare(a.name));
    } else if (sortBy === 'total') {
      topicsArray.sort((a, b) => sortDirection === 'asc' 
        ? a.total - b.total 
        : b.total - a.total);
    } else if (sortBy === 'growth') {
      topicsArray.sort((a, b) => sortDirection === 'asc' 
        ? a.growth - b.growth 
        : b.growth - a.growth);
    }
    
    // Limitar número de temas a mostrar
    topicsArray = topicsArray.slice(0, maxTopicsToShow);
    
    // Crear datos para el heatmap
    const heatmapData = [];
    let maxVal = 0;
    
    topicsArray.forEach((topic, topicIndex) => {
      workingYears.forEach((year, yearIndex) => {
        const count = topic.yearCounts[year] || 0;
        if (count > maxVal) maxVal = count;
        
        heatmapData.push({
          topic: topic.name,
          year,
          count,
          topicIndex,
          yearIndex,
          topicTotal: topic.total,
          yearTotal: yearSums[year] || 0,
          percentage: yearSums[year] ? Math.round((count / yearSums[year]) * 100) : 0
        });
      });
    });
    
    return { 
      heatmapData, 
      uniqueTopics: topicsArray.map(t => t.name), 
      topicTotals: topicsArray.reduce((acc, t) => ({ ...acc, [t.name]: t.total }), {}),
      maxCount: Math.max(1, maxVal), // Asegurar que maxCount nunca sea 0
      yearTotals: yearSums
    };
  }, [data, years, sortBy, sortDirection, showEmptyTopics, maxTopicsToShow]);
  
  // Función optimizada para determinar el color basado en el conteo
  const getColor = (count) => {
    if (count === 0) return '#f0f0f0'; // Gris claro para cero (más visible que blanco)
    
    // Asegurar que maxCount nunca sea cero para evitar división por cero
    const effectiveMaxCount = Math.max(1, maxCount);
    
    // Para valores pequeños, usar una escala no lineal para amplificar diferencias
    // Función de raíz cuadrada para dar más peso visual a valores pequeños
    const normalizedValue = Math.sqrt(count / effectiveMaxCount);
    
    switch (colorScheme) {
      case 'heat': 
        // Escala de calor mejorada
        if (count === 0) return '#f0f0f0';
        if (count <= 1) return '#ffecb3'; // Amarillo muy claro
        if (count <= 2) return '#ffcc80'; // Naranja claro
        if (count <= 3) return '#ff9800'; // Naranja
        return '#e65100'; // Naranja oscuro
        
      case 'rainbow':
        // Escala arcoíris
        if (count === 0) return '#f0f0f0';
        if (count <= 1) return '#bbdefb'; // Azul claro
        if (count <= 2) return '#4caf50'; // Verde
        if (count <= 3) return '#ffc107'; // Amarillo
        return '#f44336'; // Rojo
        
      case 'blue':
      default:
        // Escala de azules MEJORADA para visibilidad
        if (count === 0) return '#f0f0f0';
        if (count <= 1) return '#90caf9'; // Azul claro MÁS VIVO
        if (count <= 2) return '#2196f3'; // Azul medio intenso
        if (count <= 3) return '#1565c0'; // Azul intenso
        return '#0d47a1'; // Azul muy intenso
    }
  };
  
  // Dimensiones y layout
  const baseSize = 42; // Aumentado para mejor visibilidad
  const minCellSize = 38; // Tamaño mínimo para celdas
  const cellSize = Math.max(minCellSize, baseSize - (uniqueTopics.length > 10 ? (uniqueTopics.length - 10) * 1.5 : 0));
  
  // Altura dinámica basada en número de temas y tamaño de celda
  const chartHeight = uniqueTopics.length ? Math.max(400, cellSize * (uniqueTopics.length + 2)) : 400;
  
  // Formatters para tooltip personalizado
  const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload || !payload.length) return null;
    
    const data = payload[0].payload;
    const { topic, year, count, topicTotal, yearTotal, percentage } = data;
    
    return (
      <div className="bg-white p-4 border border-gray-200 rounded shadow-lg">
        <h4 className="font-bold text-lg border-b pb-2 mb-2">{topic}</h4>
        <p className="text-sm mb-1">Año: <strong>{year}</strong></p>
        <p className="text-sm mb-1">
          Preguntas: <strong>{count}</strong> 
          {count > 0 && <span className="text-gray-600"> ({percentage}% del año)</span>}
        </p>
        <div className="mt-3 pt-2 border-t border-gray-200">
          <p className="text-xs text-gray-600">Total tema: {topicTotal} preguntas</p>
          <p className="text-xs text-gray-600">Total {year}: {yearTotal} preguntas</p>
        </div>
      </div>
    );
  };
  
  // Manejar selección de celda
  const handleCellClick = (data) => {
    setSelectedTopic(data.topic);
    setSelectedYear(data.year);
  };
  
  // Para propósitos de demostración - generar un conjunto de años si no hay años
  const displayYears = years && years.length > 0 ? years : 
    ['RM 2010', 'RM 2011', 'RM 2012', 'RM 2013', 'RM 2014', 
     'RM 2015', 'RM 2016', 'RM 2018', 'RM 2019', 'RM 2020', 'RM 2022', 'RM 2024'];
  
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-6">
        <h3 className="text-xl font-bold mb-3 md:mb-0">
          Mapa de Calor: Temas por Año
        </h3>
        
        {/* Controles de visualización - versión responsive */}
        <div className="flex flex-wrap gap-3">
          {/* Selector de esquema de color */}
          <div className="flex items-center">
            <label className="text-sm text-gray-700 mr-2">Colores:</label>
            <select 
              value={colorScheme}
              onChange={(e) => setColorScheme(e.target.value)}
              className="text-sm border rounded p-1 bg-white"
            >
              <option value="blue">Azules</option>
              <option value="heat">Calor</option>
              <option value="rainbow">Arcoíris</option>
            </select>
          </div>
          
          {/* Selector de ordenamiento */}
          <div className="flex items-center">
            <label className="text-sm text-gray-700 mr-2">Ordenar:</label>
            <select 
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="text-sm border rounded p-1 bg-white"
            >
              <option value="total">Por total</option>
              <option value="name">Por nombre</option>
              <option value="growth">Por crecimiento</option>
            </select>
            
            <button 
              onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
              className="p-1 rounded hover:bg-gray-200 ml-1"
              title={`Orden ${sortDirection === 'desc' ? 'descendente' : 'ascendente'}`}
            >
              {sortDirection === 'desc' ? '↓' : '↑'}
            </button>
          </div>
          
          {/* Selector de límite */}
          <div className="flex items-center">
            <label className="text-sm text-gray-700 mr-2">Mostrar:</label>
            <select 
              value={maxTopicsToShow}
              onChange={(e) => setMaxTopicsToShow(Number(e.target.value))}
              className="text-sm border rounded p-1 bg-white"
            >
              <option value="10">10 temas</option>
              <option value="20">20 temas</option>
              <option value="50">50 temas</option>
              <option value="100">100 temas</option>
            </select>
          </div>
          
          {/* Toggle para temas vacíos */}
          <button
            onClick={() => setShowEmptyTopics(!showEmptyTopics)}
            className={`p-1 rounded text-sm ${showEmptyTopics ? 'bg-blue-100 text-blue-800' : 'bg-gray-100'}`}
            title="Mostrar/ocultar temas sin datos"
          >
            <Filter size={16} className="inline mr-1" />
            {showEmptyTopics ? 'Ocultar vacíos' : 'Mostrar todos'}
          </button>
        </div>
      </div>
      
      {/* Información de selección si hay tema/año seleccionado */}
      {(selectedTopic || selectedYear) && (
        <div className="mb-4 p-3 bg-blue-50 border-l-4 border-blue-500 rounded flex justify-between items-center">
          <div>
            {selectedTopic && <span className="font-medium">Tema: {selectedTopic}</span>}
            {selectedTopic && selectedYear && <span className="mx-2">|</span>}
            {selectedYear && <span className="font-medium">Año: {selectedYear}</span>}
          </div>
          <button 
            onClick={() => { setSelectedTopic(null); setSelectedYear(null); }}
            className="text-sm text-blue-600 hover:underline"
          >
            Limpiar selección
          </button>
        </div>
      )}
      
      {heatmapData.length > 0 ? (
        <div className="overflow-x-auto">
          <ResponsiveContainer width="100%" height={chartHeight}>
            <ScatterChart
              margin={{ top: 30, right: 40, bottom: 20, left: Math.min(250, Math.max(150, uniqueTopics.length * 3)) }}
            >
              <CartesianGrid strokeDasharray="3 3" opacity={0.3} />
              <XAxis
                type="category"
                dataKey="year"
                name="Año"
                allowDuplicatedCategory={false}
                tick={{ fontSize: 12 }}
              >
                <Label value="Años" position="bottom" offset={0} />
              </XAxis>
              <YAxis
                type="category"
                dataKey="topic"
                name="Tema"
                allowDuplicatedCategory={false}
                width={Math.min(230, Math.max(150, uniqueTopics.length * 3))}
                tick={(props) => {
                  const { x, y, payload } = props;
                  const topic = payload.value;
                  
                  // Añadir total junto al tema
                  return (
                    <g transform={`translate(${x},${y})`}>
                      <text x={-10} y={0} dy={4} textAnchor="end" fill="#333" fontSize={11} fontWeight="500">
                        {topic}
                      </text>
                      <text x={-2} y={0} dy={4} textAnchor="end" fill="#666" fontSize={9}>
                        ({topicTotals[topic] || 0})
                      </text>
                    </g>
                  );
                }}
              >
                <Label value="Temas" position="insideLeft" angle={-90} offset={10} />
              </YAxis>
              
              {/* Necesaria para el tooltip */}
              <ZAxis type="number" dataKey="count" range={[0, 0]} />
              
              <Tooltip content={<CustomTooltip />} />
              
              <Scatter
                name="Preguntas por Tema y Año"
                data={heatmapData}
                fill="#8884d8"
                shape={(props) => {
                  const { cx, cy, payload } = props;
                  
                  // Extraer los datos necesarios del payload
                  const count = payload?.count || 0;
                  const isSelected = (selectedTopic === payload?.topic) || (selectedYear === payload?.year);
                  
                  return (
                    <g>
                      {/* Rectángulo base con color */}
                      <Rectangle
                        x={cx - cellSize/2}
                        y={cy - cellSize/2}
                        width={cellSize}
                        height={cellSize}
                        fill={getColor(count)}
                        stroke={isSelected ? "#333" : "#ddd"}
                        strokeWidth={isSelected ? 2 : 0.5}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleCellClick(payload)}
                      />
                      
                      {/* Añadir valor dentro de la celda SIEMPRE que sea mayor que 0 */}
                      {count > 0 && (
                        <text
                          x={cx}
                          y={cy}
                          textAnchor="middle"
                          dominantBaseline="middle"
                          fill={count > 2 ? "#fff" : "#333"}
                          fontSize={Math.min(14, cellSize/3)}
                          fontWeight="bold"
                          onClick={() => handleCellClick(payload)}
                          style={{ cursor: 'pointer', pointerEvents: 'none' }}
                        >
                          {count}
                        </text>
                      )}
                    </g>
                  );
                }}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className="p-4 bg-amber-50 border-l-4 border-amber-500 rounded mb-4">
          <div className="flex items-start">
            <AlertTriangle className="text-amber-500 mr-3 mt-1" size={20} />
            <div>
              <p className="text-amber-800 font-medium">No hay datos suficientes para generar el mapa de calor.</p>
              <p className="text-amber-700 mt-2">
                Prueba a ajustar los filtros o incluir más años y temas en el análisis.
              </p>
            </div>
          </div>
        </div>
      )}
      
      {/* Leyenda mejorada para valores pequeños */}
      {heatmapData.length > 0 && (
        <div className="mt-6 flex justify-center items-center">
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h4 className="text-sm font-semibold text-center mb-2">Intensidad por Número de Preguntas</h4>
            
            {maxCount <= 5 ? (
              // Para valores pequeños, mostrar leyenda DISCRETA con números exactos
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center mb-2">
                  {[...Array(Math.min(maxCount + 1, 6))].map((_, i) => (
                    <div key={i} className="flex flex-col items-center mx-1">
                      <div 
                        className="w-10 h-10 border border-gray-300 mb-1 flex items-center justify-center"
                        style={{ backgroundColor: getColor(i) }}
                      >
                        {i > 0 && (
                          <span className="text-xs font-bold" style={{color: i > 2 ? "#fff" : "#333"}}>
                            {i}
                          </span>
                        )}
                      </div>
                      <span className="text-xs">{i}</span>
                    </div>
                  ))}
                </div>
                <div className="mt-2 text-xs text-gray-700 font-medium text-center">
                  Los números indican la cantidad exacta de preguntas en cada celda
                </div>
              </div>
            ) : (
              // Para rangos más amplios, usar gradiente
              <div className="flex items-center">
                <div className="text-xs mr-2">0</div>
                <div className="flex">
                  {[...Array(8)].map((_, i) => {
                    // Distribuir equitativamente los valores para mostrar toda la escala
                    const value = Math.ceil((i / 7) * maxCount);
                    return (
                      <div
                        key={i}
                        className="w-8 h-8 border border-gray-200 flex items-center justify-center"
                        style={{ backgroundColor: getColor(value) }}
                      >
                        {i > 0 && i < 7 && i % 2 === 1 && (
                          <span className="text-xs font-bold" style={{color: i > 4 ? "#fff" : "#333"}}>
                            {value}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="text-xs ml-2">{maxCount}</div>
              </div>
            )}
            
            <div className="mt-2 text-xs text-gray-500 text-center">
              Total máximo por celda: {maxCount} {maxCount === 1 ? 'pregunta' : 'preguntas'}
            </div>
          </div>
        </div>
      )}
      
      {/* Información contextual */}
      <div className="mt-6 text-sm text-gray-600">
        <p className="mb-1">* Los números entre paréntesis junto a cada tema indican el total de preguntas.</p>
        <p>* Haz clic en cualquier celda para resaltar ese tema o año específico.</p>
      </div>
    </div>
  );
};

const MultiTrendChart = ({ trendFilteredQuestions, years }) => {
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [comparisonMode, setComparisonMode] = useState('topic'); // 'topic' o 'subtopic'
  const [maxItemsToDisplay, setMaxItemsToDisplay] = useState(5);
  
  // Colores para las líneas
  const lineColors = [
    "#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#A133FF", 
    "#33FFF2", "#FF8F33", "#8F33FF", "#33A1FF", "#FFA133"
  ];

  // Calcular datos disponibles para selección
  const availableOptions = useMemo(() => {
    if (comparisonMode === 'topic') {
      return [...new Set(trendFilteredQuestions.map(q => q.topic))].sort();
    } else {
      return [...new Set(trendFilteredQuestions.map(q => q.subtopic))].sort();
    }
  }, [trendFilteredQuestions, comparisonMode]);

  // Calcular datos para el gráfico
  const chartData = useMemo(() => {
    const yearData = years.map(year => ({ year }));
    
    // Si no hay selección, mostrar los top N elementos por total de preguntas
    let itemsToShow = selectedTopics.length > 0 ? selectedTopics : [];
    
    if (itemsToShow.length === 0) {
      // Contar elementos y ordenar por total
      const counts = {};
      trendFilteredQuestions.forEach(q => {
        const key = comparisonMode === 'topic' ? q.topic : q.subtopic;
        if (!counts[key]) counts[key] = 0;
        counts[key]++;
      });
      
      itemsToShow = Object.entries(counts)
        .sort((a, b) => b[1] - a[1])
        .slice(0, maxItemsToDisplay)
        .map(([item]) => item);
    }
    
    // Para cada elemento seleccionado, calcular su tendencia por año
    itemsToShow.forEach(item => {
      years.forEach((year, idx) => {
        const count = trendFilteredQuestions.filter(q => {
          const itemValue = comparisonMode === 'topic' ? q.topic : q.subtopic;
          return itemValue === item && q.year === year;
        }).length;
        
        yearData[idx][item] = count;
      });
    });
    
    return { yearData, itemsToShow };
  }, [years, trendFilteredQuestions, selectedTopics, comparisonMode, maxItemsToDisplay]);

  // Manejar cambios en la selección
  const handleOptionToggle = (option) => {
    setSelectedTopics(prev => 
      prev.includes(option)
        ? prev.filter(t => t !== option)
        : [...prev, option]
    );
  };

  // Limpiar selecciones
  const clearSelections = () => setSelectedTopics([]);

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-3">
          Comparación de Tendencias
        </h3>
        
        <div className="flex justify-between mb-4">
          <div className="space-x-2">
            <button
              className={`px-3 py-1 rounded ${comparisonMode === 'topic' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
              onClick={() => setComparisonMode('topic')}
            >
              Por Tema
            </button>
            <button
              className={`px-3 py-1 rounded ${comparisonMode === 'subtopic' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
              onClick={() => setComparisonMode('subtopic')}
            >
              Por Subtema
            </button>
          </div>
          
          <div>
            <label className="mr-2">Máximo elementos: </label>
            <select
              value={maxItemsToDisplay}
              onChange={(e) => setMaxItemsToDisplay(Number(e.target.value))}
              className="border rounded px-2 py-1"
            >
              {[3, 5, 8, 10, 15].map(num => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
          </div>
        </div>
        
        <div className="mb-4">
          <div className="flex justify-between items-center mb-2">
            <span className="font-semibold">
              Seleccionar {comparisonMode === 'topic' ? 'Temas' : 'Subtemas'}:
            </span>
            <button
              onClick={clearSelections}
              className="text-sm text-blue-600 hover:underline"
            >
              Limpiar selección
            </button>
          </div>
          
          <div className="flex flex-wrap gap-2 max-h-28 overflow-y-auto p-2 border rounded">
            {availableOptions.map(option => (
              <button
                key={option}
                className={`text-sm px-2 py-1 rounded-full ${
                  selectedTopics.includes(option)
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
                onClick={() => handleOptionToggle(option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      </div>
      
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData.yearData}
            margin={{ top: 10, right: 30, left: 0, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend />
            
            {chartData.itemsToShow.map((item, index) => (
              <Line
                key={item}
                type="monotone"
                dataKey={item}
                name={item}
                stroke={lineColors[index % lineColors.length]}
                activeDot={{ r: 8 }}
                strokeWidth={2}
                connectNulls
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

// Función para calcular la confianza de la predicción
const calculateConfidence = (n, stdDev, total) => {
  if (n < 4) return 'baja';
  if (n < 6) return 'media';
  if (stdDev > total / (n * 2)) return 'media';
  return 'alta';
};
// Componente Mejorado de Análisis Estadístico
const TrendAnalysisStats = ({ trendFilteredQuestions, years, title = "Análisis Estadístico de Tendencias" }) => {
  // Estados para elementos interactivos
  const [chartType, setChartType] = useState('line');
  const [showConfidenceInterval, setShowConfidenceInterval] = useState(false);
  const [showMovingAverage, setShowMovingAverage] = useState(false);
  const [forecastRange, setForecastRange] = useState(2);
  const [showSeasonality, setShowSeasonality] = useState(false);
  
  // Función para extraer año numérico de diferentes formatos
  const extractYear = (yearStr) => {
    if (!yearStr) return null;
    
    // Intenta encontrar un patrón de 4 dígitos (año)
    const matches = String(yearStr).match(/\d{4}/);
    if (matches && matches.length > 0) {
      return parseInt(matches[0], 10);
    }
    
    // Si no hay un patrón, intenta la conversión directa
    const parsed = parseInt(String(yearStr).trim(), 10);
    return isNaN(parsed) ? null : parsed;
  };
  
  // Calcular análisis detallado con modelos estadísticos avanzados
  const analysisData = useMemo(() => {
    // Validación inicial de datos
    if (!trendFilteredQuestions || !years || years.length === 0) {
      return {
        error: true,
        message: "No hay datos disponibles para el análisis."
      };
    }
    
    // Preparar datos de preguntas por año
    const questionsPerYear = years.map(year => {
      const yearNumber = extractYear(year);
      return {
        yearOriginal: year,
        year: yearNumber,
        yearLabel: year,
        count: trendFilteredQuestions.filter(q => {
          const qYear = q.year || "";
          return qYear.toString() === year.toString();
        }).length
      };
    }).filter(item => item.year !== null);
    
    // Validar que tenemos datos suficientes
    if (questionsPerYear.length === 0) {
      return {
        error: true,
        message: "No se pudieron procesar los datos correctamente. Verifique el formato de años."
      };
    }
    
    // Información básica sobre los datos
    const totalQuestions = questionsPerYear.reduce((sum, item) => sum + item.count, 0);
    const averageQuestions = totalQuestions / questionsPerYear.length;
    
    // Si no hay suficientes años o preguntas
    if (questionsPerYear.length < 2) {
      return {
        error: true,
        questionsPerYear,
        totalQuestions,
        averageQuestions,
        message: "Se necesitan al menos 2 años para calcular tendencias."
      };
    }
    
    if (totalQuestions === 0) {
      return {
        error: true,
        questionsPerYear,
        totalQuestions,
        message: "No hay preguntas registradas en los períodos seleccionados."
      };
    }
    
    // Ordenar por año para análisis cronológico correcto
    const sortedData = [...questionsPerYear].sort((a, b) => a.year - b.year);
    
    // --- Análisis de Regresión Lineal ---
    const n = sortedData.length;
    
    // Calcular sumatorias para la regresión lineal
    const sumX = sortedData.reduce((sum, item) => sum + item.year, 0);
    const sumY = sortedData.reduce((sum, item) => sum + item.count, 0);
    const sumXY = sortedData.reduce((sum, item) => sum + (item.year * item.count), 0);
    const sumX2 = sortedData.reduce((sum, item) => sum + (item.year * item.year), 0);
    const sumY2 = sortedData.reduce((sum, item) => sum + (item.count * item.count), 0);
    
    // Calcular pendiente e intercepto con manejo avanzado de errores
    let slope, intercept, rSquared;
    
    const ssCovXY = sumXY - (sumX * sumY / n);
    const ssVarX = sumX2 - (sumX * sumX / n);
    const ssVarY = sumY2 - (sumY * sumY / n);
    
    if (Math.abs(ssVarX) < 0.0001) { // Evitar división por cero
      slope = 0;
      intercept = sumY / n;
      rSquared = 0;
    } else {
      slope = ssCovXY / ssVarX;
      intercept = (sumY - slope * sumX) / n;
      
      // Calcular R² (coeficiente de determinación)
      if (ssVarY < 0.0001) {
        rSquared = 1; // Si no hay variación en Y, el modelo explica "toda" la variación
      } else {
        rSquared = Math.pow(ssCovXY, 2) / (ssVarX * ssVarY);
        // Limitar R² al rango [0,1]
        rSquared = Math.max(0, Math.min(1, rSquared));
      }
    }
    
    // Función para calcular valor en la línea de tendencia
    const linearTrend = (year) => {
      return slope * year + intercept;
    };
    
    // --- Análisis de Estacionalidad y Ciclos ---
    const movingAverage = [];
    const windowSize = Math.max(2, Math.floor(n / 3)); // Tamaño de ventana adaptativo
    
    for (let i = 0; i < sortedData.length; i++) {
      const windowStart = Math.max(0, i - Math.floor(windowSize/2));
      const windowEnd = Math.min(sortedData.length - 1, i + Math.floor(windowSize/2));
      const windowValues = sortedData.slice(windowStart, windowEnd + 1);
      const windowSum = windowValues.reduce((sum, item) => sum + item.count, 0);
      const windowAvg = windowSum / windowValues.length;
      
      movingAverage.push({
        ...sortedData[i],
        movingAvg: Math.round(windowAvg * 10) / 10
      });
    }
    
    // --- Detección de Patrones y Anomalías ---
    // Calcular desviación estándar para detección de outliers
    const mean = sumY / n;
    const squaredDiffs = sortedData.map(item => Math.pow(item.count - mean, 2));
    const variance = squaredDiffs.reduce((sum, val) => sum + val, 0) / n;
    const stdDev = Math.sqrt(variance);
    
    // Detectar outliers usando método Z-score
    const zScoreThreshold = 1.5;
    const outliers = sortedData.map(item => {
      const zScore = stdDev > 0 ? (item.count - mean) / stdDev : 0;
      return {
        ...item,
        zScore,
        isOutlier: Math.abs(zScore) > zScoreThreshold,
        outlierType: zScore > zScoreThreshold ? 'alto' : (zScore < -zScoreThreshold ? 'bajo' : null)
      };
    }).filter(item => item.isOutlier);
    
    // --- Análisis de Puntos de Inflexión ---
    const inflectionPoints = [];
    if (sortedData.length >= 3) {
      for (let i = 1; i < sortedData.length - 1; i++) {
        const prev = sortedData[i-1].count;
        const current = sortedData[i].count;
        const next = sortedData[i+1].count;
        
        // Detectar cambios de dirección significativos
        if ((prev < current && current > next) || (prev > current && current < next)) {
          // Calcular la magnitud del cambio
          const prevDiff = Math.abs(current - prev);
          const nextDiff = Math.abs(next - current);
          const significance = (prevDiff + nextDiff) / 2 / mean;
          
          if (significance > 0.15) { // Solo puntos de inflexión significativos
            inflectionPoints.push({
              ...sortedData[i],
              type: prev < current ? 'máximo' : 'mínimo',
              significance: Math.round(significance * 100)
            });
          }
        }
      }
    }
    
    // --- Análisis de Tendencia y Proyección ---
    // Detectar tendencia general con criterios mejorados
    let trendDirection, trendStrength;
    const absSlope = Math.abs(slope);
    const relativeTrend = absSlope / mean;
    
    if (absSlope < 0.1 || relativeTrend < 0.01) {
      trendDirection = 'estable';
      trendStrength = 'muy baja';
    } else if (slope > 0) {
      trendDirection = 'creciente';
      if (relativeTrend > 0.1) trendStrength = 'muy alta';
      else if (relativeTrend > 0.05) trendStrength = 'alta';
      else if (relativeTrend > 0.02) trendStrength = 'moderada';
      else trendStrength = 'baja';
    } else {
      trendDirection = 'decreciente';
      if (relativeTrend > 0.1) trendStrength = 'muy alta';
      else if (relativeTrend > 0.05) trendStrength = 'alta';
      else if (relativeTrend > 0.02) trendStrength = 'moderada';
      else trendStrength = 'baja';
    }
    
    // Calcular intervalos de confianza para la tendencia (aproximación simplificada)
    const confidenceLevel = 0.95; // 95% de confianza
    const tValue = 1.96; // Aproximación para muestras grandes
    
    const calculateConfidenceInterval = (year) => {
      // Error estándar de predicción simplificado
      const x = year;
      const xBar = sumX / n;
      const seSlope = Math.sqrt((1 / (n - 2)) * ((ssVarY - slope * slope * ssVarX) / ssVarX));
      const sePrediction = stdDev * Math.sqrt(1 + 1/n + Math.pow(x - xBar, 2) / ssVarX);
      
      const marginOfError = tValue * sePrediction;
      const predicted = linearTrend(year);
      
      return {
        lower: Math.max(0, Math.round(predicted - marginOfError)),
        upper: Math.round(predicted + marginOfError)
      };
    };
    
    // Tasas de crecimiento interanuales con validación robusta
    const growthRates = [];
    for (let i = 1; i < sortedData.length; i++) {
      const prevCount = sortedData[i-1].count;
      const currentCount = sortedData[i].count;
      const yearDiff = sortedData[i].year - sortedData[i-1].year;
      
      // Solo calcular para años consecutivos o ajustar por diferencia de años
      if (yearDiff >= 1) {
        let growthRate;
        let annualizedRate;
        
        if (prevCount === 0) {
          growthRate = currentCount > 0 ? null : 0;
          annualizedRate = null;
        } else {
          growthRate = ((currentCount - prevCount) / prevCount) * 100;
          // Anualizar para períodos no consecutivos
          annualizedRate = yearDiff > 1 
            ? (Math.pow((currentCount / prevCount), 1/yearDiff) - 1) * 100 
            : growthRate;
        }
        
        let sign = 'neutral';
        if (growthRate === null) sign = 'new';
        else if (growthRate > 0) sign = 'positive';
        else if (growthRate < 0) sign = 'negative';
        
        growthRates.push({
          yearFrom: sortedData[i-1].yearLabel,
          yearTo: sortedData[i].yearLabel,
          from: prevCount,
          to: currentCount,
          rate: growthRate === null ? 'Nueva' : growthRate.toFixed(1),
          annualizedRate: annualizedRate === null ? 'N/A' : annualizedRate.toFixed(1),
          yearDiff,
          sign
        });
      }
    }
    
    // --- Proyección de Tendencias Futuras ---
    // Calcular años para pronóstico (se utiliza forecastRange como valor predeterminado)
    const lastYear = sortedData[sortedData.length - 1].year;
    const lastYearLabel = sortedData[sortedData.length - 1].yearLabel;
    
    const forecast = [];
    for (let i = 1; i <= forecastRange; i++) {
      const forecastYear = lastYear + i;
      const trend = Math.max(0, Math.round(linearTrend(forecastYear)));
      const ci = calculateConfidenceInterval(forecastYear);
      
      // Derivar etiqueta de año usando el mismo patrón que el último año
      let forecastYearLabel;
      if (lastYearLabel.includes(lastYear.toString())) {
        forecastYearLabel = lastYearLabel.replace(lastYear.toString(), forecastYear.toString());
      } else {
        forecastYearLabel = forecastYear.toString();
      }
      
      forecast.push({
        yearOriginal: forecastYearLabel,
        year: forecastYear,
        yearLabel: forecastYearLabel,
        count: null,
        trend,
        ci,
        isForecast: true
      });
    }
    
    // --- Evaluación de la Calidad del Modelo ---
    // Error cuadrático medio (MSE)
    const squaredErrors = sortedData.map(item => 
      Math.pow(item.count - linearTrend(item.year), 2)
    );
    const mse = squaredErrors.reduce((sum, val) => sum + val, 0) / n;
    const rmse = Math.sqrt(mse);
    
    // Mejorar evaluación del nivel de confianza
    let confidenceRating;
    if (rSquared > 0.8 && n >= 5) confidenceRating = 'muy alta';
    else if (rSquared > 0.6 && n >= 4) confidenceRating = 'alta';
    else if (rSquared > 0.4 && n >= 3) confidenceRating = 'media';
    else if (rSquared > 0.2) confidenceRating = 'baja';
    else confidenceRating = 'muy baja';
    
    // --- Datos Enriquecidos para Visualización ---
    // Añadir valores de tendencia y confianza a los datos históricos
    const enhancedData = sortedData.map(item => {
      const trendValue = Math.max(0, Math.round(linearTrend(item.year)));
      const ci = calculateConfidenceInterval(item.year);
      
      return {
        ...item,
        trend: trendValue,
        ci,
        residual: item.count - trendValue
      };
    });
    
    // Combinar datos históricos con pronósticos
    const combinedData = [...enhancedData, ...forecast];
    
    // Detectar patrones estacionales (simplificado)
    let hasSeasonality = false;
    let seasonalityPattern = 'No detectado';
    
    if (n >= 6) {
      // Analizar residuos para buscar patrones cíclicos
      const residuals = enhancedData.map(item => item.count - linearTrend(item.year));
      
      // Análisis básico de correlación de residuos
      const residualDiffs = [];
      for (let i = 1; i < residuals.length; i++) {
        residualDiffs.push(Math.sign(residuals[i] - residuals[i-1]));
      }
      
      // Buscar patrones de alternancia (+,-, +,-)
      let alternationCount = 0;
      for (let i = 1; i < residualDiffs.length; i++) {
        if (residualDiffs[i] * residualDiffs[i-1] < 0) {
          alternationCount++;
        }
      }
      
      const alternationRatio = alternationCount / (residualDiffs.length - 1);
      
      if (alternationRatio > 0.7) {
        hasSeasonality = true;
        seasonalityPattern = 'Altamente cíclico (alternancia frecuente)';
      } else if (alternationRatio > 0.5) {
        hasSeasonality = true;
        seasonalityPattern = 'Moderadamente cíclico';
      }
    }
    
    // --- Métricas Finales y Conclusiones ---
    return {
      // Datos básicos
      questionsPerYear: combinedData,
      totalQuestions,
      averageQuestions: Math.round(averageQuestions * 10) / 10,
      years: n,
      
      // Regresión y tendencia
      slope: slope.toFixed(2),
      intercept: intercept.toFixed(2),
      rSquared: (rSquared * 100).toFixed(1),
      rmse: Math.round(rmse * 10) / 10,
      
      // Características de la tendencia
      trendDirection,
      trendStrength,
      
      // Patrones y anomalías
      outliers,
      inflectionPoints,
      hasSeasonality,
      seasonalityPattern,
      
      // Crecimiento y evolución
      growthRates,
      recentGrowth: growthRates.length > 0 ? growthRates[growthRates.length - 1] : null,
      
      // Proyecciones
      forecast,
      nextYearProjection: forecast.length > 0 ? forecast[0].trend : null,
      confidenceRating,
      
      // Datos adicionales
      movingAverage,
      
      // Estado general
      error: false
    };
  }, [trendFilteredQuestions, years, forecastRange]);
  
  // Si no hay datos suficientes, mostrar mensaje apropiado
  if (analysisData.error) {
    return (
      <div className="bg-white p-5 rounded-lg shadow-md">
        <h3 className="text-xl font-bold text-gray-800 mb-4">{title}</h3>
        <div className="p-4 bg-amber-50 border-l-4 border-amber-500 rounded mb-4">
          <div className="flex items-start">
            <AlertTriangle className="text-amber-500 mr-3 mt-1" size={20} />
            <div>
              <p className="text-amber-800 font-medium">{analysisData.message || "No hay datos suficientes para realizar el análisis."}</p>
              {analysisData.totalQuestions !== undefined && (
                <p className="text-amber-700 mt-2">
                  Total de preguntas: {analysisData.totalQuestions || 0} en {analysisData.questionsPerYear?.length || 0} años.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  // Generar texto de análisis con formato natural y detallado
  const generateAnalysisText = () => {
    let analysis = `La tendencia es ${analysisData.trendDirection} con intensidad ${analysisData.trendStrength} `;
    analysis += `(pendiente: ${analysisData.slope}). `;
    
    if (analysisData.rSquared) {
      analysis += `El modelo explica el ${analysisData.rSquared}% de la variación observada. `;
    }
    
    if (analysisData.outliers && analysisData.outliers.length > 0) {
      analysis += `Se detectaron ${analysisData.outliers.length} años con valores atípicos: `;
      analysis += analysisData.outliers.map(o => 
        `${o.yearLabel} (${o.count} preguntas, ${o.outlierType === 'alto' ? 'inusualmente alto' : 'inusualmente bajo'})`
      ).join(', ') + '. ';
    }
    
    if (analysisData.inflectionPoints && analysisData.inflectionPoints.length > 0) {
      analysis += `Se identificaron ${analysisData.inflectionPoints.length} puntos de inflexión significativos en `;
      analysis += analysisData.inflectionPoints.map(p => 
        `${p.yearLabel} (${p.type})`
      ).join(', ') + '. ';
    }
    
    if (analysisData.hasSeasonality) {
      analysis += `Se detectó un patrón cíclico: ${analysisData.seasonalityPattern}. `;
    }
    
    analysis += `Con un nivel de confianza ${analysisData.confidenceRating}, `;
    
    if (analysisData.forecast && analysisData.forecast.length > 0) {
      const nextYear = analysisData.forecast[0];
      analysis += `se proyectan aproximadamente ${nextYear.trend} preguntas para ${nextYear.yearLabel} `;
      analysis += `(intervalo de confianza 95%: ${nextYear.ci.lower}-${nextYear.ci.upper}). `;
      
      if (analysisData.forecast.length > 1) {
        const secondYear = analysisData.forecast[1];
        analysis += `Para ${secondYear.yearLabel} se proyectan ${secondYear.trend} preguntas `;
        analysis += `(intervalo: ${secondYear.ci.lower}-${secondYear.ci.upper}).`;
      }
    }
    
    return analysis;
  };
  
  // Formato para el tooltip personalizado
  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload || payload.length === 0) return null;
    
    const item = payload[0].payload;
    const isForecast = item.isForecast;
    
    return (
      <div className="bg-white p-3 border border-gray-200 rounded-md shadow-md">
        <p className="font-medium text-gray-900 mb-1">{`Año: ${label}`}</p>
        
        {!isForecast && (
          <p className="text-blue-600">{`Preguntas: ${payload[0].value ?? 'N/A'}`}</p>
        )}
        
        <p className="text-red-500">{`Tendencia: ${item.trend ?? 'N/A'}`}</p>
        
        {isForecast && (
          <p className="text-gray-500 text-xs italic mt-1">Proyección</p>
        )}
        
        {item.ci && (
          <p className="text-gray-600 text-xs mt-1">
            {`Intervalo de confianza (95%): ${item.ci.lower}-${item.ci.upper}`}
          </p>
        )}
      </div>
    );
  };
  
  // Renderizar gráfica según el tipo seleccionado
  const renderChart = () => {
    switch (chartType) {
      case 'bar':
        return (
          <BarChart
            data={analysisData.questionsPerYear}
            margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="yearLabel" />
            <YAxis allowDecimals={false} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="count" name="Preguntas" fill="#3B82F6" />
            <Bar dataKey="trend" name="Tendencia" fill="#EF4444" fillOpacity={0.7} />
          </BarChart>
        );
        
      case 'area':
        return (
          <AreaChart
            data={analysisData.questionsPerYear}
            margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
          >
            <defs>
              <linearGradient id="colorCount" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#3B82F6" stopOpacity={0.1}/>
              </linearGradient>
              <linearGradient id="colorTrend" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#EF4444" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#EF4444" stopOpacity={0.1}/>
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="yearLabel" />
            <YAxis allowDecimals={false} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Area type="monotone" dataKey="count" name="Preguntas" stroke="#3B82F6" fillOpacity={1} fill="url(#colorCount)" />
            <Area type="monotone" dataKey="trend" name="Tendencia" stroke="#EF4444" fillOpacity={1} fill="url(#colorTrend)" />
          </AreaChart>
        );
        
      case 'composed':
        return (
          <ComposedChart
            data={analysisData.questionsPerYear}
            margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="yearLabel" />
            <YAxis allowDecimals={false} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="count" name="Preguntas" barSize={20} fill="#3B82F6" />
            <Line type="monotone" dataKey="trend" name="Tendencia" stroke="#EF4444" strokeWidth={2} />
            {showMovingAverage && (
              <Line type="monotone" dataKey="movingAvg" name="Media móvil" stroke="#10B981" strokeWidth={2} strokeDasharray="5 5" />
            )}
          </ComposedChart>
        );
        
      case 'line':
      default:
        return (
          <LineChart
            data={analysisData.questionsPerYear}
            margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="yearLabel" />
            <YAxis 
              allowDecimals={false}
              domain={[0, dataMax => Math.ceil(dataMax * 1.1)]}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            
            {/* Datos históricos */}
            <Line 
              type="monotone" 
              dataKey="count" 
              name="Preguntas" 
              stroke="#3B82F6" 
              activeDot={{ r: 8 }} 
              strokeWidth={2}
              connectNulls
              dot={{ r: 4 }}
            />
            
            {/* Línea de tendencia */}
            <Line 
              type="monotone" 
              dataKey="trend" 
              name="Tendencia" 
              stroke="#EF4444" 
              strokeWidth={2}
              strokeDasharray="5 5"
              dot={false}
            />
            
            {/* Media móvil */}
            {showMovingAverage && (
              <Line 
                type="monotone" 
                dataKey="movingAvg" 
                name="Media móvil" 
                stroke="#10B981" 
                strokeWidth={2}
                dot={false}
              />
            )}
            
            {/* Intervalo de confianza */}
            {showConfidenceInterval && analysisData.questionsPerYear.map((entry, index) => {
              if (!entry.ci) return null;
              return (
                <ReferenceArea 
                  key={`ci-${index}`}
                  x1={entry.yearLabel} 
                  x2={entry.yearLabel}
                  y1={entry.ci.lower} 
                  y2={entry.ci.upper}
                  fill="#3B82F6"
                  fillOpacity={0.1}
                  ifOverflow="visible"
                />
              );
            })}
            
            {/* Marcar área de pronóstico */}
            {analysisData.questionsPerYear.some(item => item.isForecast) && (
              <ReferenceArea
                x1={analysisData.questionsPerYear.find(item => item.isForecast)?.yearLabel}
                x2={analysisData.questionsPerYear[analysisData.questionsPerYear.length - 1].yearLabel}
                fill="#FEF3C7"
                fillOpacity={0.3}
              >
                <Label value="Pronóstico" position="insideTop" />
              </ReferenceArea>
            )}
            
            {/* Marcar outliers */}
            {analysisData.outliers && analysisData.outliers.map((outlier, idx) => (
              <ReferenceLine
                key={`outlier-${idx}`}
                x={outlier.yearLabel}
                stroke={outlier.outlierType === 'alto' ? "#10B981" : "#EF4444"}
                strokeDasharray="3 3"
                strokeWidth={2}
              >
                <Label 
                  value="●" 
                  position="top" 
                  fill={outlier.outlierType === 'alto' ? "#10B981" : "#EF4444"} 
                />
              </ReferenceLine>
            ))}
            
            {/* Marcar puntos de inflexión */}
            {analysisData.inflectionPoints && analysisData.inflectionPoints.map((point, idx) => (
              <ReferenceLine
                key={`inflection-${idx}`}
                x={point.yearLabel}
                stroke="#9333EA"
                strokeWidth={1}
              >
                <Label 
                  value={point.type === 'máximo' ? '▲' : '▼'} 
                  position={point.type === 'máximo' ? 'top' : 'bottom'} 
                  fill="#9333EA" 
                  fontSize={14}
                />
              </ReferenceLine>
            ))}
          </LineChart>
        );
    }
  };
  
  // Iconos para indicar dirección de tendencia
  const TrendIcon = ({ direction, strength }) => {
    if (direction === 'creciente') {
      return <ArrowUpCircle className="text-green-500" size={24} />;
    } else if (direction === 'decreciente') {
      return <ArrowDownCircle className="text-red-500" size={24} />;
    } else {
      return <Minus className="text-yellow-500" size={24} />;
    }
  };
  
  // Componente para mostrar tasa de crecimiento
  const GrowthBadge = ({ value, sign }) => {
    if (value === 'Nueva') {
      return <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded font-medium">Nueva</span>;
    }
    
    const numValue = parseFloat(value);
    if (isNaN(numValue)) {
      return <span className="px-2 py-1 bg-gray-100 text-gray-800 rounded font-medium">N/A</span>;
    }
    
    if (numValue > 0) {
      return (
        <span className="px-2 py-1 bg-green-100 text-green-800 rounded font-medium flex items-center">
          <ArrowUpCircle className="mr-1" size={14} />
          {value}%
        </span>
      );
    } else if (numValue < 0) {
      return (
        <span className="px-2 py-1 bg-red-100 text-red-800 rounded font-medium flex items-center">
          <ArrowDownCircle className="mr-1" size={14} />
          {value}%
        </span>
      );
    } else {
      return (
        <span className="px-2 py-1 bg-gray-100 text-gray-800 rounded font-medium flex items-center">
          <Minus className="mr-1" size={14} />
          0%
        </span>
      );
    }
  };
  
  return (
    <div className="bg-white p-5 rounded-lg shadow-md">
      <div className="flex flex-wrap items-center justify-between mb-4">
        <h3 className="text-xl font-bold text-gray-800">{title}</h3>
        
        {/* Controles de visualización */}
        <div className="flex flex-wrap gap-2 items-center mt-2 sm:mt-0">
          {/* Selector de tipo de gráfico */}
          <div className="flex border rounded overflow-hidden">
            <button 
              onClick={() => setChartType('line')} 
              className={`p-1 px-2 ${chartType === 'line' ? 'bg-blue-500 text-white' : 'bg-gray-100'}`}
              title="Gráfico de líneas"
            >
              <TrendingUp size={18} />
            </button>
            <button 
              onClick={() => setChartType('bar')} 
              className={`p-1 px-2 ${chartType === 'bar' ? 'bg-blue-500 text-white' : 'bg-gray-100'}`}
              title="Gráfico de barras"
            >
              <BarChart2 size={18} />
            </button>
            <button 
              onClick={() => setChartType('area')} 
              className={`p-1 px-2 ${chartType === 'area' ? 'bg-blue-500 text-white' : 'bg-gray-100'}`}
              title="Gráfico de área"
            >
              <Activity size={18} />
            </button>
            <button 
              onClick={() => setChartType('composed')} 
              className={`p-1 px-2 ${chartType === 'composed' ? 'bg-blue-500 text-white' : 'bg-gray-100'}`}
              title="Gráfico combinado"
            >
              <PieChart size={18} />
            </button>
          </div>
          
          {/* Opciones adicionales */}
          <button 
            onClick={() => setShowMovingAverage(!showMovingAverage)}
            className={`p-1 px-2 border rounded ${showMovingAverage ? 'bg-green-500 text-white' : 'bg-gray-100'}`}
            title="Mostrar media móvil"
          >
            <Activity size={18} />
          </button>
          
          <button 
            onClick={() => setShowConfidenceInterval(!showConfidenceInterval)}
            className={`p-1 px-2 border rounded ${showConfidenceInterval ? 'bg-purple-500 text-white' : 'bg-gray-100'}`}
            title="Mostrar intervalo de confianza"
          >
            <Share2 size={18} />
          </button>
          
          {/* Selector de rango de pronóstico */}
          <select
            value={forecastRange}
            onChange={(e) => setForecastRange(parseInt(e.target.value))}
            className="border rounded p-1 text-sm"
            title="Años de pronóstico"
          >
            <option value="1">1 año</option>
            <option value="2">2 años</option>
            <option value="3">3 años</option>
            <option value="5">5 años</option>
          </select>
        </div>
      </div>
      
      {/* Panel de resumen */}
      <div className="p-3 bg-blue-50 border-l-4 border-blue-500 rounded mb-6">
        <div className="flex items-start">
          <Info className="text-blue-500 mr-3 mt-1 flex-shrink-0" size={20} />
          <p className="text-blue-900">{generateAnalysisText()}</p>
        </div>
      </div>
      
      {/* Métricas clave en tarjetas */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        {/* Tarjeta de tendencia */}
        <div className="bg-gray-50 p-4 rounded-lg border shadow-sm">
          <div className="flex items-center justify-between">
            <h4 className="font-semibold text-gray-700 flex items-center">
              <TrendingUp className="mr-2 text-gray-500" size={18} />
              Tendencia
            </h4>
            <TrendIcon direction={analysisData.trendDirection} strength={analysisData.trendStrength} />
          </div>
          
          <p className={`text-2xl font-bold mt-2 ${
            analysisData.trendDirection === 'creciente' ? 'text-green-600' : 
            analysisData.trendDirection === 'decreciente' ? 'text-red-600' : 'text-yellow-600'
          }`}>
            {analysisData.trendDirection.toUpperCase()}
          </p>
          
          <div className="flex flex-col mt-2 text-sm text-gray-500 space-y-1">
            <p>Intensidad: <span className="font-medium">{analysisData.trendStrength}</span></p>
            <p>Pendiente: <span className="font-medium">{analysisData.slope}</span></p>
            <p>R²: <span className="font-medium">{analysisData.rSquared}%</span></p>
          </div>
        </div>
        
        {/* Tarjeta de proyección */}
        <div className="bg-gray-50 p-4 rounded-lg border shadow-sm">
          <div className="flex items-center justify-between">
            <h4 className="font-semibold text-gray-700 flex items-center">
              <Calendar className="mr-2 text-gray-500" size={18} />
              Previsión
            </h4>
            <div className="px-2 py-1 bg-blue-100 text-blue-800 rounded text-xs font-medium">
              Confianza: {analysisData.confidenceRating}
            </div>
          </div>
          
          {analysisData.forecast && analysisData.forecast.length > 0 && (
            <div className="flex items-end mt-2">
              <p className="text-2xl font-bold text-blue-600 mr-2">
                {analysisData.forecast[0].trend}
              </p>
              <p className="text-sm text-gray-500">
                preguntas para {analysisData.forecast[0].yearLabel}
              </p>
            </div>
          )}
          
          <div className="mt-3 text-sm">
            {analysisData.forecast && analysisData.forecast.map((f, idx) => (
              idx > 0 && (
                <div key={idx} className="flex justify-between items-center mb-1">
                  <span className="text-gray-600">{f.yearLabel}:</span>
                  <span className="font-medium text-blue-600">{f.trend}</span>
                </div>
              )
            ))}
          </div>
        </div>
        
        {/* Tarjeta de variación anual */}
        <div className="bg-gray-50 p-4 rounded-lg border shadow-sm">
          <h4 className="font-semibold text-gray-700 mb-3 flex items-center">
            <Activity className="mr-2 text-gray-500" size={18} />
            Variación anual
          </h4>
          
          {analysisData.growthRates && analysisData.growthRates.length > 0 ? (
            <div className="space-y-2">
              {analysisData.growthRates.slice(-3).map((growth, idx) => (
                <div key={idx} className="flex justify-between items-center">
                  <span className="text-gray-700">{growth.yearTo}:</span>
                  <GrowthBadge value={growth.rate} sign={growth.sign} />
                </div>
              ))}
              
              {/* Estadísticas adicionales */}
              <div className="pt-2 mt-2 border-t border-gray-200 text-xs text-gray-500">
                <div className="flex justify-between mb-1">
                  <span>Promedio:</span>
                  <span className="font-medium">{analysisData.averageQuestions} preguntas/año</span>
                </div>
                <div className="flex justify-between">
                  <span>Total preguntas:</span>
                  <span className="font-medium">{analysisData.totalQuestions}</span>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-gray-500">No hay datos suficientes</p>
          )}
        </div>
      </div>
      
      {/* Gráfica principal */}
      <div className="bg-gray-50 p-4 rounded-lg border shadow-sm mb-4">
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            {renderChart()}
          </ResponsiveContainer>
        </div>
      </div>
      
      {/* Sección de detalles avanzados (opcional, podría estar colapsada) */}
      <div className="bg-gray-50 p-4 rounded-lg border shadow-sm">
        <div className="flex items-center justify-between mb-3">
          <h4 className="font-semibold text-gray-700 flex items-center">
            <Sliders className="mr-2 text-gray-500" size={18} />
            Detalles adicionales
          </h4>
          
          <button className="text-blue-500 hover:text-blue-700 text-sm flex items-center">
            <Download className="mr-1" size={14} />
            Exportar datos
          </button>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Patrones y anomalías */}
          <div>
            <h5 className="text-sm font-medium text-gray-700 mb-2">Patrones detectados</h5>
            
            {(analysisData.outliers && analysisData.outliers.length > 0) || 
             (analysisData.inflectionPoints && analysisData.inflectionPoints.length > 0) || 
             analysisData.hasSeasonality ? (
              <ul className="text-sm text-gray-600 space-y-1">
                {analysisData.hasSeasonality && (
                  <li className="flex items-start">
                    <span className="text-purple-500 mr-2">•</span>
                    Patrón cíclico: {analysisData.seasonalityPattern}
                  </li>
                )}
                
                {analysisData.inflectionPoints && analysisData.inflectionPoints.map((point, idx) => (
                  <li key={idx} className="flex items-start">
                    <span className="text-purple-500 mr-2">•</span>
                    Punto de inflexión en {point.yearLabel}: {point.type} (significancia: {point.significance}%)
                  </li>
                ))}
                
                {analysisData.outliers && analysisData.outliers.map((outlier, idx) => (
                  <li key={idx} className="flex items-start">
                    <span className={outlier.outlierType === 'alto' ? "text-green-500 mr-2" : "text-red-500 mr-2"}>•</span>
                    Valor atípico en {outlier.yearLabel}: {outlier.count} preguntas (z-score: {outlier.zScore})
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-gray-500">No se detectaron patrones significativos.</p>
            )}
          </div>
          
          {/* Métricas estadísticas */}
          <div>
            <h5 className="text-sm font-medium text-gray-700 mb-2">Métricas estadísticas</h5>
            
            <div className="grid grid-cols-2 gap-x-4 gap-y-2 text-sm">
              <div className="flex justify-between">
                <span className="text-gray-600">R²:</span>
                <span className="font-medium">{analysisData.rSquared}%</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">RMSE:</span>
                <span className="font-medium">{analysisData.rmse}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Pendiente:</span>
                <span className="font-medium">{analysisData.slope}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Intercepto:</span>
                <span className="font-medium">{analysisData.intercept}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Años analizados:</span>
                <span className="font-medium">{analysisData.years}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Total preguntas:</span>
                <span className="font-medium">{analysisData.totalQuestions}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Funciones para detección de patrones e insights
function detectEmergingTopics(questions, years) {
  if (years.length < 2) return [];
  
  const results = [];
  const latestYear = years[years.length - 1];
  const previousYear = years[years.length - 2];
  
  // Contar temas por año
  const topicsByYear = {};
  questions.forEach(q => {
    if (!topicsByYear[q.year]) topicsByYear[q.year] = {};
    topicsByYear[q.year][q.topic] = (topicsByYear[q.year][q.topic] || 0) + 1;
  });
  
  // Buscar temas nuevos o con crecimiento
  const latestYearTopics = topicsByYear[latestYear] || {};
  const previousYearTopics = topicsByYear[previousYear] || {};
  
  Object.entries(latestYearTopics).forEach(([topic, count]) => {
    const prevCount = previousYearTopics[topic] || 0;
    
    // Tema totalmente nuevo
    if (prevCount === 0 && count >= 3) {
      results.push({
        type: 'emerging',
        icon: '🌱',
        color: 'text-green-600',
        title: 'Tema emergente',
        text: `"${topic}" apareció por primera vez con ${count} preguntas en ${latestYear}.`
      });
    } 
    // Tema con crecimiento significativo
    else if (prevCount > 0 && count >= prevCount * 1.5 && (count - prevCount) >= 5) {
      const growthPercent = Math.round((count - prevCount) / prevCount * 100);
      results.push({
        type: 'growth',
        icon: '📈',
        color: 'text-blue-600',
        title: 'Crecimiento significativo',
        text: `"${topic}" creció un ${growthPercent}% (de ${prevCount} a ${count} preguntas).`,
        data: { topic, growthPercent, year: latestYear }
      });
    }
  });
  
  return results;
}

function detectCyclicalPatterns(questions, years) {
  if (years.length < 4) return [];
  
  // Agrupar preguntas por tema y año
  const topicTrendsByYear = {};
  
  questions.forEach(q => {
    if (!topicTrendsByYear[q.topic]) {
      topicTrendsByYear[q.topic] = {};
    }
    topicTrendsByYear[q.topic][q.year] = (topicTrendsByYear[q.topic][q.year] || 0) + 1;
  });
  
  const results = [];
  const patterns = [];
  
  // Buscar patrones de subida y bajada alternantes
  Object.entries(topicTrendsByYear).forEach(([topic, yearCounts]) => {
    if (Object.keys(yearCounts).length < 4) return;
    
    // Convertir a series temporales
    const timeSeries = years.map(year => ({
      year,
      count: yearCounts[year] || 0
    }));
    
    // Detectar alternancia
    let alternatingCount = 0;
    let direction = null;
    
    for (let i = 1; i < timeSeries.length; i++) {
      const prev = timeSeries[i-1].count;
      const current = timeSeries[i].count;
      
      if (prev === current) continue;
      
      const currentDirection = current > prev;
      
      if (direction === null) {
        direction = currentDirection;
      } else if (direction !== currentDirection) {
        alternatingCount++;
        direction = currentDirection;
      }
    }
    
    // Si hay suficientes alternancias, considerar patrón cíclico
    if (alternatingCount >= 2) {
      patterns.push({
        topic,
        alternatingCount,
        yearCounts: timeSeries
      });
    }
  });
  
  // Ordenar y tomar los más significativos
  patterns.sort((a, b) => b.alternatingCount - a.alternatingCount);
  
  patterns.slice(0, 2).forEach(pattern => {
    results.push({
      type: 'cyclical',
      icon: '🔄',
      color: 'text-purple-600',
      title: 'Patrón cíclico detectado',
      text: `"${pattern.topic}" muestra un patrón alternante entre subidas y bajadas.`,
      data: pattern
    });
  });
  
  return results;
}

const InteractiveDashboard = ({ allQuestions, trendFilteredQuestions, years }) => {
  // Estado para configuraciones del dashboard
  const [activeTab, setActiveTab] = useState('overview');
  const [selectedMetric, setSelectedMetric] = useState('count');
  const [selectedViewMode, setSelectedViewMode] = useState('yearly');
  const [selectedYear, setSelectedYear] = useState(years.length > 0 ? years[years.length - 1] : '');
  const [highlightedTopic, setHighlightedTopic] = useState(null);
  const [insightsPanelOpen, setInsightsPanelOpen] = useState(true);
  const [patternDetectionActive, setPatternDetectionActive] = useState(true);
  
  // Colores para visualizaciones
  const colors = [
    "#2563EB", "#059669", "#DC2626", "#8B5CF6", "#D97706", 
    "#DB2777", "#7C3AED", "#F59E0B", "#4F46E5", "#10B981"
  ];
  
  // Cálculo de métricas generales
  const metrics = useMemo(() => {
    const totalQuestions = trendFilteredQuestions.length;
    const topicsCount = new Set(trendFilteredQuestions.map(q => q.topic)).size;
    const subtopicsCount = new Set(trendFilteredQuestions.map(q => q.subtopic)).size;
    
    // Promedio de preguntas por año
    const avgQuestionsPerYear = totalQuestions / years.length;
    
    // Año con más preguntas
    const questionsByYear = years.map(year => ({
      year,
      count: trendFilteredQuestions.filter(q => q.year === year).length
    }));
    const peakYear = questionsByYear.reduce((max, current) => 
      current.count > max.count ? current : max, { count: 0 }).year;
    
    // Tema más frecuente
    const topicCounts = {};
    trendFilteredQuestions.forEach(q => {
      topicCounts[q.topic] = (topicCounts[q.topic] || 0) + 1;
    });
    const topTopic = Object.entries(topicCounts)
      .sort((a, b) => b[1] - a[1])
      .shift();
    
    return {
      totalQuestions,
      topicsCount,
      subtopicsCount,
      avgQuestionsPerYear: Math.round(avgQuestionsPerYear),
      peakYear,
      topTopic: topTopic ? topTopic[0] : 'N/A',
      topTopicCount: topTopic ? topTopic[1] : 0
    };
  }, [trendFilteredQuestions, years]);
  
  // Detección automática de patrones e insights
  const insights = useMemo(() => {
    if (!patternDetectionActive) return [];
    
    const allInsights = [];
    
    // 1. Detectar temas emergentes (nuevos o con crecimiento significativo)
    const emergingTopics = detectEmergingTopics(trendFilteredQuestions, years);
    
    // 2. Identificar patrones cíclicos
    const cyclicalPatterns = detectCyclicalPatterns(trendFilteredQuestions, years);
    
    return [
      ...emergingTopics, 
      ...cyclicalPatterns
    ];
  }, [trendFilteredQuestions, years, patternDetectionActive]);
  
  // Componente auxiliar para tarjetas de métricas
  const MetricCard = ({ title, value, icon, color, subtext }) => (
    <div className="bg-white rounded-lg shadow p-4 relative overflow-hidden">
      <div className={`absolute top-0 right-0 w-16 h-16 rounded-bl-full ${color} opacity-20`}></div>
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-gray-500 text-sm font-medium">{title}</h3>
          <p className="text-2xl font-bold mt-1">{value}</p>
          {subtext && <p className="text-xs text-gray-500 mt-1">{subtext}</p>}
        </div>
        <div className="text-2xl">{icon}</div>
      </div>
    </div>
  );
  
  // 1. Vista general
  const OverviewTab = () => {
    // Datos para el gráfico principal de tendencia
    const yearlyData = years.map(year => ({
      year,
      total: trendFilteredQuestions.filter(q => q.year === year).length
    }));
    
    // Distribución de temas para el año seleccionado
    const topicsForSelectedYear = useMemo(() => {
      const topicCounts = {};
      
      trendFilteredQuestions
        .filter(q => q.year === selectedYear)
        .forEach(q => {
          topicCounts[q.topic] = (topicCounts[q.topic] || 0) + 1;
        });
      
      return Object.entries(topicCounts)
        .map(([topic, count]) => ({ topic, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 6);
    }, [trendFilteredQuestions, selectedYear]);
    
    return (
      <div className="space-y-8">
        {/* Tarjetas de métricas clave */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <MetricCard
            title="Total Preguntas"
            value={metrics.totalQuestions}
            icon="📊"
            color="bg-blue-500"
          />
          <MetricCard
            title="Temas Cubiertos"
            value={metrics.topicsCount}
            icon="📚"
            color="bg-green-500"
          />
          <MetricCard
            title="Prom. Preguntas/Año"
            value={metrics.avgQuestionsPerYear}
            icon="📅"
            color="bg-purple-500"
          />
          <MetricCard
            title="Tema Principal"
            value={metrics.topTopic}
            icon="🏆"
            color="bg-amber-500"
            subtext={`${metrics.topTopicCount} preguntas`}
          />
        </div>
        
        {/* Gráfico principal de evolución */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-2 bg-white p-4 rounded-lg shadow">
            <h3 className="text-lg font-bold mb-4">Evolución Anual</h3>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={yearlyData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis allowDecimals={false} />
                  <Tooltip formatter={(value) => [`${value} preguntas`, 'Total']} />
                  <Legend />
                  <Line 
                    type="monotone" 
                    dataKey="total" 
                    stroke="#3182CE" 
                    activeDot={{ r: 8 }} 
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          
          <div className="bg-white p-4 rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">Distribución por Tema</h3>
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                className="text-sm border rounded p-1"
              >
                {years.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
            
            <div className="h-64">
              {topicsForSelectedYear.length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={topicsForSelectedYear}
                      dataKey="count"
                      nameKey="topic"
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      label={(entry) => entry.topic}
                      labelLine={false}
                    >
                      {topicsForSelectedYear.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value, name, props) => [`${value} preguntas`, props.payload.topic]} />
                  </PieChart>
                </ResponsiveContainer>
              ) : (
                <div className="h-full flex items-center justify-center text-gray-500">
                  No hay datos para {selectedYear}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <div className="bg-gray-100 p-4 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Dashboard Analítico</h2>
        
        {/* Botón para panel de insights */}
        <button
          onClick={() => setInsightsPanelOpen(!insightsPanelOpen)}
          className="text-sm bg-blue-600 text-white px-3 py-1 rounded"
        >
          {insightsPanelOpen ? 'Ocultar Insights' : 'Mostrar Insights'}
        </button>
      </div>
      
      {/* Panel de insights rápidos (opcional) */}
      <AnimatePresence>
        {insightsPanelOpen && insights.length > 0 && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="mb-4 overflow-hidden"
          >
            <div className="bg-gradient-to-r from-blue-50 to-purple-50 p-3 rounded-lg border border-blue-100">
              <h3 className="text-sm font-semibold text-blue-800 mb-2">Insights Clave:</h3>
              <div className="flex overflow-x-auto space-x-4 pb-2">
                {insights.slice(0, 3).map((insight, index) => (
                  <div key={index} className="flex-shrink-0 max-w-xs bg-white p-2 rounded shadow-sm">
                    <div className="flex items-center">
                      <span className="text-xl mr-2">{insight.icon}</span>
                      <p className="text-sm text-gray-700">{insight.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      
      {/* Contenido principal - Vista general */}
      <div>
        <OverviewTab />
      </div>
    </div>
  );
};

const ExportComponent = ({ trendFilteredQuestions, trendGroupedData, years }) => {
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [exportFormat, setExportFormat] = useState('csv');
  const [exportContent, setExportContent] = useState(['data']);
  const [includeCharts, setIncludeCharts] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [exportReady, setExportReady] = useState(false);
  const [exportPreview, setExportPreview] = useState(null);
  
  const dashboardRef = useRef(null);
  
  // Función para exportar datos en CSV
  const exportCSV = () => {
    // Determinar qué datos exportar
    let dataToExport = [];
    
    if (exportContent.includes('data')) {
      // Exportar datos agrupados por tema/subtema
      dataToExport = trendGroupedData.map(row => {
        const baseRow = {
          Tema: row.topic,
          Subtema: row.subtopic || '',
          'Subtema Específico': row.subtopic_specific || '',
          Total: row.total
        };
        
        // Añadir columnas para cada año
        years.forEach(year => {
          baseRow[`Año ${year}`] = row.counts[year] || 0;
        });
        
        return baseRow;
      });
    } else if (exportContent.includes('raw')) {
      // Exportar datos sin procesar
      dataToExport = trendFilteredQuestions.map(q => ({
        Año: q.year,
        Tema: q.topic,
        Subtema: q.subtopic,
        'Subtema Específico': q.subtopic_specific || '',
        Pregunta: q.text || '',
        ID: q.id || ''
      }));
    }
    
    // Convertir a CSV
    const csv = Papa.unparse(dataToExport);
    
    // Crear blob y descargarlo
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `analisis_tendencia_${new Date().toISOString().slice(0, 10)}.csv`);
    
    // Marcar como completado
    setExportReady(true);
    setTimeout(() => setExportReady(false), 3000);
  };
  
  // Función para exportar como PDF (simulado con imagen)
  const exportPDF = async () => {
    setIsGenerating(true);
    
    try {
      // Capturar el contenido como imagen
      if (!dashboardRef.current) {
        throw new Error('No se pudo encontrar el elemento a exportar');
      }
      
      const canvas = await html2canvas(dashboardRef.current, {
        scale: 2,
        useCORS: true,
        logging: false
      });
      
      // Convertir a imagen
      const imageData = canvas.toDataURL('image/png');
      setExportPreview(imageData);
      
      // Opción para descargar como imagen
      saveAs(imageData, `analisis_tendencia_${new Date().toISOString().slice(0, 10)}.png`);
      
      // Marcar como completado
      setExportReady(true);
      setTimeout(() => setExportReady(false), 3000);
    } catch (error) {
      console.error('Error al generar la exportación:', error);
      alert('Ocurrió un error al generar la exportación. Por favor, intente nuevamente.');
    } finally {
      setIsGenerating(false);
    }
  };
  
  // Función principal de exportación
  const handleExport = () => {
    if (exportFormat === 'csv') {
      exportCSV();
    } else if (exportFormat === 'pdf') {
      exportPDF();
    }
  };
  
  // Para generar un enlace compartible (simulado)
  const generateShareLink = () => {
    // Aquí se generaría un enlace real basado en los filtros actuales
    // Por ahora simulamos la generación de un enlace
    const dummyLink = `https://app.example.com/share/${Math.random().toString(36).substring(2, 10)}`;
    
    // Copiar al portapapeles
    navigator.clipboard.writeText(dummyLink).then(() => {
      alert('Enlace copiado al portapapeles: ' + dummyLink);
    });
  };
  
  return (
    <div className="mb-6">
      <div className="flex justify-end mb-4">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setIsExportOpen(!isExportOpen)}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition flex items-center"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-5 w-5 mr-2" 
            viewBox="0 0 20 20" 
            fill="currentColor"
          >
            <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
          Exportar/Compartir Análisis
        </motion.button>
      </div>
      
      {isExportOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="bg-white rounded-lg shadow-lg p-6 mb-6"
        >
          <h3 className="text-xl font-bold text-gray-800 mb-4">Exportar Análisis</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h4 className="font-semibold mb-2">Opciones de Exportación</h4>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Formato de Exportación
                  </label>
                  <div className="flex space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="exportFormat"
                        value="csv"
                        checked={exportFormat === 'csv'}
                        onChange={() => setExportFormat('csv')}
                      />
                      <span className="ml-2">CSV (Excel)</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="exportFormat"
                        value="pdf"
                        checked={exportFormat === 'pdf'}
                        onChange={() => setExportFormat('pdf')}
                      />
                      <span className="ml-2">PDF/Imagen</span>
                    </label>
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Contenido a Exportar
                  </label>
                  <div className="space-y-2">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        checked={exportContent.includes('data')}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setExportContent([...exportContent, 'data']);
                          } else {
                            setExportContent(exportContent.filter(item => item !== 'data'));
                          }
                        }}
                      />
                      <span className="ml-2">Datos agrupados (por tema/subtema)</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        checked={exportContent.includes('raw')}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setExportContent([...exportContent, 'raw']);
                          } else {
                            setExportContent(exportContent.filter(item => item !== 'raw'));
                          }
                        }}
                      />
                      <span className="ml-2">Datos individuales (preguntas)</span>
                    </label>
                  </div>
                </div>
                
                {exportFormat === 'pdf' && (
                  <div>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        checked={includeCharts}
                        onChange={(e) => setIncludeCharts(e.target.checked)}
                      />
                      <span className="ml-2">Incluir gráficos y visualizaciones</span>
                    </label>
                  </div>
                )}
              </div>
              
              <div className="mt-6 flex space-x-4">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleExport}
                  disabled={exportContent.length === 0 || isGenerating}
                  className={`px-4 py-2 rounded-md text-white ${
                    exportContent.length === 0 || isGenerating
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700'
                  }`}
                >
                  {isGenerating ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Generando...
                    </>
                  ) : (
                    'Exportar'
                  )}
                </motion.button>
                
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={generateShareLink}
                  className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                >
                  Generar Enlace Compartible
                </motion.button>
              </div>
              
              {exportReady && (
                <div className="mt-4 p-2 bg-green-100 text-green-800 rounded">
                  ✓ Exportación completada con éxito
                </div>
              )}
            </div>
            
            <div>
              <h4 className="font-semibold mb-2">Vista Previa</h4>
              <div className="border rounded-md bg-gray-50 p-4 h-64 overflow-auto">
                {exportPreview ? (
                  <img 
                    src={exportPreview} 
                    alt="Vista previa de exportación" 
                    className="w-full h-auto" 
                  />
                ) : (
                  <div className="text-center text-gray-500 mt-16">
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-12 w-12 mx-auto mb-2" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    {exportFormat === 'pdf' 
                      ? 'Haz clic en "Exportar" para generar una vista previa' 
                      : 'Los datos se exportarán en formato CSV'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
      
      {/* Elemento contenedor (ref) para la captura */}
      <div ref={dashboardRef} style={{ display: 'none' }}>
        {/* Aquí irían los componentes que queremos capturar para exportación */}
        <div className="p-4 bg-white">
          <h2 className="text-xl font-bold mb-4">Análisis de Tendencia</h2>
          
          <div className="mb-4">
            <h3 className="font-semibold">Resumen de Filtros Aplicados:</h3>
            <p className="text-sm text-gray-600">
              {trendFilteredQuestions.length} preguntas | {years.length} años analizados
            </p>
          </div>
          
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2">Tema</th>
                <th className="border border-gray-300 px-4 py-2">Subtema</th>
                <th className="border border-gray-300 px-4 py-2">Subtema Específico</th>
                {years.map((year) => (
                  <th key={year} className="border border-gray-300 px-4 py-2">{year}</th>
                ))}
                <th className="border border-gray-300 px-4 py-2">Total</th>
              </tr>
            </thead>
            <tbody>
              {trendGroupedData.slice(0, 20).map((row, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="border border-gray-300 px-4 py-2">{row.topic}</td>
                  <td className="border border-gray-300 px-4 py-2">{row.subtopic}</td>
                  <td className="border border-gray-300 px-4 py-2">{row.subtopic_specific}</td>
                  {years.map((year) => (
                    <td key={year} className="border border-gray-300 px-4 py-2 text-center">
                      {row.counts[year] || 0}
                    </td>
                  ))}
                  <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                    {row.total}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          
          <div className="mt-4 text-sm text-gray-500 text-right">
            Generado el {new Date().toLocaleDateString()} - Análisis de Tendencia
          </div>
        </div>
      </div>
    </div>
  );
};

function GenerateExam({ allQuestions, onStartExam, onBack, darkMode }) {
  // ============================
  // Estados para el examen
  // ============================
  const [examTitle, setExamTitle] = useState("");
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedEspecialidades, setSelectedEspecialidades] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedSubtopics, setSelectedSubtopics] = useState([]);
  // NUEVO: filtro para subtopic_specific
  const [selectedSubtopicsSpecific, setSelectedSubtopicsSpecific] = useState([]);

  // Valor por defecto 50
  const [numPreguntas, setNumPreguntas] = useState(50);

  // Valor por defecto 60
  const [tiempo, setTiempo] = useState(60);

  // Modo de examen
  const [examMode, setExamMode] = useState("study");

  // ============================
  // Estados para Análisis de Tendencia
  // ============================
  const [showTrendAnalysis, setShowTrendAnalysis] = useState(false);
  const [trendAreaFilter, setTrendAreaFilter] = useState([]);
  const [trendTopicFilter, setTrendTopicFilter] = useState([]);
  const [trendSubtopicFilter, setTrendSubtopicFilter] = useState([]);
  // NUEVO: filtro para subtopic_specific en el análisis de tendencia
  const [trendSubtopicSpecificFilter, setTrendSubtopicSpecificFilter] = useState([]);

  // NUEVO: filtro por año en el análisis de tendencia
  const [trendYearFilter, setTrendYearFilter] = useState([]);

  // Vista para el análisis
  const [trendView, setTrendView] = useState("table");
  const [selectedTrendGroup, setSelectedTrendGroup] = useState(null);
  const [selectedBarData, setSelectedBarData] = useState(null);
  const [subtopicsSortOrder, setSubtopicsSortOrder] = useState('desc');
  const [subtopicsChartType, setSubtopicsChartType] = useState('bar');

  // NUEVO: agrupar solo por Subtema (ignorando Subtema específico)
  const [trendGroupBySubtopicOnly, setTrendGroupBySubtopicOnly] = useState(false);
  
  // NUEVO: opciones de visualización para totales
  const [trendDisplayOptions, setTrendDisplayOptions] = useState({
    showTopicTotals: true,
    showSubtopicTotals: true
  });
  
  // NUEVO: sección activa para análisis avanzado
  const [activeTrendAnalysisSection, setActiveTrendAnalysisSection] = useState(null);
  
  // Persistencia del estado
useEffect(() => {
  try {
    const savedState = localStorage.getItem('generateExamState');
    if (savedState) {
      const state = JSON.parse(savedState);
      
      // Restaurar todos los estados relevantes
      if (state.examTitle !== undefined) setExamTitle(state.examTitle);
      if (state.selectedYears) setSelectedYears(state.selectedYears);
      if (state.selectedEspecialidades) setSelectedEspecialidades(state.selectedEspecialidades);
      if (state.selectedAreas) setSelectedAreas(state.selectedAreas);
      if (state.selectedTopics) setSelectedTopics(state.selectedTopics);
      if (state.selectedSubtopics) setSelectedSubtopics(state.selectedSubtopics);
      if (state.selectedSubtopicsSpecific) setSelectedSubtopicsSpecific(state.selectedSubtopicsSpecific);
      if (state.numPreguntas) setNumPreguntas(state.numPreguntas);
      if (state.tiempo) setTiempo(state.tiempo);
      if (state.examMode) setExamMode(state.examMode);
      
      // Restaurar estado del análisis de tendencia
      if (state.showTrendAnalysis !== undefined) setShowTrendAnalysis(state.showTrendAnalysis);
      if (state.trendAreaFilter) setTrendAreaFilter(state.trendAreaFilter);
      if (state.trendTopicFilter) setTrendTopicFilter(state.trendTopicFilter);
      if (state.trendSubtopicFilter) setTrendSubtopicFilter(state.trendSubtopicFilter);
      if (state.trendSubtopicSpecificFilter) setTrendSubtopicSpecificFilter(state.trendSubtopicSpecificFilter);
      if (state.trendYearFilter) setTrendYearFilter(state.trendYearFilter);
      if (state.trendView) setTrendView(state.trendView);
      if (state.trendGroupBySubtopicOnly !== undefined) setTrendGroupBySubtopicOnly(state.trendGroupBySubtopicOnly);
      if (state.trendDisplayOptions) setTrendDisplayOptions(state.trendDisplayOptions);
      if (state.activeTrendAnalysisSection) setActiveTrendAnalysisSection(state.activeTrendAnalysisSection);
    }
  } catch (error) {
    console.error("Error al restaurar el estado del generador:", error);
    localStorage.removeItem('generateExamState');
  }
}, []);

// Guardar estado actual
useEffect(() => {
  const state = {
    examTitle,
    selectedYears,
    selectedEspecialidades,
    selectedAreas,
    selectedTopics,
    selectedSubtopics,
    selectedSubtopicsSpecific,
    numPreguntas,
    tiempo,
    examMode,
    showTrendAnalysis,
    trendAreaFilter,
    trendTopicFilter,
    trendSubtopicFilter,
    trendSubtopicSpecificFilter,
    trendYearFilter,
    trendView,
    trendGroupBySubtopicOnly,
    trendDisplayOptions,
    activeTrendAnalysisSection
  };
  localStorage.setItem('generateExamState', JSON.stringify(state));
}, [
  examTitle,
  selectedYears,
  selectedEspecialidades,
  selectedAreas,
  selectedTopics,
  selectedSubtopics,
  selectedSubtopicsSpecific,
  numPreguntas,
  tiempo,
  examMode,
  showTrendAnalysis,
  trendAreaFilter,
  trendTopicFilter,
  trendSubtopicFilter,
  trendSubtopicSpecificFilter,
  trendYearFilter,
  trendView,
  trendGroupBySubtopicOnly,
  trendDisplayOptions,
  activeTrendAnalysisSection
]);

// AGREGAR TAMBIÉN: Control para el modo oscuro
const [initialDarkModeSet, setInitialDarkModeSet] = useState(false);

useEffect(() => {
  // Primera carga, solo marcamos que hemos capturado el estado inicial
  if (!initialDarkModeSet) {
    setInitialDarkModeSet(true);
    return;
  }
  
  // En cambios posteriores de darkMode, no hacemos nada que afecte la navegación
  // Esto evita que se resetee a la vista inicial al cambiar el modo oscuro
}, [darkMode]);

  // Colores para gráficos
  const barColors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#A133FF",
    "#33FFF2",
    "#FF8F33",
    "#8F33FF"
  ];

  // ============================
  // Opciones únicas para filtros del examen
  // ============================
  const uniqueYears = useMemo(
    () => Array.from(new Set(allQuestions.map((q) => q.year))).sort(),
    [allQuestions]
  );
  const uniqueEspecialidades = useMemo(
    () => Array.from(new Set(allQuestions.map((q) => q.especialidad))).sort(),
    [allQuestions]
  );
  const uniqueAreas = useMemo(
    () => Array.from(new Set(allQuestions.map((q) => q.area))).sort(),
    [allQuestions]
  );

  // ============================
  // Filtrado base (por Año, Especialidad y Área)
  // ============================
  const filteredBase = useMemo(() => {
    return allQuestions.filter((q) => {
      if (selectedYears.length > 0 && !selectedYears.includes(q.year)) return false;
      if (selectedEspecialidades.length > 0 && !selectedEspecialidades.includes(q.especialidad)) return false;
      if (selectedAreas.length > 0 && !selectedAreas.includes(q.area)) return false;
      return true;
    });
  }, [allQuestions, selectedYears, selectedEspecialidades, selectedAreas]);

  // ============================
  // Cálculo y ordenamiento de Temas (con contador)
  // ============================
  const topicsWithCount = useMemo(() => {
    const counts = {};
    filteredBase.forEach((q) => {
      const topic = q.topic;
      counts[topic] = (counts[topic] || 0) + 1;
    });
    const arr = Object.keys(counts).map((topic) => ({ topic, count: counts[topic] }));
    // Orden: primero desc por count, luego alfabético por tema
    arr.sort((a, b) => b.count - a.count || a.topic.localeCompare(b.topic));
    return arr;
  }, [filteredBase]);

  const availableTopics = useMemo(() => topicsWithCount.map((item) => item.topic), [topicsWithCount]);

  useEffect(() => {
    setSelectedTopics((prev) => prev.filter((tp) => availableTopics.includes(tp)));
  }, [availableTopics]);

  // ============================
  // Filtrado para Subtemas según los Temas seleccionados
  // ============================
  const filteredForSubtopics = useMemo(() => {
    let data = filteredBase;
    if (selectedTopics.length > 0) {
      data = data.filter((q) => selectedTopics.includes(q.topic));
    }
    return data;
  }, [filteredBase, selectedTopics]);

  // Cálculo y ordenamiento de Subtemas (con contador)
  const subtopicsWithCount = useMemo(() => {
    const counts = {};
    filteredForSubtopics.forEach((q) => {
      const subtopic = q.subtopic;
      counts[subtopic] = (counts[subtopic] || 0) + 1;
    });
    const arr = Object.keys(counts).map((subtopic) => ({ subtopic, count: counts[subtopic] }));
    // Orden: primero desc por count, luego alfabético por subtema
    arr.sort((a, b) => b.count - a.count || a.subtopic.localeCompare(b.subtopic));
    return arr;
  }, [filteredForSubtopics]);

  const availableSubtopics = useMemo(
    () => subtopicsWithCount.map((item) => item.subtopic),
    [subtopicsWithCount]
  );

  useEffect(() => {
    setSelectedSubtopics((prev) => prev.filter((st) => availableSubtopics.includes(st)));
  }, [availableSubtopics]);

  // ============================
  // Filtrado para Subtema Específico (subtopic_specific)
  // ============================
  const filteredForSubtopicsSpecific = useMemo(() => {
    let data = filteredForSubtopics;
    if (selectedSubtopics.length > 0) {
      data = data.filter((q) => selectedSubtopics.includes(q.subtopic));
    }
    return data;
  }, [filteredForSubtopics, selectedSubtopics]);

  const subtopicsSpecificWithCount = useMemo(() => {
    const counts = {};
    filteredForSubtopicsSpecific.forEach((q) => {
      const sps = q.subtopic_specific || "Sin especificar";
      counts[sps] = (counts[sps] || 0) + 1;
    });
    const arr = Object.keys(counts).map((sps) => ({
      subtopic_specific: sps,
      count: counts[sps]
    }));
    // Orden: desc por count, luego alfabético
    arr.sort((a, b) => b.count - a.count || a.subtopic_specific.localeCompare(b.subtopic_specific));
    return arr;
  }, [filteredForSubtopicsSpecific]);

  const availableSubtopicsSpecific = useMemo(
    () => subtopicsSpecificWithCount.map((item) => item.subtopic_specific),
    [subtopicsSpecificWithCount]
  );

  useEffect(() => {
    setSelectedSubtopicsSpecific((prev) =>
      prev.filter((sps) => availableSubtopicsSpecific.includes(sps))
    );
  }, [availableSubtopicsSpecific]);

  // ============================
  // Filtrado final de preguntas (aplicando TODOS los filtros)
  // ============================
  const filteredQuestions = useMemo(() => {
    return allQuestions.filter((q) => {
      if (selectedYears.length > 0 && !selectedYears.includes(q.year)) return false;
      if (selectedEspecialidades.length > 0 && !selectedEspecialidades.includes(q.especialidad)) return false;
      if (selectedAreas.length > 0 && !selectedAreas.includes(q.area)) return false;
      if (selectedTopics.length > 0 && !selectedTopics.includes(q.topic)) return false;
      if (selectedSubtopics.length > 0 && !selectedSubtopics.includes(q.subtopic)) return false;
      // Nuevo filtro: subtopic_specific
      if (selectedSubtopicsSpecific.length > 0 && !selectedSubtopicsSpecific.includes(q.subtopic_specific)) {
        return false;
      }
      return true;
    });
  }, [
    allQuestions,
    selectedYears,
    selectedEspecialidades,
    selectedAreas,
    selectedTopics,
    selectedSubtopics,
    selectedSubtopicsSpecific
  ]);

  // ============================
  // Función para alternar selección en checkboxes
  // ============================
  const toggleSelected = (value, selectedArray, setSelected) => {
    setSelected((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  // ============================
  // Handlers para dropdowns
  // ============================
  const handleTopicsChange = (e) => {
    const options = e.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) selected.push(options[i].value);
    }
    setSelectedTopics(selected);
  };

  const handleSubtopicsChange = (e) => {
    const options = e.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) selected.push(options[i].value);
    }
    setSelectedSubtopics(selected);
  };

  // Nuevo handler para subtopic_specific
  const handleSubtopicsSpecificChange = (e) => {
    const options = e.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) selected.push(options[i].value);
    }
    setSelectedSubtopicsSpecific(selected);
  };

  // ============================
  // Función para limpiar filtros del examen
  // ============================
  const resetFilters = () => {
    setSelectedYears([]);
    setSelectedEspecialidades([]);
    setSelectedAreas([]);
    setSelectedTopics([]);
    setSelectedSubtopics([]);
    setSelectedSubtopicsSpecific([]); // Limpiar también subtopic_specific
  };

  // ============================
  // Función para limpiar filtros del Análisis de Tendencia
  // ============================
  const resetTrendFilters = () => {
    setTrendAreaFilter([]);
    setTrendTopicFilter([]);
    setTrendSubtopicFilter([]);
    setTrendSubtopicSpecificFilter([]);
    setTrendYearFilter([]);
    setTrendGroupBySubtopicOnly(false); // Resetear agrupación
    // Restaurar opciones de visualización a sus valores por defecto
    setTrendDisplayOptions({
      showTopicTotals: true,
      showSubtopicTotals: true
    });
  };

  // ============================
  // Handler para iniciar el examen
  // ============================
  const handleStart = () => {
    const limit = Math.min(numPreguntas, 200);
    let finalSelection;
    if (examMode === "simulation") {
      // En modo simulacro se barajan las preguntas
      const shuffled = [...filteredQuestions].sort(() => Math.random() - 0.5);
      finalSelection = shuffled.slice(0, limit);
    } else {
      // En modo estudio se mantienen en el orden filtrado
      finalSelection = filteredQuestions.slice(0, limit);
    }
    // Llamamos al callback pasando examTitle
    onStartExam(
      finalSelection,
      examMode === "simulation" ? tiempo : null,
      examMode,
      examTitle
    );
  };

  // ============================
  // Opciones para Análisis de Tendencia
  // ============================
  const uniqueTrendAreas = useMemo(
    () => Array.from(new Set(allQuestions.map((q) => q.area))).sort(),
    [allQuestions]
  );
  const uniqueTrendTopics = useMemo(
    () => Array.from(new Set(allQuestions.map((q) => q.topic))).sort(),
    [allQuestions]
  );
  const uniqueTrendSubtopics = useMemo(
    () => Array.from(new Set(allQuestions.map((q) => q.subtopic))).sort(),
    [allQuestions]
  );
  // Nuevo: lista única de subtopic_specific
  const uniqueTrendSubtopicsSpecific = useMemo(
    () => Array.from(new Set(allQuestions.map((q) => q.subtopic_specific))).sort(),
    [allQuestions]
  );
  // NUEVO: lista única de años para el análisis de tendencia
  const uniqueTrendYears = useMemo(
    () => Array.from(new Set(allQuestions.map((q) => q.year))).sort(),
    [allQuestions]
  );

  // Filtrado para el análisis de tendencia
  const trendFilteredQuestions = useMemo(() => {
    return allQuestions.filter((q) => {
      if (trendAreaFilter.length > 0 && !trendAreaFilter.includes(q.area)) return false;
      if (trendTopicFilter.length > 0 && !trendTopicFilter.includes(q.topic)) return false;
      if (trendSubtopicFilter.length > 0 && !trendSubtopicFilter.includes(q.subtopic)) return false;
      if (trendSubtopicSpecificFilter.length > 0 && !trendSubtopicSpecificFilter.includes(q.subtopic_specific)) {
        return false;
      }
      if (trendYearFilter.length > 0 && !trendYearFilter.includes(q.year)) return false;
      return true;
    });
  }, [
    allQuestions,
    trendAreaFilter,
    trendTopicFilter,
    trendSubtopicFilter,
    trendSubtopicSpecificFilter,
    trendYearFilter
  ]);

  // Lista de años presentes en el análisis (tras filtrar)
  const trendYears = useMemo(() => {
    return Array.from(new Set(trendFilteredQuestions.map((q) => q.year))).sort();
  }, [trendFilteredQuestions]);

  // ============================
  // Agrupación (Tendencia) ACTUALIZADA para mostrar totales por tema y subtema
  // ============================
  const trendGroupedData = useMemo(() => {
    // Track topics and their totals
    const topicTotals = {};
    const subtopicTotals = {};
    const groups = {};
    
    trendFilteredQuestions.forEach((q) => {
      const { topic, subtopic, subtopic_specific = "Sin especificar", year } = q;
      
      // Add to topic totals
      if (!topicTotals[topic]) {
        topicTotals[topic] = {
          topic,
          isTopic: true,  // Flag to identify topic rows
          counts: {},
          total: 0
        };
      }
      topicTotals[topic].counts[year] = (topicTotals[topic].counts[year] || 0) + 1;
      topicTotals[topic].total += 1;
      
      // Add to subtopic totals (within a topic)
      const subtopicKey = `${topic}||${subtopic}`;
      if (!subtopicTotals[subtopicKey]) {
        subtopicTotals[subtopicKey] = {
          topic,
          subtopic,
          isSubtopic: true,  // Flag to identify subtopic rows
          counts: {},
          total: 0
        };
      }
      subtopicTotals[subtopicKey].counts[year] = (subtopicTotals[subtopicKey].counts[year] || 0) + 1;
      subtopicTotals[subtopicKey].total += 1;

      // Create the regular detail rows as before
      const key = trendGroupBySubtopicOnly
        ? `${topic}||${subtopic}`
        : `${topic}||${subtopic}||${subtopic_specific}`;

      if (!groups[key]) {
        groups[key] = {
          topic,
          subtopic,
          subtopic_specific: trendGroupBySubtopicOnly ? "" : subtopic_specific,
          counts: {},
          total: 0
        };
      }
      groups[key].counts[year] = (groups[key].counts[year] || 0) + 1;
      groups[key].total += 1;
    });

    // Convert to arrays
    const topicArray = Object.values(topicTotals);
    const subtopicArray = Object.values(subtopicTotals);
    const groupArray = Object.values(groups);

    // Regular sorting as before
    groupArray.sort((a, b) => {
      if (b.total !== a.total) {
        return b.total - a.total;
      }
      if (a.topic !== b.topic) {
        return a.topic.localeCompare(b.topic);
      }
      if (a.subtopic !== b.subtopic) {
        return a.subtopic.localeCompare(b.subtopic);
      }
      return a.subtopic_specific.localeCompare(b.subtopic_specific);
    });

    // Sort topics alphabetically
    topicArray.sort((a, b) => a.topic.localeCompare(b.topic));
    
    // Sort subtopics first by topic, then by subtopic
    subtopicArray.sort((a, b) => {
      if (a.topic !== b.topic) {
        return a.topic.localeCompare(b.topic);
      }
      return a.subtopic.localeCompare(b.subtopic);
    });

    // Combine arrays based on display options
    let finalArray = [];
    
    // First add all detail rows
    finalArray = [...groupArray];
    
    // Then add subtopic totals if enabled
    if (trendDisplayOptions.showSubtopicTotals) {
      // Group detail rows by topic for better organization
      const groupedByTopic = {};
      
      // Group subtopic totals by their topic
      subtopicArray.forEach((subtopic) => {
        if (!groupedByTopic[subtopic.topic]) {
          groupedByTopic[subtopic.topic] = [];
        }
        groupedByTopic[subtopic.topic].push(subtopic);
      });
      
      // Group detail rows by their topic too
      finalArray = [];
      Object.keys(groupedByTopic).sort().forEach(topicName => {
        // Add all subtopic totals for this topic
        finalArray.push(...groupedByTopic[topicName]);
        
        // Add all detail rows for this topic
        const topicDetailRows = groupArray.filter(row => row.topic === topicName);
        finalArray.push(...topicDetailRows);
      });
    }
    
    // Finally add topic totals as headers if enabled
    if (trendDisplayOptions.showTopicTotals) {
      const reorganizedArray = [];
      
      topicArray.forEach(topicRow => {
        // Add the topic total row first
        reorganizedArray.push(topicRow);
        
        // Then add all rows that belong to this topic
        const topicRows = finalArray.filter(row => row.topic === topicRow.topic);
        reorganizedArray.push(...topicRows);
      });
      
      finalArray = reorganizedArray;
    }

    return finalArray;
  }, [trendFilteredQuestions, trendGroupBySubtopicOnly, trendDisplayOptions]);

  // Datos para el gráfico de total de preguntas por año
  const trendChartData = useMemo(() => {
    return trendYears.map((year) => ({
      year,
      total: trendFilteredQuestions.filter((q) => q.year === year).length
    }));
  }, [trendYears, trendFilteredQuestions]);

  // Gráfico de Subtemas para un tema seleccionado
  const subtopicsBarData = useMemo(() => {
    if (trendTopicFilter.length !== 1) return [];
    const selectedTopic = trendTopicFilter[0];
    const topicQuestions = trendFilteredQuestions.filter((q) => q.topic === selectedTopic);
    const counts = {};
    topicQuestions.forEach((q) => {
      counts[q.subtopic] = (counts[q.subtopic] || 0) + 1;
    });
    const data = Object.keys(counts).map((subtopic) => ({ subtopic, count: counts[subtopic] }));
    if (subtopicsSortOrder === 'asc') {
      data.sort((a, b) => a.count - b.count);
    } else {
      data.sort((a, b) => b.count - a.count);
    }
    return data;
  }, [trendFilteredQuestions, trendTopicFilter, subtopicsSortOrder]);

  // ============================
  // Tooltips y handlers
  // ============================
  const CustomBarTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white border border-gray-300 p-2 rounded shadow">
          <p className="font-bold text-gray-800">Subtema: {label}</p>
          <p className="text-gray-600">Preguntas: {payload[0].value}</p>
        </div>
      );
    }
    return null;
  };

  const handleBarClick = (data, index) => {
    setSelectedBarData(data);
  };

  // Nuevo handler para las opciones de visualización
  const handleDisplayOptionChange = (option) => {
    setTrendDisplayOptions(prev => ({
      ...prev,
      [option]: !prev[option]
    }));
  };

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gradient-to-br from-gray-800 to-gray-900' : 'bg-gradient-to-br from-red-200 to-yellow-200'} flex flex-col items-center py-10 px-4`}>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white'} rounded-lg shadow-2xl w-full max-w-6xl p-8`}
      >
        {/* Botón de Regresar */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onBack}
          className={`mb-4 px-4 py-2 ${darkMode ? 'bg-red-700' : 'bg-red-600'} text-white rounded-lg hover:bg-red-700 transition self-start`}
        >
          Regresar
        </motion.button>

        {/* Cabecera */}
        <header className="mb-8 text-center">
          <motion.h1
            className={`text-4xl font-extrabold ${darkMode ? 'text-red-400' : 'text-red-600'} mb-2`}
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            Generador de Exámenes Interactivo
          </motion.h1>
          <motion.p
            className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Personaliza tus filtros y genera exámenes al instante
          </motion.p>
        </header>

        {/* Sección de Filtros del Examen */}
        <section className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <motion.h2 className={`text-2xl font-bold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Filtros</motion.h2>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={resetFilters}
              className={`${darkMode ? 'bg-gray-700 hover:bg-gray-600 text-gray-200' : 'bg-gray-200 hover:bg-gray-300 text-gray-700'} px-4 py-2 rounded transition`}
            >
              Limpiar Filtros
            </motion.button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Filtro: Año */}
            <div>
              <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>Año</h3>
              <div className="space-y-2">
                {uniqueYears.map((year) => (
                  <motion.label
                    key={`year-${year}`}
                    className="flex items-center space-x-2 cursor-pointer"
                    whileHover={{ scale: 1.02 }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedYears.includes(year)}
                      onChange={() => toggleSelected(year, selectedYears, setSelectedYears)}
                      className={`form-checkbox h-5 w-5 ${darkMode ? 'text-red-400 bg-gray-700' : 'text-red-600'}`}
                    />
                    <span className={`${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>{year}</span>
                  </motion.label>
                ))}
              </div>
            </div>
            {/* Filtro: Especialidad */}
            <div>
              <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>Especialidad</h3>
              <div className="space-y-2">
                {uniqueEspecialidades.map((esp) => (
                  <motion.label
                    key={`especialidad-${esp}`}
                    className="flex items-center space-x-2 cursor-pointer"
                    whileHover={{ scale: 1.02 }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedEspecialidades.includes(esp)}
                      onChange={() => toggleSelected(esp, selectedEspecialidades, setSelectedEspecialidades)}
                      className={`form-checkbox h-5 w-5 ${darkMode ? 'text-red-400 bg-gray-700' : 'text-red-600'}`}
                    />
                    <span className={`${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>{esp}</span>
                  </motion.label>
                ))}
              </div>
            </div>
            {/* Filtro: Área */}
            <div>
              <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>Área</h3>
              <div className="space-y-2">
                {uniqueAreas.map((area) => (
                  <motion.label
                    key={`area-${area}`}
                    className="flex items-center space-x-2 cursor-pointer"
                    whileHover={{ scale: 1.02 }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedAreas.includes(area)}
                      onChange={() => toggleSelected(area, selectedAreas, setSelectedAreas)}
                      className={`form-checkbox h-5 w-5 ${darkMode ? 'text-red-400 bg-gray-700' : 'text-red-600'}`}
                    />
                    <span className={`${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>{area}</span>
                  </motion.label>
                ))}
              </div>
            </div>
          </div>

          {/* Dropdowns para Tema, Subtema y Subtema Específico */}
          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Dropdown: Tema */}
            <div>
              <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>Tema</h3>
              <select
                multiple
                value={selectedTopics}
                onChange={handleTopicsChange}
                className={`w-full p-3 ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'border'} rounded focus:outline-none focus:ring-2 focus:ring-red-300 transition`}
                size={Math.min(topicsWithCount.length, 5) || 1}
              >
                {topicsWithCount.length > 0
                  ? topicsWithCount.map((item) => (
                      <option key={item.topic} value={item.topic}>
                        {item.topic} ({item.count})
                      </option>
                    ))
                  : <option disabled>No hay temas disponibles</option>}
              </select>
            </div>
            {/* Dropdown: Subtema */}
            <div>
              <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>Subtema</h3>
              <select
                multiple
                value={selectedSubtopics}
                onChange={handleSubtopicsChange}
                className={`w-full p-3 ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'border'} rounded focus:outline-none focus:ring-2 focus:ring-red-300 transition`}
                size={Math.min(subtopicsWithCount.length, 5) || 1}
              >
                {subtopicsWithCount.length > 0
                  ? subtopicsWithCount.map((item) => (
                      <option key={item.subtopic} value={item.subtopic}>
                        {item.subtopic} ({item.count})
                      </option>
                    ))
                  : <option disabled>No hay subtemas disponibles</option>}
              </select>
            </div>
            {/* Dropdown: Subtema Específico (NUEVO) */}
            <div>
              <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>Subtema Específico</h3>
              <select
                multiple
                value={selectedSubtopicsSpecific}
                onChange={handleSubtopicsSpecificChange}
                className={`w-full p-3 ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'border'} rounded focus:outline-none focus:ring-2 focus:ring-red-300 transition`}
                size={Math.min(subtopicsSpecificWithCount.length, 5) || 1}
              >
                {subtopicsSpecificWithCount.length > 0
                  ? subtopicsSpecificWithCount.map((item) => (
                      <option key={item.subtopic_specific} value={item.subtopic_specific}>
                        {item.subtopic_specific} ({item.count})
                      </option>
                    ))
                  : <option disabled>No hay subtemas específicos</option>}
              </select>
            </div>
          </div>
        </section>

        {/* Sección de Filtros Activos */}
        <section className="mb-8">
          <h2 className={`text-2xl font-bold ${darkMode ? 'text-gray-200' : 'text-gray-800'} mb-4`}>Filtros Activos</h2>
          <div className="flex flex-wrap gap-2">
            <AnimatePresence>
              {selectedYears.map((year) => (
                <motion.span
                  key={`year-${year}`}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  className="bg-red-100 text-red-700 px-3 py-1 rounded-full cursor-pointer hover:bg-red-200 transition"
                  onClick={() => setSelectedYears((prev) => prev.filter((y) => y !== year))}
                >
                  {year} &times;
                </motion.span>
              ))}
              {selectedEspecialidades.map((esp) => (
                <motion.span
                  key={`especialidad-${esp}`}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  className="bg-green-100 text-green-700 px-3 py-1 rounded-full cursor-pointer hover:bg-green-200 transition"
                  onClick={() => setSelectedEspecialidades((prev) => prev.filter((e) => e !== esp))}
                >
                  {esp} &times;
                </motion.span>
              ))}
              {selectedAreas.map((area) => (
                <motion.span
                  key={`area-${area}`}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  className="bg-blue-100 text-blue-700 px-3 py-1 rounded-full cursor-pointer hover:bg-blue-200 transition"
                  onClick={() => setSelectedAreas((prev) => prev.filter((a) => a !== area))}
                >
                  {area} &times;
                </motion.span>
              ))}
              {selectedTopics.map((tp) => (
                <motion.span
                  key={`topic-${tp}`}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  className="bg-purple-100 text-purple-700 px-3 py-1 rounded-full cursor-pointer hover:bg-purple-200 transition"
                  onClick={() => setSelectedTopics((prev) => prev.filter((t) => t !== tp))}
                >
                  {tp} &times;
                </motion.span>
              ))}
              {selectedSubtopics.map((st) => (
                <motion.span
                  key={`sub-${st}`}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  className="bg-yellow-100 text-yellow-700 px-3 py-1 rounded-full cursor-pointer hover:bg-yellow-200 transition"
                  onClick={() => setSelectedSubtopics((prev) => prev.filter((s) => s !== st))}
                >
                  {st} &times;
                </motion.span>
              ))}
              {/* Filtros activos de subtopic_specific */}
              {selectedSubtopicsSpecific.map((sps) => (
                <motion.span
                  key={`subspec-${sps}`}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  className="bg-pink-100 text-pink-700 px-3 py-1 rounded-full cursor-pointer hover:bg-pink-200 transition"
                  onClick={() =>
                    setSelectedSubtopicsSpecific((prev) => prev.filter((val) => val !== sps))
                  }
                >
                  {sps} &times;
                </motion.span>
              ))}
              {/* Caso: sin filtros */}
              {selectedYears.length === 0 &&
                selectedEspecialidades.length === 0 &&
                selectedAreas.length === 0 &&
                selectedTopics.length === 0 &&
                selectedSubtopics.length === 0 &&
                selectedSubtopicsSpecific.length === 0 && (
                  <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={`${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    No hay filtros activos
                  </motion.span>
                )}
            </AnimatePresence>
          </div>
        </section>

        {/* Botón para mostrar Análisis de Tendencia */}
        <div className="mb-8 text-center">
          {!showTrendAnalysis && (
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowTrendAnalysis(true)}
              className={`px-6 py-2 ${darkMode ? 'bg-blue-700 hover:bg-blue-800' : 'bg-blue-600 hover:bg-blue-700'} text-white font-bold rounded-full shadow-lg transition`}
            >
              Análisis de Tendencia
            </motion.button>
          )}
        </div>

        {/* Sección de Análisis de Tendencia */}
        {showTrendAnalysis && (
          <section className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className={`text-2xl font-bold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Análisis de Tendencia</h2>
              <div className="space-x-2">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={resetTrendFilters}
                  className={`${darkMode ? 'bg-gray-700 hover:bg-gray-600 text-gray-200' : 'bg-gray-200 hover:bg-gray-300 text-gray-700'} px-4 py-2 rounded transition`}
                >
                  Limpiar Filtros
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    setShowTrendAnalysis(false);
                    setTrendView("table");
                    setSelectedTrendGroup(null);
                    setActiveTrendAnalysisSection(null);
                  }}
                  className={`${darkMode ? 'bg-gray-700 hover:bg-gray-600 text-gray-200' : 'bg-gray-200 hover:bg-gray-300 text-gray-700'} px-4 py-2 rounded transition`}
                >
                  Cerrar Análisis
                </motion.button>
              </div>
            </div>

            {/* Filtros para el análisis */}
            <div className="grid grid-cols-1 md:grid-cols-5 gap-6 mb-6">
              {/* Filtro: Año (NUEVO) */}
              <div>
                <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>Filtrar por Año</h3>
                <select
                  multiple
                  value={trendYearFilter}
                  onChange={(e) => {
                    const options = e.target.options;
                    const selected = [];
                    for (let i = 0; i < options.length; i++) {
                      if (options[i].selected) selected.push(options[i].value);
                    }
                    setTrendYearFilter(selected);
                  }}
                  className={`w-full p-3 border ${darkMode ? 'bg-gray-700 text-white border-gray-600' : ''} rounded focus:outline-none focus:ring-2 focus:ring-blue-300 transition`}
                  size={Math.min(uniqueTrendYears.length, 5) || 1}
                >
                  {uniqueTrendYears.map((yr) => (
                    <option key={yr} value={yr}>
                      {yr}
                    </option>
                  ))}
                </select>
              </div>
              {/* Filtro: Área */}
              <div>
                <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>Filtrar por Área</h3>
                <select
                  multiple
                  value={trendAreaFilter}
                  onChange={(e) => {
                    const options = e.target.options;
                    const selected = [];
                    for (let i = 0; i < options.length; i++) {
                      if (options[i].selected) selected.push(options[i].value);
                    }
                    setTrendAreaFilter(selected);
                  }}
                  className={`w-full p-3 border ${darkMode ? 'bg-gray-700 text-white border-gray-600' : ''} rounded focus:outline-none focus:ring-2 focus:ring-blue-300 transition`}
                  size={Math.min(uniqueTrendAreas.length, 5) || 1}
                >
                  {uniqueTrendAreas.map((area) => (
                    <option key={area} value={area}>{area}</option>
                  ))}
                </select>
              </div>
              {/* Filtro: Tema */}
              <div>
                <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>Filtrar por Tema</h3>
                <select
                  multiple
                  value={trendTopicFilter}
                  onChange={(e) => {
                    const options = e.target.options;
                    const selected = [];
                    for (let i = 0; i < options.length; i++) {
                      if (options[i].selected) selected.push(options[i].value);
                    }
                    setTrendTopicFilter(selected);
                  }}
                  className={`w-full p-3 border ${darkMode ? 'bg-gray-700 text-white border-gray-600' : ''} rounded focus:outline-none focus:ring-2 focus:ring-blue-300 transition`}
                  size={Math.min(uniqueTrendTopics.length, 5) || 1}
                >
                  {uniqueTrendTopics.map((tp) => (
                    <option key={tp} value={tp}>{tp}</option>
                  ))}
                </select>
              </div>
              {/* Filtro: Subtema */}
              <div>
                <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>Filtrar por Subtema</h3>
                <select
                  multiple
                  value={trendSubtopicFilter}
                  onChange={(e) => {
                    const options = e.target.options;
                    const selected = [];
                    for (let i = 0; i < options.length; i++) {
                      if (options[i].selected) selected.push(options[i].value);
                    }
                    setTrendSubtopicFilter(selected);
                  }}
                  className={`w-full p-3 border ${darkMode ? 'bg-gray-700 text-white border-gray-600' : ''} rounded focus:outline-none focus:ring-2 focus:ring-blue-300 transition`}
                  size={Math.min(uniqueTrendSubtopics.length, 5) || 1}
                >
                  {uniqueTrendSubtopics.map((st) => (
                    <option key={st} value={st}>{st}</option>
                  ))}
                </select>
              </div>
              {/* Filtro: Subtema Específico */}
              <div>
                <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>Filtrar por Subtema Específico</h3>
                <select
                  multiple
                  value={trendSubtopicSpecificFilter}
                  onChange={(e) => {
                    const options = e.target.options;
                    const selected = [];
                    for (let i = 0; i < options.length; i++) {
                      if (options[i].selected) selected.push(options[i].value);
                    }
                    setTrendSubtopicSpecificFilter(selected);
                  }}
                  className={`w-full p-3 border ${darkMode ? 'bg-gray-700 text-white border-gray-600' : ''} rounded focus:outline-none focus:ring-2 focus:ring-blue-300 transition`}
                  size={Math.min(uniqueTrendSubtopicsSpecific.length, 5) || 1}
                >
                  {uniqueTrendSubtopicsSpecific.map((sps) => (
                    <option key={sps} value={sps}>{sps}</option>
                  ))}
                </select>
              </div>
            </div>

            {/* NUEVAS Opciones de visualización */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
              {/* Checkbox para agrupar solo por Subtema */}
              <div className="flex items-center space-x-2">
                <input
                  id="groupBySubtopic"
                  type="checkbox"
                  checked={trendGroupBySubtopicOnly}
                  onChange={() => setTrendGroupBySubtopicOnly(!trendGroupBySubtopicOnly)}
                  className={`form-checkbox h-5 w-5 ${darkMode ? 'bg-gray-700 text-blue-400' : 'text-blue-600'}`}
                />
                <label htmlFor="groupBySubtopic" className={`${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  Mostrar total únicamente por <strong>Subtema</strong> (ignorar Subtema Específico)
                </label>
              </div>
              
              {/* NUEVO: Mostrar total por Tema */}
              <div className="flex items-center space-x-2">
                <input
                  id="showTopicTotals"
                  type="checkbox"
                  checked={trendDisplayOptions.showTopicTotals}
                  onChange={() => handleDisplayOptionChange('showTopicTotals')}
                  className={`form-checkbox h-5 w-5 ${darkMode ? 'bg-gray-700 text-blue-400' : 'text-blue-600'}`}
                />
                <label htmlFor="showTopicTotals" className={`${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  Mostrar filas de total por <strong>Tema</strong>
                </label>
              </div>
              
              {/* NUEVO: Mostrar total por Subtema */}
              <div className="flex items-center space-x-2">
                <input
                  id="showSubtopicTotals"
                  type="checkbox"
                  checked={trendDisplayOptions.showSubtopicTotals}
                  onChange={() => handleDisplayOptionChange('showSubtopicTotals')}
                  className={`form-checkbox h-5 w-5 ${darkMode ? 'bg-gray-700 text-blue-400' : 'text-blue-600'}`}
                />
                <label htmlFor="showSubtopicTotals" className={`${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  Mostrar filas de total por <strong>Subtema</strong>
                </label>
              </div>
            </div>

            {/* Botones para alternar vista */}
            <div className="flex justify-center mb-6 space-x-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  setTrendView("table");
                  setSelectedTrendGroup(null);
                  setActiveTrendAnalysisSection(null);
                }}
                className={`px-4 py-2 rounded ${
                  trendView === "table" ? 
                  `${darkMode ? 'bg-blue-700 text-white' : 'bg-blue-600 text-white'}` : 
                  `${darkMode ? 'bg-gray-700 text-gray-200' : 'bg-gray-200 text-gray-700'}`
                }`}
              >
                Tabla
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  setTrendView("chart");
                  setSelectedTrendGroup(null);
                  setActiveTrendAnalysisSection(null);
                }}
                className={`px-4 py-2 rounded ${
                  trendView === "chart" ? 
                  `${darkMode ? 'bg-blue-700 text-white' : 'bg-blue-600 text-white'}` : 
                  `${darkMode ? 'bg-gray-700 text-gray-200' : 'bg-gray-200 text-gray-700'}`
                }`}
              >
                Gráfico
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  setTrendView("subtopics");
                  setActiveTrendAnalysisSection(null);
                }}
                disabled={trendTopicFilter.length !== 1}
                className={`px-4 py-2 rounded ${
                  trendView === "subtopics" ? 
                  `${darkMode ? 'bg-blue-700 text-white' : 'bg-blue-600 text-white'}` : 
                  `${darkMode ? 'bg-gray-700 text-gray-200' : 'bg-gray-200 text-gray-700'}`
                } ${trendTopicFilter.length !== 1 ? "opacity-50 cursor-not-allowed" : ""}`}
              >
                Gráfico Subtemas
              </motion.button>
            </div>

            {/* Vista según el modo seleccionado */}
            {trendView === "table" && (
              <div className="overflow-x-auto">
                <table className={`min-w-full border-collapse border ${darkMode ? 'border-gray-600' : 'border-gray-300'}`}>
                  <thead>
                    <tr className={`${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                      <th className={`border ${darkMode ? 'border-gray-600' : 'border-gray-300'} px-4 py-2`}>Tema</th>
                      <th className={`border ${darkMode ? 'border-gray-600' : 'border-gray-300'} px-4 py-2`}>Subtema</th>
                      {/* Mostrar la columna de Subtema Específico solo si NO agrupamos por subtema */}
                      {!trendGroupBySubtopicOnly && (
                        <th className={`border ${darkMode ? 'border-gray-600' : 'border-gray-300'} px-4 py-2`}>Subtema Específico</th>
                      )}
                      {trendYears.map((year) => (
                        <th key={year} className={`border ${darkMode ? 'border-gray-600' : 'border-gray-300'} px-4 py-2`}>{year}</th>
                      ))}
                      <th className={`border ${darkMode ? 'border-gray-600' : 'border-gray-300'} px-4 py-2`}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trendGroupedData.map((row, index) => {
                      // Determine row styling based on type (topic, subtopic, or detail)
                      let rowClassName = `hover:${darkMode ? 'bg-gray-700' : 'bg-gray-50'} cursor-pointer`;
                      
                      if (row.isTopic) {
                        rowClassName = `${darkMode ? 'bg-blue-900 hover:bg-blue-800' : 'bg-blue-100 hover:bg-blue-200'} font-bold cursor-pointer`;
                      } else if (row.isSubtopic) {
                        rowClassName = `${darkMode ? 'bg-purple-900 hover:bg-purple-800' : 'bg-yellow-50 hover:bg-yellow-400'} italic cursor-pointer`;
                      }
                      
                      return (
                        <tr
                          key={index}
                          className={rowClassName}
                          onClick={() => !row.isTopic && !row.isSubtopic && setSelectedTrendGroup(row)}
                        >
                          <td className={`border ${darkMode ? 'border-gray-600' : 'border-gray-300'} px-4 py-2`}>
                            {row.isTopic ? `📊 TOTAL: ${row.topic}` : row.topic}
                          </td>
                          <td className={`border ${darkMode ? 'border-gray-600' : 'border-gray-300'} px-4 py-2`}>
                            {row.isSubtopic ? `📈 TOTAL: ${row.subtopic}` : row.subtopic}
                          </td>
                          {/* Celda de subtopic_specific solo si NO agrupamos por subtema */}
                          {!trendGroupBySubtopicOnly && (
                            <td className={`border ${darkMode ? 'border-gray-600' : 'border-gray-300'} px-4 py-2`}>
                              {row.subtopic_specific}
                            </td>
                          )}
                          {trendYears.map((year) => (
  <td key={year} className={`border ${darkMode ? 'border-gray-600' : 'border-gray-300'} px-4 py-2 text-center`}>
    {row.counts[year] > 0 ? (
      <span className={`inline-block rounded-full w-7 h-7 flex items-center justify-center font-bold ${
        darkMode 
          ? 'bg-red-700 text-white'
          : 'bg-red-100 text-red-800'
      }`}>
        {row.counts[year]}
      </span>
    ) : (
      <span className={`text-${darkMode ? 'gray-500' : 'gray-400'}`}>0</span>
    )}
  </td>
))}
<td className={`border ${darkMode ? 'border-gray-600' : 'border-gray-300'} px-4 py-2 text-center ${
  row.isTopic 
    ? (darkMode ? 'bg-blue-900' : 'bg-blue-100')
    : row.isSubtopic 
      ? (darkMode ? 'bg-purple-900' : 'bg-yellow-50')
      : ''
}`}>
  <span className={`inline-block rounded-lg font-bold relative overflow-hidden ${
    row.total > 0 ? 'px-4 py-1.5' : 'px-3 py-1'
  }`} 
  style={{
    background: row.total > 0 
      ? `linear-gradient(135deg, ${darkMode ? '#ff4500' : '#ff8c00'} 0%, ${darkMode ? '#ff0000' : '#ff4500'} 100%)`
      : darkMode ? '#333' : '#f5f5f5',
    color: row.total > 0 ? 'white' : darkMode ? '#666' : '#999',
    textShadow: row.total > 0 ? '0 1px 2px rgba(0,0,0,0.3)' : 'none',
    boxShadow: row.total > 0 
      ? `0 2px 4px rgba(${darkMode ? '0,0,0,0.5' : '255,69,0,0.3'})` 
      : 'none',
    border: row.total > 0 
      ? `1px solid ${darkMode ? '#ff2200' : '#ff6347'}`
      : `1px solid ${darkMode ? '#444' : '#ddd'}`
  }}>
    {row.total > 0 && (
      <span className="absolute inset-0 opacity-20" 
        style={{
          background: `radial-gradient(ellipse at top, ${darkMode ? '#ffcc00' : '#ffdd00'} 0%, transparent 70%)`,
          mixBlendMode: 'overlay'
        }}
      />
    )}
    {row.total}
  </span>
</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'} mt-2 text-center`}>
                  Haz clic en una fila para ver el detalle gráfico
                </p>
              </div>
            )}

            {trendView === "chart" && (
              <div className="w-full h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={trendChartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" stroke={darkMode ? "#444" : undefined} />
                    <XAxis dataKey="year" stroke={darkMode ? "#ccc" : undefined} />
                    <YAxis allowDecimals={false} stroke={darkMode ? "#ccc" : undefined} />
                    <Tooltip contentStyle={darkMode ? { backgroundColor: '#333', borderColor: '#555', color: '#fff' } : undefined} />
                    <Legend />
                    <Line type="monotone" dataKey="total" stroke="#FF0000" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}

            {trendView === "subtopics" && (
              <div className="w-full h-72">
                {subtopicsBarData.length > 0 ? (
                  <>
                    <div className="flex justify-center space-x-4 mb-4">
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() =>
                          setSubtopicsSortOrder(subtopicsSortOrder === 'desc' ? 'asc' : 'desc')
                        }
                        className={`px-4 py-2 ${darkMode ? 'bg-green-700 hover:bg-green-800' : 'bg-green-600 hover:bg-green-700'} text-white rounded`}
                      >
                        Orden: {subtopicsSortOrder === 'desc' ? 'Descendente' : 'Ascendente'}
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() =>
                          setSubtopicsChartType(subtopicsChartType === 'bar' ? 'pie' : 'bar')
                        }
                        className={`px-4 py-2 ${darkMode ? 'bg-purple-700 hover:bg-purple-800' : 'bg-purple-600 hover:bg-purple-700'} text-white rounded`}
                      >
                        Tipo de gráfico: {subtopicsChartType === 'bar' ? 'Barra' : 'Pastel'}
                      </motion.button>
                    </div>
                    {subtopicsChartType === 'bar' ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={subtopicsBarData} margin={{ top: 20, right: 30, bottom: 50, left: 20 }}>
                          <CartesianGrid strokeDasharray="3 3" stroke={darkMode ? "#444" : undefined} />
                          <XAxis 
                            dataKey="subtopic" 
                            tick={{ fill: darkMode ? "#ccc" : "#333", fontSize: 12 }}
                          >
                            <Label value="Subtemas" offset={-40} position="insideBottom" fill={darkMode ? "#ccc" : "#333"} />
                          </XAxis>
                          <YAxis tick={{ fill: darkMode ? "#ccc" : "#333", fontSize: 12 }}>
                            <Label value="Número de Preguntas" angle={-90} position="insideLeft" fill={darkMode ? "#ccc" : "#333"} />
                          </YAxis>
                          <Tooltip contentStyle={darkMode ? { backgroundColor: '#333', borderColor: '#555', color: '#fff' } : undefined} />
                          <Legend />
                          <Bar dataKey="count" isAnimationActive={true} onClick={handleBarClick} cursor="pointer">
                            {subtopicsBarData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={barColors[index % barColors.length]}
                              />
                            ))}
                            <LabelList dataKey="count" position="top" style={{ fill: darkMode ? "#ddd" : "#333", fontWeight: "bold" }} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Tooltip contentStyle={darkMode ? { backgroundColor: '#333', borderColor: '#555', color: '#fff' } : undefined} />
                          <Legend />
                          <Pie
                            data={subtopicsBarData}
                            dataKey="count"
                            nameKey="subtopic"
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            onClick={(data, index) => handleBarClick(data.payload, index)}
                            label
                          >
                            {subtopicsBarData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={barColors[index % barColors.length]}
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    )}
                  </>
                ) : (
                  <p className={`text-center ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    No hay datos para el gráfico de subtemas.
                  </p>
                )}
              </div>
            )}

            {/* Controles de visualización avanzada */}
            <div className="mt-8 mb-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>Visualizaciones Avanzadas</h3>
                <div className="space-x-2">
                  <button
                    className={`px-3 py-1 ${activeTrendAnalysisSection === "predictive" ? 
                    `${darkMode ? 'bg-indigo-800' : 'bg-indigo-700'}` : 
                    `${darkMode ? 'bg-indigo-700 hover:bg-indigo-800' : 'bg-indigo-600 hover:bg-indigo-700'}`} text-white rounded transition`}
                    onClick={() => setActiveTrendAnalysisSection(activeTrendAnalysisSection === "predictive" ? null : "predictive")}
                  >
                    Análisis Estadístico
                  </button>
                  <button
                    className={`px-3 py-1 ${activeTrendAnalysisSection === "heatmap" ? 
                    `${darkMode ? 'bg-green-800' : 'bg-green-700'}` : 
                    `${darkMode ? 'bg-green-700 hover:bg-green-800' : 'bg-green-600 hover:bg-green-700'}`} text-white rounded transition`}
                    onClick={() => setActiveTrendAnalysisSection(activeTrendAnalysisSection === "heatmap" ? null : "heatmap")}
                  >
                    Mapa de Calor
                  </button>
                  <button
                    className={`px-3 py-1 ${activeTrendAnalysisSection === "comparison" ? 
                    `${darkMode ? 'bg-amber-800' : 'bg-amber-700'}` : 
                    `${darkMode ? 'bg-amber-700 hover:bg-amber-800' : 'bg-amber-600 hover:bg-amber-700'}`} text-white rounded transition`}
                    onClick={() => setActiveTrendAnalysisSection(activeTrendAnalysisSection === "comparison" ? null : "comparison")}
                  >
                    Comparativa
                  </button>
                  <button
                    className={`px-3 py-1 ${activeTrendAnalysisSection === "dashboard" ? 
                    `${darkMode ? 'bg-purple-800' : 'bg-purple-700'}` : 
                    `${darkMode ? 'bg-purple-700 hover:bg-purple-800' : 'bg-purple-600 hover:bg-purple-700'}`} text-white rounded transition`}
                    onClick={() => setActiveTrendAnalysisSection(activeTrendAnalysisSection === "dashboard" ? null : "dashboard")}
                  >
                    Dashboard
                  </button>
                </div>
              </div>

              {/* Componente de Exportación */}
              <ExportComponent 
                trendFilteredQuestions={trendFilteredQuestions}
                trendGroupedData={trendGroupedData} 
                years={trendYears}
                darkMode={darkMode}
              />

              {/* Análisis Predictivo y Estadístico */}
              {activeTrendAnalysisSection === "predictive" && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <TrendAnalysisStats 
                    trendFilteredQuestions={trendFilteredQuestions} 
                    years={trendYears}
                    darkMode={darkMode}
                  />
                </motion.div>
              )}

              {/* Mapa de Calor */}
              {activeTrendAnalysisSection === "heatmap" && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <TrendHeatmap 
                    data={trendGroupedData} 
                    years={trendYears}
                    darkMode={darkMode}
                  />
                </motion.div>
              )}

              {/* Comparativa de Tendencias */}
              {activeTrendAnalysisSection === "comparison" && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <MultiTrendChart 
                    trendFilteredQuestions={trendFilteredQuestions} 
                    years={trendYears}
                    darkMode={darkMode}
                  />
                </motion.div>
              )}

              {/* Dashboard Interactivo */}
              {activeTrendAnalysisSection === "dashboard" && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <InteractiveDashboard 
                    allQuestions={allQuestions}
                    trendFilteredQuestions={trendFilteredQuestions} 
                    years={trendYears}
                    darkMode={darkMode}
                  />
                </motion.div>
              )}
            </div>
          </section>
        )}

        {/* Opciones del Examen */}
        <section className="mb-8">
          <h2 className={`text-2xl font-bold ${darkMode ? 'text-gray-200' : 'text-gray-800'} mb-4`}>Opciones del Examen</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Título del Examen */}
            <div>
              <label className={`block text-lg font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>
                Título del Examen (opcional)
              </label>
              <input
                type="text"
                value={examTitle}
                onChange={(e) => setExamTitle(e.target.value)}
                placeholder="Ej: Simulacro Cardiología 2024"
                className={`w-full p-3 ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'border'} rounded focus:outline-none focus:ring-2 focus:ring-red-300 transition`}
              />
            </div>

            {/* Número de Preguntas */}
            <div>
              <label className={`block text-lg font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>
                Número de Preguntas (máx. 200): <span className="font-bold">{numPreguntas}</span>
              </label>
              <input
                type="range"
                value={numPreguntas}
                onChange={(e) => setNumPreguntas(Number(e.target.value))}
                min="1"
                max="200"
                className="w-full mb-2"
              />
              <input
                type="number"
                value={numPreguntas}
                onChange={(e) => {
                  let val = Number(e.target.value);
                  if (val > 200) val = 200;
                  if (val < 1) val = 1;
                  setNumPreguntas(val);
                }}
                min="1"
                max="200"
                className={`w-full p-2 ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'border'} rounded focus:outline-none focus:ring-2 focus:ring-red-300 transition`}
              />
            </div>

            {/* Tiempo (minutos) */}
            <div>
              <label className={`block text-lg font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>
                Tiempo (minutos, máx. 180): <span className="font-bold">{tiempo}</span>
              </label>
              <input
                type="range"
                value={tiempo}
                onChange={(e) => setTiempo(Number(e.target.value))}
                min="1"
                max="180"
                className="w-full mb-2"
                disabled={examMode === "study"}
              />
              <input
                type="number"
                value={tiempo}
                onChange={(e) => {
                  let val = Number(e.target.value);
                  if (val > 180) val = 180;
                  if (val < 1) val = 1;
                  setTiempo(val);
                }}
                min="1"
                max="180"
                disabled={examMode === "study"}
                className={`w-full p-2 ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'border'} rounded focus:outline-none focus:ring-2 focus:ring-red-300 transition ${examMode === "study" ? 'opacity-50' : ''}`}
              />
              {examMode === "study" && (
                <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'} mt-1`}>Modo de estudio: Sin límite de tiempo</p>
              )}
            </div>

            {/* Modo de Examen */}
            <div className="md:col-span-2">
              <label className={`block text-lg font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>
                Modo de Examen:
              </label>
              <div className="flex space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    name="examMode"
                    value="study"
                    checked={examMode === "study"}
                    onChange={() => setExamMode("study")}
                  />
                  <span className={`ml-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>Modo de Estudio</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    name="examMode"
                    value="simulation"
                    checked={examMode === "simulation"}
                    onChange={() => setExamMode("simulation")}
                  />
                  <span className={`ml-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>Modo Simulacro</span>
                </label>
              </div>
            </div>
          </div>
        </section>

        {/* Resumen */}
        <section className="mb-8">
          <h2 className={`text-2xl font-bold ${darkMode ? 'text-gray-200' : 'text-gray-800'} mb-4`}>Resumen</h2>
          <div className="mb-4">
            <p className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              Preguntas disponibles (tras filtros): <span className="font-bold">{filteredQuestions.length}</span> de{" "}
              <span className="font-bold">{allQuestions.length}</span>
            </p>
            <div className="w-full bg-gray-300 rounded-full h-3">
              <motion.div
                className="bg-red-600 h-3 rounded-full"
                initial={{ width: 0 }}
                animate={{
                  width: `${
                    allQuestions.length > 0
                      ? (filteredQuestions.length / allQuestions.length) * 100
                      : 0
                  }%`,
                }}
                transition={{ duration: 0.5 }}
              />
            </div>
          </div>
          <div className="text-center">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleStart}
              className={`px-10 py-3 ${darkMode ? 'bg-red-700' : 'bg-red-600'} text-white font-bold rounded-full shadow-lg hover:bg-red-700 transition transform`}
            >
              Comenzar Examen
            </motion.button>
          </div>
        </section>
      </motion.div>

      {/* Modal para gráfico detallado del grupo seleccionado */}
      <AnimatePresence>
        {selectedTrendGroup && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white'} rounded-lg shadow-2xl p-6 w-11/12 md:w-2/3 relative`}
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
            >
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                Detalle: {selectedTrendGroup.topic} - {selectedTrendGroup.subtopic}
                {/* Solo mostrar subtema específico si no estamos agrupando por subtema */}
                {!trendGroupBySubtopicOnly && ` - ${selectedTrendGroup.subtopic_specific}`}
              </h3>
              <div className="w-full h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={trendYears.map((year) => ({
                      year,
                      count: selectedTrendGroup.counts[year] || 0,
                    }))}
                    margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="count" stroke="#007AFF" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setSelectedTrendGroup(null)}
                className={`absolute top-4 right-4 ${darkMode ? 'text-gray-300 hover:text-gray-100' : 'text-gray-600 hover:text-gray-800'}`}
              >
                Cerrar
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Modal para detalle al hacer clic en una barra o segmento */}
      <AnimatePresence>
        {selectedBarData && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white'} rounded-lg shadow-2xl p-6 w-11/12 md:w-1/3 relative`}
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
            >
              <h3 className="text-2xl font-bold mb-4">Detalle del Subtema</h3>
              <p className="text-lg mb-2">
                <span className="font-bold">Subtema:</span> {selectedBarData.subtopic}
              </p>
              <p className="text-lg">
                <span className="font-bold">Preguntas:</span> {selectedBarData.count}
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setSelectedBarData(null)}
                className={`absolute top-4 right-4 ${darkMode ? 'text-gray-300 hover:text-gray-100' : 'text-gray-600 hover:text-gray-800'}`}
              >
                Cerrar
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default GenerateExam;