/**
 * ErrorBank.js
 * 
 * Módulo que maneja el registro de errores en LocalStorage.
 */

/**
 * Calcula la fecha del próximo repaso basado en un modelo simple de repetición.
 * @param {Object} attemptData - Objeto con { attempts, errors }.
 * @returns {string} Fecha en formato ISO para la próxima revisión.
 */
function calculateNextReviewDate({ attempts, errors }) {
  let intervalDays = 1;
  if (errors === 0) {
    // Cada intento correcto amplía el intervalo, con un tope de 30 días.
    intervalDays = Math.min(30, attempts * 2 || 1);
  } else {
    // Si hay errores, repaso pronto (1 día).
    intervalDays = 1;
  }
  const nextReview = new Date();
  nextReview.setDate(nextReview.getDate() + intervalDays);
  return nextReview.toISOString();
}

/**
 * Registra (o actualiza) una pregunta en el banco de errores en modo normal (estudio).
 * - Se llama cada vez que el usuario contesta la pregunta.
 * - Si se responde incorrecto, se incrementa la cuenta de errores.
 * - Se actualiza la fecha de último intento y la fecha del próximo repaso.
 * - `timeAdded` se setea si no existe; o podría actualizarse si quieres que cada error
 *   refresque la fecha y se muestre como “más reciente” en ErrorReview.
 */
export function updateErrorBank(question, isCorrect, simulationMode = false) {
  const stored = localStorage.getItem('errorBank');
  const errorBank = stored ? JSON.parse(stored) : {};
  const qId = String(question.id);

  // Si no existe el registro, lo creamos
  const currentData = errorBank[qId] || {
    attempts: 0,
    errors: 0,
    learned: false, 
    mnemonic: '',
    lastAttempt: null,
    nextReview: null,
    removed: false,
    timeAdded: null
  };

  currentData.attempts += 1;
  if (!isCorrect) {
    currentData.errors += 1;
  }

  // Actualizamos la fecha de último intento
  const now = new Date().toISOString();
  currentData.lastAttempt = now;

  // Se setea la fecha de nextReview en modo estudio
  if (!simulationMode) {
    // Lógica normal de repetición
    currentData.nextReview = calculateNextReviewDate({
      attempts: currentData.attempts,
      errors: currentData.errors
    });
  } else {
    // (Si usaras "updateErrorBank" en modo simulacro, podrías setear nextReview inmediato.
    //  Pero en el flujo nuevo, se sugiere no llamar updateErrorBank en cada pregunta
    //  del simulacro, y usar addBulkSimulationErrors al final.)
  }

  // Si no tenía timeAdded, lo asignamos
  // (o podrías querer actualizarlo *cada vez* que hay un error)
  if (!currentData.timeAdded) {
    currentData.timeAdded = now;
  }

  errorBank[qId] = currentData;
  localStorage.setItem('errorBank', JSON.stringify(errorBank));
}

/**
 * Al terminar el simulacro, registra todas las preguntas falladas
 * con learned=false, incrementa contadores, y asigna timeAdded a la fecha/hora actual.
 * 
 * @param {Array} questions - Arreglo de preguntas falladas en el simulacro.
 */
export function addBulkSimulationErrors(questions) {
  const stored = localStorage.getItem('errorBank');
  const errorBank = stored ? JSON.parse(stored) : {};

  const now = new Date().toISOString();

  questions.forEach((q) => {
    const qId = String(q.id);

    if (!errorBank[qId]) {
      // Si no existía la entrada, la creamos
      errorBank[qId] = {
        attempts: 1,
        errors: 1,
        learned: false,
        mnemonic: '',
        lastAttempt: now,
        nextReview: null,
        removed: false,
        timeAdded: now
      };
    } else {
      // Si ya estaba en el banco, actualizamos
      errorBank[qId].attempts += 1;
      errorBank[qId].errors += 1;
      errorBank[qId].learned = false;   // marcado como “no aprendido”
      errorBank[qId].lastAttempt = now;
      // nextReview se puede recalcular si quieres:
      // errorBank[qId].nextReview = new Date().toISOString();
      // Y actualizamos la fecha de alta para que aparezca como reciente
      errorBank[qId].timeAdded = now;
    }
  });

  localStorage.setItem('errorBank', JSON.stringify(errorBank));
}

/**
 * Retorna las preguntas pendientes de revisión (nextReview <= hoy),
 * excluyendo las que están “aprendidas” o “removidas”.
 */
export function getDueErrors(allQuestions) {
  const stored = localStorage.getItem('errorBank');
  if (!stored) return [];
  const errorBank = JSON.parse(stored);
  const now = new Date();

  const dueIds = Object.keys(errorBank).filter((qId) => {
    const data = errorBank[qId];
    if (data.learned) return false;
    if (data.removed) return false;
    return data.nextReview && new Date(data.nextReview) <= now;
  });

  return allQuestions.filter((q) => dueIds.includes(String(q.id)));
}

/**
 * Devuelve todas las preguntas que tengan un registro en el errorBank
 * (y que no estén marcadas como removed).
 */
export function getAllErrorQuestions(allQuestions) {
  const stored = localStorage.getItem('errorBank');
  if (!stored) return [];
  const errorBank = JSON.parse(stored);

  const errorIds = Object.keys(errorBank).filter((qId) => {
    const data = errorBank[qId];
    // Excluir removidas
    if (data.removed) return false;
    // Incluir si hay attempts>0, errors>0 o learned===true
    return data.attempts > 0 || data.errors > 0 || data.learned === true;
  });

  return allQuestions.filter((q) => errorIds.includes(String(q.id)));
}

/**
 * Marca o desmarca una pregunta como aprendida. 
 * Si se marca como aprendida, se ajusta su nextReview a ~6 meses;
 * si se desmarca, se setea nextReview = now (repaso inmediato).
 */
export function toggleLearned(questionId) {
  const stored = localStorage.getItem('errorBank');
  if (!stored) return;
  const errorBank = JSON.parse(stored);

  if (!errorBank[questionId]) return;

  const qData = errorBank[questionId];
  qData.learned = !qData.learned;

  if (qData.learned) {
    // Aprendida: la programamos a 6 meses
    const sixMonthsFromNow = new Date();
    sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);
    qData.nextReview = sixMonthsFromNow.toISOString();
  } else {
    // Desmarcada: repaso pronto
    qData.nextReview = new Date().toISOString();
  }

  errorBank[questionId] = qData;
  localStorage.setItem('errorBank', JSON.stringify(errorBank));
}

/**
 * Actualiza la mnemotecnia de una pregunta en el banco de errores.
 */
export function updateMnemonic(questionId, mnemonicText) {
  const stored = localStorage.getItem('errorBank');
  if (!stored) return;
  const errorBank = JSON.parse(stored);

  if (!errorBank[questionId]) return;

  errorBank[questionId].mnemonic = mnemonicText;
  localStorage.setItem('errorBank', JSON.stringify(errorBank));
}

/**
 * Devuelve la info específica de errorBank para una pregunta (o null si no existe).
 */
export function getErrorBankData(questionId) {
  const stored = localStorage.getItem('errorBank');
  if (!stored) return null;
  const errorBank = JSON.parse(stored);
  return errorBank[questionId] || null;
}

/**
 * “Quita” preguntas del banco de errores marcando `removed = true` (borrado lógico).
 */
export function removeQuestionsFromErrorBank(questionIds = []) {
  const stored = localStorage.getItem('errorBank');
  if (!stored) return;
  const errorBank = JSON.parse(stored);

  questionIds.forEach((id) => {
    const qId = String(id);
    if (errorBank[qId]) {
      errorBank[qId].removed = true;
    }
  });

  localStorage.setItem('errorBank', JSON.stringify(errorBank));
}

/**
 * Devuelve todas las preguntas que estén “removed = true”.
 */
export function getRemovedErrorQuestions(allQuestions) {
  const stored = localStorage.getItem('errorBank');
  if (!stored) return [];
  const errorBank = JSON.parse(stored);

  const removedIds = Object.keys(errorBank).filter((qId) => {
    const data = errorBank[qId];
    return data.removed === true;
  });

  return allQuestions.filter((q) => removedIds.includes(String(q.id)));
}

/**
 * Restaura preguntas quitadas: setea removed = false.
 */
export function restoreQuestionsToErrorBank(questionIds = []) {
  const stored = localStorage.getItem('errorBank');
  if (!stored) return;
  const errorBank = JSON.parse(stored);

  questionIds.forEach((id) => {
    const qId = String(id);
    if (errorBank[qId]) {
      errorBank[qId].removed = false;
    }
  });

  localStorage.setItem('errorBank', JSON.stringify(errorBank));
}

/**
 * Elimina definitivamente las preguntas del errorBank (borrado permanente).
 */
export function deleteFromErrorBank(questionIds = []) {
  const stored = localStorage.getItem('errorBank');
  if (!stored) return;
  const errorBank = JSON.parse(stored);

  questionIds.forEach((id) => {
    const qId = String(id);
    if (errorBank[qId]) {
      delete errorBank[qId];
    }
  });

  localStorage.setItem('errorBank', JSON.stringify(errorBank));
}