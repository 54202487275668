import React, { useState } from 'react';
import '../components/FlashcardCell.css'; // Asegúrate de que el path es correcto

function TablaResumenTiroidea() {
  const [hiddenCells, setHiddenCells] = useState({});
  const [hiddenColumns, setHiddenColumns] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  // Datos de ejemplo
  const tableData = [
    {
      aspecto: "🔥 Exposición a Radiación",
      descripcionClave:
        "- Factor de riesgo para carcinoma papilar\n- Uso de I-131 / radioterapia",
      herramientaExamen: "Historia clínica",
      notasRelevantes: "- Induce mutaciones (BRAF, RET/PTC)",
      estrategiaEstudio: "Revisar mecanismos y efectos de la radiación",
      frases: [
        "Factor de riesgo para carcinoma papilar",
        "Uso de I-131 / radioterapia",
        "Induce mutaciones (BRAF, RET/PTC)",
      ],
    },
    {
      aspecto: "🧬 Mutaciones Genéticas",
      descripcionClave: "- Mutaciones clave: BRAF, RET/PTC",
      herramientaExamen: "Histopatología / Citología",
      notasRelevantes: "- Determinan el fenotipo papilar",
      estrategiaEstudio: "Estudiar genética molecular en tiroides",
      frases: [
        "Mutaciones clave: BRAF, RET/PTC",
        "Determinan el fenotipo papilar",
      ],
    },
    {
      aspecto: "🏥 Carcinoma Papilar de Tiroides",
      descripcionClave:
        "- Tumor predominante en adultos (30-50 años)\n- Evolución indolente",
      herramientaExamen: "FNA, Histopatología",
      notasRelevantes:
        "- Buen pronóstico y alta respuesta a tratamientos específicos",
      estrategiaEstudio: "Revisar características histológicas y pronóstico",
      frases: [
        "Tumor predominante en adultos (30-50 años)",
        "Evolución indolente",
        "Buen pronóstico",
        "Alta respuesta a tratamientos específicos",
      ],
    },
    {
      aspecto: "📊 Evaluación Ultrasonográfica",
      descripcionClave:
        "- Identifica nódulos sospechosos:\n• Hipoecogenicidad\n• Bordes irregulares\n• Microcalcificaciones\n• Relación “taller que ancho”",
      herramientaExamen: "Ultrasonido",
      notasRelevantes:
        "- Fundamental para diferenciar nódulos benignos vs. malignos",
      estrategiaEstudio: "Memorizar hallazgos ecográficos clave",
      frases: [
        "Hipoecogenicidad",
        "Bordes irregulares",
        "Microcalcificaciones",
        "Relación 'taller que ancho'",
      ],
    },
    {
      aspecto: "🔬 Confirmación Diagnóstica (FNA)",
      descripcionClave:
        "- Biopsia por aspiración para confirmar malignidad",
      herramientaExamen: "FNA (Biopsia)",
      notasRelevantes: "- Estándar para diagnóstico definitivo",
      estrategiaEstudio: "Practicar interpretación de citologías",
      frases: ["Biopsia por aspiración", "Diagnóstico definitivo"],
    },
    {
      aspecto: "🔪 Tratamiento Quirúrgico",
      descripcionClave:
        "- Tiroidectomía total o parcial ± disección cervical según extensión tumoral",
      herramientaExamen: "Cirugía",
      notasRelevantes:
        "- Determinado por el estadio y extensión tumoral",
      estrategiaEstudio: "Repasar algoritmos y criterios quirúrgicos",
      frases: [
        "Tiroidectomía total o parcial",
        "Disección cervical según extensión tumoral",
        "Determinación por estadio",
      ],
    },
    {
      aspecto: "💊 Radioiodoterapia (I-131)",
      descripcionClave:
        "- Ablación de tejido tiroideo residual\n- Tratamiento de metástasis",
      herramientaExamen: "I-131, Gammagrafía",
      notasRelevantes:
        "- Basada en la captación de yodo; evalúa la función tisular",
      estrategiaEstudio: "Estudiar indicaciones, dosis y protocolos",
      frases: [
        "Ablación de tejido tiroideo residual",
        "Tratamiento de metástasis",
        "Captación de yodo",
        "Función tisular",
      ],
    },
    {
      aspecto: "🔎 Seguimiento",
      descripcionClave:
        "- Monitoreo post-tratamiento\n- Evaluación de recurrencia mediante gammagrafía y niveles de tiroglobulina",
      herramientaExamen: "Gammagrafía, Biomarcadores",
      notasRelevantes:
        "- Verifica eficacia terapéutica y detecta recurrencias",
      estrategiaEstudio:
        "Conocer y practicar protocolos de seguimiento",
      frases: [
        "Monitoreo post-tratamiento",
        "Evaluación de recurrencia",
        "Gammagrafía",
        "Niveles de tiroglobulina",
      ],
    },
    {
      aspecto: "⚠️ Complicaciones del Tratamiento",
      descripcionClave:
        "- Efectos secundarios del I-131, especialmente fibrosis pulmonar",
      herramientaExamen: "Radiología, Seguimiento clínico",
      notasRelevantes:
        "- Relevante en dosis elevadas; requiere manejo a largo plazo",
      estrategiaEstudio:
        "Estudiar complicaciones y estrategias de mitigación",
      frases: [
        "Efectos secundarios del I-131",
        "Fibrosis pulmonar",
        "Manejo a largo plazo",
        "Dosis elevadas",
      ],
    },
  ];

  const columnDisplayNames = {
    aspecto: 'Aspecto',
    descripcionClave: 'Descripción Clave',
    herramientaExamen: 'Herramienta/Examen',
    notasRelevantes: 'Notas Relevantes',
    estrategiaEstudio: 'Estrategia de Estudio',
  };

  const columns = Object.keys(columnDisplayNames);

  // Función para voltear una celda individual
  const toggleCell = (rowIdx, fieldKey) => {
    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prevState) => ({
      ...prevState,
      [cellKey]: !prevState[cellKey],
    }));
  };

  // Función para voltear (ocultar/mostrar) todas las celdas
  const toggleAllCells = () => {
    const areAllCellsFlipped = Object.keys(hiddenCells).length > 0;
    if (!areAllCellsFlipped) {
      const newHiddenCells = {};
      tableData.forEach((_, rowIdx) => {
        columns.forEach((colKey) => {
          const cellKey = `${rowIdx}-${colKey}`;
          newHiddenCells[cellKey] = true;
        });
      });
      setHiddenCells(newHiddenCells);
    } else {
      setHiddenCells({});
    }
  };

  // Función para ocultar/mostrar una columna completa
  const toggleColumn = (columnKey) => {
    setHiddenColumns((prevState) => {
      const newHiddenColumns = {
        ...prevState,
        [columnKey]: !prevState[columnKey],
      };

      const newHiddenCells = { ...hiddenCells };
      tableData.forEach((_, rowIdx) => {
        const cellKey = `${rowIdx}-${columnKey}`;
        if (newHiddenColumns[columnKey]) {
          newHiddenCells[cellKey] = true;
        } else {
          delete newHiddenCells[cellKey];
        }
      });

      setHiddenCells(newHiddenCells);
      return newHiddenColumns;
    });
  };

  // Función para abrir el modal con el contenido de la celda
  const handleRevealText = (text, rowIdx, fieldKey) => {
    setModalContent(text);
    setIsModalOpen(true);

    // Restablece el flip de la celda al abrir el modal
    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prev) => {
      const newState = { ...prev };
      if (newState[cellKey]) {
        delete newState[cellKey];
      }
      return newState;
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  // Función para alternar pantalla completa
  const toggleFullScreen = () => {
    const elem = document.getElementById("tablaContainer");
    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        alert(`Error al intentar habilitar pantalla completa: ${err.message}`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <div className="overflow-x-auto w-full p-4 bg-white shadow-lg rounded-md" id="tablaContainer">
      <h2 className="font-bold text-blue-800 mb-4 text-center text-3xl">
        Tabla Resumen de Aspectos en Tiroideos
      </h2>

      {/* Botones para controlar la tabla */}
      <div className="flex flex-wrap justify-center gap-4 mb-6">
        <button
          onClick={toggleAllCells}
          className="px-4 py-2 rounded bg-green-600 text-white hover:bg-green-700 transition font-semibold"
        >
          {Object.keys(hiddenCells).length === 0
            ? 'Ocultar toda la información'
            : 'Mostrar toda la información'}
        </button>
        {columns.map((columnKey) => (
          <button
            key={columnKey}
            onClick={() => toggleColumn(columnKey)}
            className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600 transition font-semibold"
          >
            {hiddenColumns[columnKey]
              ? `Mostrar ${columnDisplayNames[columnKey]}`
              : `Ocultar ${columnDisplayNames[columnKey]}`}
          </button>
        ))}
        {/* Botón para pantalla completa */}
        <button
          onClick={toggleFullScreen}
          className="px-4 py-2 rounded bg-yellow-500 text-white hover:bg-yellow-600 transition font-semibold"
        >
          Pantalla Completa
        </button>
      </div>

      {/* Tabla con separación horizontal y vertical */}
      <table className="border-separate border-spacing-x-12 border-spacing-y-12 min-w-[3000px] table-auto">
        <thead>
          <tr>
            {columns.map(
              (columnKey) =>
                !hiddenColumns[columnKey] && (
                  <th
                    key={columnKey}
                    className="border-b-2 border-gray-300 bg-blue-100 text-center p-4 font-semibold text-gray-700 whitespace-pre-wrap break-words leading-relaxed"
                  >
                    {columnDisplayNames[columnKey]}
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {tableData.map((item, rowIdx) => (
            <tr key={rowIdx} className="transition duration-200 hover:bg-gray-50">
              {columns.map((colKey) => {
                if (hiddenColumns[colKey]) return null;
                const cellKey = `${rowIdx}-${colKey}`;
                return (
                  <td
                    key={colKey}
                    className="p-4 border-b border-gray-200 whitespace-pre-wrap break-words leading-relaxed align-top"
                  >
                    <div
                      className={`flashcard-cell ${hiddenCells[cellKey] ? 'flipped' : ''}`}
                      title="Haz click para voltear la tarjeta"
                    >
                      <div className="flashcard-cell-inner">
                        <div
                          className="flashcard-cell-front rounded-md shadow-md bg-white p-4 whitespace-pre-wrap break-words leading-relaxed"
                          onClick={() => toggleCell(rowIdx, colKey)}
                        >
                          {Array.isArray(item[colKey])
                            ? item[colKey].join(', ')
                            : item[colKey]}
                        </div>
                        <div
                          className="flashcard-cell-back rounded-md shadow-md bg-gray-50 p-4 whitespace-pre-wrap break-words leading-relaxed"
                          onClick={(e) => e.stopPropagation()}
                          title="Haz click para ver detalles en modal"
                        >
                          <button
                            className="text-blue-600 underline font-semibold hover:text-blue-800"
                            onClick={() =>
                              handleRevealText(
                                Array.isArray(item[colKey])
                                  ? `${columnDisplayNames[colKey]}: ${item[colKey].join(', ')}`
                                  : item[colKey],
                                rowIdx,
                                colKey
                              )
                            }
                          >
                            Mostrar
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3 className="font-bold text-xl mb-4">Detalle</h3>
            <p className="mb-4 whitespace-pre-wrap">{modalContent}</p>
            <button
              onClick={closeModal}
              className="px-4 py-2 rounded bg-red-600 text-white hover:bg-red-700 transition font-semibold"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TablaResumenTiroidea;