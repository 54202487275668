/**** ErrorReview.js ****/
import React, { useState, useEffect } from 'react';

// Importaciones desde ErrorBank
import {
  getAllErrorQuestions,
  toggleLearned,
  updateMnemonic,
  removeQuestionsFromErrorBank,
  getRemovedErrorQuestions,
  restoreQuestionsToErrorBank,
  deleteFromErrorBank
} from './components/ErrorBank';

// Modo Flashcard
import FlashcardMode from './components/FlashcardMode';

// Modo Quiz
import Quiz from './components/Quiz';

/**
 * Componente principal de ErrorReview:
 * 
 * - Muestra, pagina y filtra las preguntas con errores (no removidas).
 * - Permite cambiar a modo Flashcard o modo Quiz para repasarlas.
 * - Permite quitar (borrado lógico) preguntas del banco de errores.
 * - Muestra también la sección de “Errores Quitados”, donde se pueden
 *   restaurar o eliminar permanentemente.
 */
function ErrorReview({ allQuestions, onBack }) {
  // ========================
  // 1) Estado principal
  // ========================
  const [errorQuestions, setErrorQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  // Paginación (errores activos)
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10;

  // Filtros
  const [selectedArea, setSelectedArea] = useState('Todos');
  const [selectedTopic, setSelectedTopic] = useState('Todos');
  const [selectedSubtopic, setSelectedSubtopic] = useState('Todos');
  const [selectedYear, setSelectedYear] = useState('Todos');
  const [selectedSubtopicSpecific, setSelectedSubtopicSpecific] = useState('Todos');

  // Modo flashcard
  const [flashcardMode, setFlashcardMode] = useState(false);
  const [flashcardList, setFlashcardList] = useState([]);

  // Modo quiz
  const [quizMode, setQuizMode] = useState(false);
  const [quizList, setQuizList] = useState([]);

  // Muestra/oculta la tabla de errores quitados
  const [showRemoved, setShowRemoved] = useState(false);
  const [removedQuestions, setRemovedQuestions] = useState([]);
  const [selectedRemovedQuestions, setSelectedRemovedQuestions] = useState([]);

  // ========================
  // 2) Carga inicial
  // ========================
  useEffect(() => {
    // Cargar las preguntas con errores (no removidas)
    const eq = getAllErrorQuestions(allQuestions);
    setErrorQuestions(eq);

    // Cargar las preguntas marcadas como removidas
    const rq = getRemovedErrorQuestions(allQuestions);
    setRemovedQuestions(rq);
  }, [allQuestions]);

  // ========================
  // 3) Filtros dinámicos
  // ========================
  const areas = ['Todos', ...new Set(errorQuestions.map((q) => q.area || 'Desconocido'))];
  const topics = ['Todos', ...new Set(errorQuestions.map((q) => q.topic || 'Desconocido'))];
  const subtopics = ['Todos', ...new Set(errorQuestions.map((q) => q.subtopic || 'Desconocido'))];
  const years = ['Todos', ...new Set(errorQuestions.map((q) => q.year))];
  const subtopicsSpecific = [
    'Todos',
    ...new Set(errorQuestions.map((q) => q.subtopic_specific || 'Sin especificar'))
  ];

  // Aplicamos el filtrado
  const filteredQuestions = errorQuestions.filter((q) => {
    if (selectedArea !== 'Todos' && q.area !== selectedArea) return false;
    if (selectedTopic !== 'Todos' && q.topic !== selectedTopic) return false;
    if (selectedSubtopic !== 'Todos' && q.subtopic !== selectedSubtopic) return false;
    if (selectedYear !== 'Todos' && String(q.year) !== String(selectedYear)) return false;
    if (selectedSubtopicSpecific !== 'Todos') {
      const sps = q.subtopic_specific || 'Sin especificar';
      if (sps !== selectedSubtopicSpecific) return false;
    }
    return true;
  });

  // ========================
  // 4) Ordenar (más reciente primero) usando timeAdded
  // ========================
  const stored = localStorage.getItem('errorBank');
  const errorBank = stored ? JSON.parse(stored) : {};

  const sortedQuestions = [...filteredQuestions].sort((a, b) => {
    const aTime = errorBank[a.id]?.timeAdded || '1970-01-01';
    const bTime = errorBank[b.id]?.timeAdded || '1970-01-01';
    return new Date(bTime) - new Date(aTime); // descendente
  });

  // ========================
  // 5) Paginación
  // ========================
  const totalPages = Math.ceil(sortedQuestions.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedQuestions = sortedQuestions.slice(startIndex, endIndex);

  // Navegar en páginas
  const goToPrevPage = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };
  const goToNextPage = () => {
    if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
  };

  // ========================
  // 6) Checkboxes (errores activos)
  // ========================
  const allSelected =
    paginatedQuestions.length > 0 &&
    paginatedQuestions.every((q) => selectedQuestions.includes(q.id));

  const toggleSelectAll = () => {
    if (allSelected) {
      // Si todo estaba seleccionado, deseleccionamos
      setSelectedQuestions([]);
    } else {
      // Seleccionamos todos los de la página actual
      const idsThisPage = paginatedQuestions.map((q) => q.id);
      const newSelection = Array.from(new Set([...selectedQuestions, ...idsThisPage]));
      setSelectedQuestions(newSelection);
    }
  };

  const handleCheckboxChange = (questionId) => {
    if (selectedQuestions.includes(questionId)) {
      setSelectedQuestions(selectedQuestions.filter((id) => id !== questionId));
    } else {
      setSelectedQuestions([...selectedQuestions, questionId]);
    }
  };

  // ========================
  // 7) Manejo de “Removidos”
  // ========================
  const allSelectedRemoved =
    removedQuestions.length > 0 &&
    removedQuestions.every((q) => selectedRemovedQuestions.includes(q.id));

  const toggleSelectAllRemoved = () => {
    if (allSelectedRemoved) {
      setSelectedRemovedQuestions([]);
    } else {
      setSelectedRemovedQuestions(removedQuestions.map((q) => q.id));
    }
  };

  const handleCheckboxRemovedChange = (questionId) => {
    if (selectedRemovedQuestions.includes(questionId)) {
      setSelectedRemovedQuestions(
        selectedRemovedQuestions.filter((id) => id !== questionId)
      );
    } else {
      setSelectedRemovedQuestions([...selectedRemovedQuestions, questionId]);
    }
  };

  // ========================
  // 8) Acciones
  // ========================

  // Quitar (borrado lógico) las seleccionadas de errores activos
  const handleRemoveSelected = () => {
    removeQuestionsFromErrorBank(selectedQuestions);
    reloadData();
    setSelectedQuestions([]);
  };
  const handleRemoveSingle = (questionId) => {
    removeQuestionsFromErrorBank([questionId]);
    reloadData();
    setSelectedQuestions(selectedQuestions.filter((id) => id !== questionId));
  };

  // Restaurar “removidos”
  const handleRestoreSelected = () => {
    restoreQuestionsToErrorBank(selectedRemovedQuestions);
    reloadData();
    setSelectedRemovedQuestions([]);
  };
  const handleRestoreSingle = (questionId) => {
    restoreQuestionsToErrorBank([questionId]);
    reloadData();
    setSelectedRemovedQuestions(
      selectedRemovedQuestions.filter((id) => id !== questionId)
    );
  };

  // Borrado permanente
  const handleDeleteSelected = () => {
    deleteFromErrorBank(selectedRemovedQuestions);
    reloadData();
    setSelectedRemovedQuestions([]);
  };
  const handleDeleteSingle = (questionId) => {
    deleteFromErrorBank([questionId]);
    reloadData();
    setSelectedRemovedQuestions(
      selectedRemovedQuestions.filter((id) => id !== questionId)
    );
  };

  // Volver a cargar datos del banco
  function reloadData() {
    const eq = getAllErrorQuestions(allQuestions);
    setErrorQuestions(eq);
    const rq = getRemovedErrorQuestions(allQuestions);
    setRemovedQuestions(rq);
  }

  // ========================
  // 9) Modo Flashcard / Quiz
  // ========================
  const handleRepasarTodasFlash = () => {
    setFlashcardList(sortedQuestions);
    setFlashcardMode(true);
  };
  const handleRepasarSeleccionadasFlash = () => {
    const toReview = sortedQuestions.filter((q) => selectedQuestions.includes(q.id));
    setFlashcardList(toReview);
    setFlashcardMode(true);
  };
  const handleExitFlashcard = () => {
    setFlashcardMode(false);
    setFlashcardList([]);
  };

  const handleRepasarTodasQuiz = () => {
    setQuizList(sortedQuestions);
    setQuizMode(true);
  };
  const handleRepasarSeleccionadasQuiz = () => {
    const toReview = sortedQuestions.filter((q) => selectedQuestions.includes(q.id));
    setQuizList(toReview);
    setQuizMode(true);
  };
  const handleExitQuiz = () => {
    setQuizMode(false);
    setQuizList([]);
  };

  // ========================
  // 10) Toggle ver Errores Quitados
  // ========================
  const handleToggleRemovedView = () => {
    setShowRemoved(!showRemoved);
    setCurrentPage(0);
  };

  // ========================
  // 11) Funciones Learned / Mnemonic
  // ========================
  const handleToggleLearned = (questionId) => {
    toggleLearned(String(questionId));
    // Forzar recarga
    const eq = getAllErrorQuestions(allQuestions);
    setErrorQuestions(eq);
  };
  const handleMnemonicUpdate = (questionId, newMnemonic) => {
    updateMnemonic(String(questionId), newMnemonic);
    const eq = getAllErrorQuestions(allQuestions);
    setErrorQuestions(eq);
  };

  // ========================
  // 12) Render Modo Flashcard
  // ========================
  if (flashcardMode) {
    return (
      <div className="min-h-screen bg-gray-100 py-8">
        <div className="max-w-3xl mx-auto">
          <FlashcardMode questions={flashcardList} onExit={handleExitFlashcard} />
        </div>
      </div>
    );
  }

  // ========================
  // 13) Render Modo Quiz
  // ========================
  if (quizMode) {
    return (
      <div className="min-h-screen bg-gray-100 py-8">
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
          <Quiz
            title="Repaso de Errores (Modo Quiz)"
            questions={quizList}
            onBack={handleExitQuiz}
            simulationMode={false}
            fromGenerator={true}
          />
        </div>
      </div>
    );
  }

  // ========================
  // 14) Render principal
  // ========================
  if (showRemoved) {
    // -------------------------------------------------
    // RENDER: Errores Quitados
    // -------------------------------------------------
    return (
      <div className="min-h-screen bg-gray-100 py-8">
        <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
            Errores Quitados
          </h2>
          <p className="mb-4 text-gray-600 text-center">
            Total quitados: {removedQuestions.length}
          </p>

          <div className="flex flex-wrap gap-2 justify-center mb-6">
            <button
              onClick={onBack}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
            >
              Volver
            </button>
            <button
              onClick={handleToggleRemovedView}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Ver Errores Activos
            </button>
            <button
              onClick={handleRestoreSelected}
              disabled={!selectedRemovedQuestions.length}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 font-semibold disabled:bg-gray-300"
            >
              Restaurar ({selectedRemovedQuestions.length})
            </button>
            <button
              onClick={handleDeleteSelected}
              disabled={!selectedRemovedQuestions.length}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-semibold disabled:bg-gray-300"
            >
              Quitar Permanentemente ({selectedRemovedQuestions.length})
            </button>
          </div>

          {removedQuestions.length === 0 ? (
            <p className="text-center text-gray-500">No hay preguntas removidas.</p>
          ) : (
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-2 border text-left">
                    <input
                      type="checkbox"
                      checked={allSelectedRemoved}
                      onChange={toggleSelectAllRemoved}
                    />
                  </th>
                  <th className="p-2 border text-left">Pregunta</th>
                  <th className="p-2 border text-left">Área</th>
                  <th className="p-2 border text-left">Tema</th>
                  <th className="p-2 border text-left">Subtema</th>
                  <th className="p-2 border text-left">Subtema Esp.</th>
                  <th className="p-2 border text-left">Año</th>
                  {/* Nueva columna Fecha */}
                  <th className="p-2 border text-left">Fecha</th>
                  {/* Columna Hora existente */}
                  <th className="p-2 border text-left">Hora</th>
                  <th className="p-2 border text-left">Restaurar</th>
                  <th className="p-2 border text-left">Borrar Perm.</th>
                </tr>
              </thead>
              <tbody>
                {removedQuestions.map((q) => {
                  // Obtenemos info de errorBank
                  const data = errorBank[q.id] || {};
                  const fecha = data.timeAdded
                    ? new Date(data.timeAdded).toLocaleDateString()
                    : '—';
                  const hora = data.timeAdded
                    ? new Date(data.timeAdded).toLocaleTimeString()
                    : '—';

                  return (
                    <tr key={q.id}>
                      <td className="p-2 border">
                        <input
                          type="checkbox"
                          checked={selectedRemovedQuestions.includes(q.id)}
                          onChange={() => handleCheckboxRemovedChange(q.id)}
                        />
                      </td>
                      <td className="p-2 border text-sm max-w-sm">
                        {q.question_text}
                      </td>
                      <td className="p-2 border text-sm">{q.area}</td>
                      <td className="p-2 border text-sm">{q.topic}</td>
                      <td className="p-2 border text-sm">{q.subtopic}</td>
                      <td className="p-2 border text-sm">
                        {q.subtopic_specific || 'Sin especificar'}
                      </td>
                      <td className="p-2 border text-sm">{q.year}</td>
                      <td className="p-2 border text-sm">{fecha}</td>
                      <td className="p-2 border text-sm">{hora}</td>
                      <td className="p-2 border text-sm">
                        <button
                          onClick={() => handleRestoreSingle(q.id)}
                          className="px-2 py-1 bg-green-600 text-white rounded text-xs hover:bg-green-700"
                        >
                          Restaurar
                        </button>
                      </td>
                      <td className="p-2 border text-sm">
                        <button
                          onClick={() => handleDeleteSingle(q.id)}
                          className="px-2 py-1 bg-red-600 text-white rounded text-xs hover:bg-red-700"
                        >
                          Borrar Perm.
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  } else {
    // -------------------------------------------------
    // RENDER: Errores Activos
    // -------------------------------------------------
    return (
      <div className="min-h-screen bg-gray-100 py-8">
        <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
            Revisión de Errores
          </h2>
          <p className="mb-4 text-gray-600 text-center">
            Total de preguntas con errores: {errorQuestions.length}
          </p>

          {/* Botones principales */}
          <div className="flex flex-wrap gap-2 justify-center mb-6">
            <button
              onClick={onBack}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
            >
              Volver
            </button>
            <button
              onClick={handleRepasarTodasFlash}
              disabled={!sortedQuestions.length}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 font-semibold disabled:bg-gray-300"
            >
              Flashcard: Todas
            </button>
            <button
              onClick={handleRepasarSeleccionadasFlash}
              disabled={!selectedQuestions.length}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 font-semibold disabled:bg-gray-300"
            >
              Flashcard: Seleccionadas ({selectedQuestions.length})
            </button>
            <button
              onClick={handleRepasarTodasQuiz}
              disabled={!sortedQuestions.length}
              className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 font-semibold disabled:bg-gray-300"
            >
              Quiz: Todas
            </button>
            <button
              onClick={handleRepasarSeleccionadasQuiz}
              disabled={!selectedQuestions.length}
              className="px-4 py-2 bg-purple-700 text-white rounded-lg hover:bg-purple-800 font-semibold disabled:bg-gray-300"
            >
              Quiz: Seleccionadas ({selectedQuestions.length})
            </button>
            <button
              onClick={handleRemoveSelected}
              disabled={!selectedQuestions.length}
              className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 font-semibold disabled:bg-gray-300"
            >
              Quitar de Errores ({selectedQuestions.length})
            </button>
            <button
              onClick={handleToggleRemovedView}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Ver Errores Quitados
            </button>
          </div>

          {/* Filtros */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">Área:</label>
              <select
                value={selectedArea}
                onChange={(e) => {
                  setSelectedArea(e.target.value);
                  setCurrentPage(0);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              >
                {areas.map((a) => (
                  <option key={a} value={a}>
                    {a}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">Tema:</label>
              <select
                value={selectedTopic}
                onChange={(e) => {
                  setSelectedTopic(e.target.value);
                  setCurrentPage(0);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              >
                {topics.map((t) => (
                  <option key={t} value={t}>
                    {t}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">Subtema:</label>
              <select
                value={selectedSubtopic}
                onChange={(e) => {
                  setSelectedSubtopic(e.target.value);
                  setCurrentPage(0);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              >
                {subtopics.map((st) => (
                  <option key={st} value={st}>
                    {st}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Subtema Esp.:
              </label>
              <select
                value={selectedSubtopicSpecific}
                onChange={(e) => {
                  setSelectedSubtopicSpecific(e.target.value);
                  setCurrentPage(0);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              >
                {subtopicsSpecific.map((sps) => (
                  <option key={sps} value={sps}>
                    {sps}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">Año:</label>
              <select
                value={selectedYear}
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                  setCurrentPage(0);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              >
                {years.map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Tabla de errores activos */}
          {sortedQuestions.length === 0 ? (
            <p className="text-center text-gray-500">No hay preguntas con esos filtros.</p>
          ) : (
            <>
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-2 border text-left">
                      <input
                        type="checkbox"
                        checked={allSelected}
                        onChange={toggleSelectAll}
                      />
                    </th>
                    <th className="p-2 border text-left">Pregunta</th>
                    <th className="p-2 border text-left">Área</th>
                    <th className="p-2 border text-left">Tema</th>
                    <th className="p-2 border text-left">Subtema</th>
                    <th className="p-2 border text-left">Subtema Esp.</th>
                    <th className="p-2 border text-left">Año</th>
                    <th className="p-2 border text-left">Aprendido</th>
                    <th className="p-2 border text-left">Mnemotecnia</th>
                    {/* Nueva columna Fecha */}
                    <th className="p-2 border text-left">Fecha</th>
                    {/* Columna Hora existente */}
                    <th className="p-2 border text-left">Hora</th>
                    <th className="p-2 border text-left">Quitar</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedQuestions.map((q) => {
                    // Accedemos a la info del errorBank
                    const bankData = errorBank[q.id] || {};
                    const isLearned = bankData.learned || false;
                    const mnemonic = bankData.mnemonic || '';
                    const fecha = bankData.timeAdded
                      ? new Date(bankData.timeAdded).toLocaleDateString()
                      : '—';
                    const hora = bankData.timeAdded
                      ? new Date(bankData.timeAdded).toLocaleTimeString()
                      : '—';

                    return (
                      <tr key={q.id}>
                        <td className="p-2 border">
                          <input
                            type="checkbox"
                            checked={selectedQuestions.includes(q.id)}
                            onChange={() => handleCheckboxChange(q.id)}
                          />
                        </td>
                        <td className="p-2 border text-sm max-w-sm">
                          {q.question_text}
                        </td>
                        <td className="p-2 border text-sm">{q.area}</td>
                        <td className="p-2 border text-sm">{q.topic}</td>
                        <td className="p-2 border text-sm">{q.subtopic}</td>
                        <td className="p-2 border text-sm">
                          {q.subtopic_specific || 'Sin especificar'}
                        </td>
                        <td className="p-2 border text-sm">{q.year}</td>
                        <td className="p-2 border text-sm">
                          <button
                            onClick={() => handleToggleLearned(q.id)}
                            className={`px-2 py-1 text-white rounded text-xs ${
                              isLearned
                                ? 'bg-green-600 hover:bg-green-700'
                                : 'bg-red-500 hover:bg-red-600'
                            }`}
                          >
                            {isLearned ? 'Aprendido' : 'No Aprendido'}
                          </button>
                        </td>
                        <td className="p-2 border text-sm">
                          <MnemotecniaEditor
                            questionId={q.id}
                            initialValue={mnemonic}
                            onSave={handleMnemonicUpdate}
                          />
                        </td>
                        <td className="p-2 border text-sm">{fecha}</td>
                        <td className="p-2 border text-sm">{hora}</td>
                        <td className="p-2 border text-sm">
                          <button
                            onClick={() => handleRemoveSingle(q.id)}
                            className="px-2 py-1 bg-red-500 text-white rounded text-xs hover:bg-red-600"
                          >
                            Quitar
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* Paginación */}
              <div className="flex justify-center items-center mt-4 gap-4">
                <button
                  onClick={goToPrevPage}
                  disabled={currentPage === 0}
                  className="px-3 py-1 bg-gray-300 rounded disabled:bg-gray-100"
                >
                  Anterior
                </button>
                <span>
                  Página {currentPage + 1} de {totalPages}
                </span>
                <button
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages - 1}
                  className="px-3 py-1 bg-gray-300 rounded disabled:bg-gray-100"
                >
                  Siguiente
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

/**
 * Subcomponente para editar la mnemotecnia (inline).
 */
function MnemotecniaEditor({ questionId, initialValue, onSave }) {
  const [value, setValue] = useState(initialValue);
  const [editing, setEditing] = useState(false);

  const handleSave = () => {
    onSave(questionId, value);
    setEditing(false);
  };

  if (!editing) {
    return (
      <div className="flex items-center gap-2">
        <span className="text-gray-700 text-xs italic">{value || '---'}</span>
        <button
          onClick={() => setEditing(true)}
          className="px-2 py-1 bg-blue-500 text-white rounded text-xs"
        >
          {value ? 'Editar' : 'Agregar'}
        </button>
      </div>
    );
  }

  return (
    <div>
      <textarea
        rows={2}
        className="w-full border border-gray-300 rounded p-1 text-xs"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="mt-1 flex gap-2">
        <button
          onClick={handleSave}
          className="px-2 py-1 bg-green-600 text-white rounded text-xs hover:bg-green-700"
        >
          Guardar
        </button>
        <button
          onClick={() => {
            setValue(initialValue);
            setEditing(false);
          }}
          className="px-2 py-1 bg-gray-400 text-white rounded text-xs hover:bg-gray-500"
        >
          Cancelar
        </button>
      </div>
    </div>
  );
}

export default ErrorReview;